import React from "react"
import { Helmet } from "react-helmet-async"

function Meta({ title, siteUrl, desc, tags }) {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="subject" content={title.split(" - ")[0]} />
            <meta name="description" content={desc} />
            <meta name="canonical" content={siteUrl} />
            <meta name="siteUrl" content={siteUrl} />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="keywords" content={tags.join(", ")} />
            <meta name="theme-color" content="#FFFFFF" />
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <link rel="icon" type="image/png" href="/img/favicon.png" sizes="16x16" />
            <link rel="apple-touch-icon" type="image/png" href="/img/favicon.png" />
            <link rel="manifest" type="image/png" href="/manifest.json"/>
            
            <meta name="naver-site-verification" content="b3c32001ddf7d2b9d8dc8e9a2a630c7bb8bafaed" />
            <script type="text/javascript" src="https://code.jquery.com/jquery-1.12.4.min.js"></script>
            <script type="text/javascript" src="https://cdn.iamport.kr/js/iamport.payment-1.1.8.js"></script>

            <meta property="og:url" content={siteUrl} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={desc} />
            <meta property="og:image" content="/img/og_title.png" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content={siteUrl.split("/")[2]} />
            <meta property="twitter:url" content={siteUrl} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={desc} />
            <meta name="twitter:image" content="/img/og_title.png" />
        </Helmet>
    )
}

export default Meta
