import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { Provider } from "react-redux"
import store from "./redux/redux-store"
import { HelmetProvider } from "react-helmet-async"

const container = document.getElementById("root")
const root = ReactDOM.createRoot(container)

if (container.hasChildNodes()) {
    ReactDOM.hydrateRoot(
        container,
        <Provider store={store}>
            <HelmetProvider>
                <App />
            </HelmetProvider>
        </Provider>,
    )
} else {
    root.render(
        <Provider store={store}>
            <HelmetProvider>
                <App />
            </HelmetProvider>
        </Provider>,
    )
}

reportWebVitals()
