import { useLocation } from "react-router-dom";
import "swiper/css";
import ItemCard from "../common/itemCard";

export default function CurationItems() {
    const { state } = useLocation();

    let titleimg, items;
    if (state) {
        ({ titleimg, items } = state);

        for (let i = 0; i < items.length; i++) {
            items[i].item_name = items[i].name;
            items[i].item_price = items[i].price;
            items[i].item_company = items[i].manuf;
            items[i].item_img = items[i].img;
            items[i].item_badges = items[i].badges;

            // 이 4줄은 왜 존재하는 거지???
            items[i].name = null;
            items[i].price = null;
            items[i].manuf = null;
            items[i].img = null;
            items[i].badges = null;
        }
    }

    return state ? (
        <>
            <img
                src={process.env.REACT_APP_IMAGE_BASE + titleimg + `?timestamp=${new Date().getTime()}`}
                className="mt-[12px]"
                width={360}
                alt="큐레이션 소개 문구"
            />
            <div className="z-10 mb-[20px] mx-[16px] text-14px text-left">
                <div className="mt-[30px] text-14px grid grid-cols-2 gap-[12px]">
                    {items.map((item, i) => (
                        <ItemCard item={item} key={`item_liq_${i}`} />
                    ))}
                </div>
            </div>
        </>
    ) : (
        <h2 className="pt-[32px]">비정상적인 접근입니다.</h2>
    );
}
