import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import OrderModal from "../OrderModal";
import { PaginationAndo } from "../PaginationAndo";
import Meta from "../../Meta";

import { fetchItem } from "../../../redux/module/item_all";
import ItemCard from "../../common/itemCard";
import { useParams } from "react-router-dom";
import { setSearchPlaceholder } from "../../../redux/module/custom_str";

import RollingText from "../../../components/BlackFriday/RollingText";
// import CountdownTimer from "../../../components/BlackFriday/CountDown";

export default function ShopAll() {
  const dispatch = useDispatch();
  const page = parseInt(useParams().page);
  const nav = useNavigate();
  const loc = useLocation().pathname;

  useEffect(() => {
    dispatch(setSearchPlaceholder("무엇이든 검색해보세요!"));
  }, [dispatch]);

  const search_text = useSelector((state) => state.custom_str.search_text);

  const items = useSelector((state) => state.item_all.items);
  const items_count = useSelector((state) => state.item_all.items_count);

  const [order, setOrder] = useState("new");
  const [order_text, setOrderText] = useState("NEW");
  const [toggle_order, setToggleOrder] = useState(false); // 정렬 드롭다운 토글 여부
  const order_options = [
    // "BEST",
    "NEW",
    "낮은 가격순",
    "높은 가격순",
  ];

  const setOrderOption = (text) => {
    let mode;
    switch (text) {
      case "BEST":
        mode = "best";
        break;
      case "NEW":
        mode = "new";
        break;
      case "낮은 가격순":
        mode = "cheap";
        break;
      case "높은 가격순":
        mode = "expensive";
        break;
      default:
        text = "NEW";
        mode = "new";
        break;
    }
    setOrder(mode);
    setOrderText(text);
    setToggleOrder(false);
  };

  useEffect(() => {
    dispatch(
      fetchItem({
        query_clause: `page=${page}&search_text=${search_text}&order=${order}`,
      }),
    );
  }, [dispatch, order, page, search_text]);

  const expand_abvr = (t) => {
    switch (t) {
      case "L":
        return "liquid";
      case "M":
        return "machine";
      case "C":
        return "coil";
      case "P":
        return "pod";
      default:
        return "undef";
    }
  };

  return (
    <div className={`${toggle_order ? "h-screen overflow-hidden" : null}`}>
      <Meta
        title="샵 - 안도 전자담배"
        siteUrl="https://andocompany.com/shop"
        desc="내 맘에 쏙 드는 전담 쇼핑!"
        tags={[
          "전자담배",
          "전자담배 액상",
          "전자담배 추천",
          "일회용 전자담배",
          " 전자담배 기기",
          " 궐련형 전자담배",
          "젤로 전자담배",
          "발라리안",
          " 편의점 전자담배",
          " 전자담배 기기 추천",
        ]}
      />
      <RollingText />
      {/* <CountdownTimer /> */}
      {!loc.includes("all") && (
        <>
          <div className="mt-8 grid grid-cols-3 gap-[14px] mx-[16px] mb-[30px]">
            {[
              { str: "ALL", url: "all" },
              { str: "액상", url: "liquid" },
              { str: "기기", url: "machine" },
              { str: "부품", url: "accessory" },
              // { str: "특가할인", url: "flashsale" },
              // { str: "스타터세트", url: "starter" },
            ].map((type) => (
              <p className="mx-auto w-full h-[50px] bg-[#F6F6F6] flex flex-col justify-center" key={`${type.str}샵`} onClick={() => nav(`/shop/${type.url}/1`)}>
                {type.str}
              </p>
            ))}
          </div>
          <hr className="border-[#EBEBEB]" />
          <div className="mt-[30px] mx-[16px]">
            <div className="flex justify-between relative h-[40px]">
              <div className="font-medium self-center">안도에서 만나보세요</div>
              {/* <div className="font-medium self-center">💝 블랙프라이데이 할인 상품</div> */}
              <div
                className={`w-[130px] ${toggle_order ? "rounded-[15px]" : "rounded-[25px]"} text-14px border-[#D1D1D1] bg-white border absolute right-0 z-10`}
                onClick={() => setToggleOrder(!toggle_order)}
              >
                <div className={`flex justify-between h-[40px] px-[16px] ${toggle_order ? "bg-[#F8F8F8] rounded-t-[15px]" : "bg-white rounded-[25px]"}`}>
                  <p className="self-center">{order_text}</p>
                  <p className="self-center">
                    <img src="/img/sort_updown.svg" width={14} alt="sort: updown arrow" />
                  </p>
                </div>
                {toggle_order &&
                  order_options.map((elem, itr) => (
                    <p className="py-[10px] px-[16px] text-left" key={"shop_sort_" + itr} onClick={() => setOrderOption(elem)}>
                      {elem}
                    </p>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
      <div className="z-10 mt-[12px] mb-[20px] text-14px text-left font-medium text-[#BDBDBD] bg-white relative" onClick={() => setToggleOrder((cur) => !cur)}>
        <div className="mx-[16px] flex">
          <p>ALL</p>
          <p className="mx-[6px] flex flex-col justify-center">
            <img src="/imageDB/shop_common/Chevron_Right_5_8_BDBDBD.svg" alt="Right" />
          </p>
          <p className="text-black">{order_text}&nbsp;</p>
          <p className="mx-[4px] flex flex-col justify-center">
            <img src="/imageDB/shop_common/Chevron_Down_8_5_black_2.svg" alt="Down" />
          </p>
        </div>
        <OrderModal
          toggle_order={toggle_order}
          order_options={order_options}
          order_text={order_text}
          setToggleOrder={setToggleOrder}
          setOrderOption={setOrderOption}
        />
      </div>

      <hr className="border-[#F2F2F2]" />

      <div className="mt-[30px] mx-[12px] text-left text-14px grid grid-cols-2 gap-x-[12px]">
        {items.map((item, i) => (
          <ItemCard item={item} type={expand_abvr(item.item_id[3])} key={`item_${i}`} />
        ))}
      </div>

      {items_count ? <PaginationAndo page={page} last={items_count} /> : null}
    </div>
  );
}
