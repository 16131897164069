import { useEffect, useState } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  fetchMachineSaves,
  setMachineSaves,
} from "../../redux/module/item_machine";
import { useDispatch, useSelector } from "react-redux";
import { setCustom } from "../../redux/module/custom_str";
import { fetchQuickAcc } from "../../redux/module/item_acc";

export default function MyDevAdd() {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [search_text, setSearch] = useState("");

  const [machines, setMachine] = useState([]);
  const [machine_select, setCheck] = useState([]);
  const [checkCount, setCheckCount] = useState(0);

  const machine_infos = useSelector(
    (state) => state.item_machine.save_machine_infos
  );

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_FRONT_URL}/api/item/machine/onlyList?search_text=${search_text}`
    )
      .then((res) => {
        setMachine(res.data);
        setCheck(new Array(res.data.length).fill(false));
        setCheckCount(0);
      })
      .catch((err) => console.error(err));
  }, [search_text]);

  useEffect(() => {
    dispatch(setCustom("기기 추가하기"));
    if (!(machine_infos && machine_infos.length)) dispatch(fetchMachineSaves());
  }, [dispatch, machine_infos]);

  const addDevice = () => {
    const item_ids = [];
    for (let i = 0; i < machines.length; i++)
      if (machine_select[i]) item_ids.push(machines[i]);

    Axios.get(
      `${
        process.env.REACT_APP_FRONT_URL
      }/api/item/addMachine?item_id=${item_ids.map((it) => it.item_id)}`
    )
      .then(() => {
        const new_machine_infos = [...item_ids, ...machine_infos];
        dispatch(setMachineSaves(new_machine_infos));
        dispatch(fetchQuickAcc(new_machine_infos));
        nav("/");
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="mt-[12px] mx-[16px] h-[40px] rounded-[40px] bg-[#F2F2F2]">
        <form
          className="flex relative"
          onSubmit={(e) => {
            e.preventDefault();
            setSearch(e.target[0].value);
          }}
        >
          <input
            type="text"
            placeholder="기기명이나 브랜드를 검색해보세요"
            // required
            className="ml-auto mr-[36px] w-full h-[40px] rounded-l-full text-14px pl-[17px] bg-transparent placeholder-[#D8D8D8]"
          />

          <button
            type="submit"
            className="px-[12px] self-center absolute right-[0px]"
          >
            <img
              src="/imageDB/header/icon_search.svg"
              width={21}
              alt="search"
            />
          </button>
        </form>
      </div>

      <div className="grid grid-cols-2 gap-[12px] my-[20px] mx-[16px]">
        {machines.map((elem, itr) => (
          <div
            key={"searched_devices_" + itr}
            className={`w-[158px] h-[156px] mx-auto bg-[#F5F5F5] rounded-[10px] border-2 ${
              machine_select[itr] ? "border-[#696969]" : "border-transparent"
            }  text-12px relative`}
            onClick={() =>
              setCheck((arr) => {
                if (arr[itr]) setCheckCount((st) => st - 1);
                else setCheckCount((st) => st + 1);
                arr[itr] = !arr[itr];
                return [...arr];
              })
            }
          >
            {machine_select[itr] ? (
              <p className="w-[24px] h-[24px] rounded-full bg-[#696969] flex absolute top-[12px] right-[12px]">
                <img
                  src="/imageDB/my_page/my_dev/dev_check.svg"
                  className="self-center mx-auto"
                  alt="선택"
                />
              </p>
            ) : null}
            <p className="mt-[8px] h-[93px] flex">
              <img
                src={process.env.REACT_APP_IMAGE_BASE + elem.item_img + `?timestamp=${new Date().getTime()}`}
                className="mx-auto self-center"
                width={93}
                alt="기기 이미지"
              />
            </p>
            <div className="h-[42px] flex flex-col justify-center">
              <p className="font-medium">{elem.item_company}</p>
              <p>{elem.item_name}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="w-full min-w-[360px] fixed bottom-[33px]">
        {checkCount > 0 ? (
          <button
            className="w-[328px] h-[54px] rounded-[40px] mx-auto bg-black text-white"
            onClick={addDevice}
          >
            {checkCount > 1 && `${checkCount}개 `}저장하기
          </button>
        ) : null}
      </div>
      <p className="h-[100px]" />
    </>
  );
}
