import { configureStore } from "@reduxjs/toolkit";

import { banner } from "./module/banner";
import { custom_str } from "./module/custom_str";
import { item_fav } from "./module/item_fav";
import { item_liquid } from "./module/item_liquid";
import { item_machine } from "./module/item_machine";
import { item_acc } from "./module/item_acc";
import { item_cart } from "./module/item_cart";
import { item_all } from "./module/item_all";
import { user_info } from "./module/user_info";
import { item_flash } from "./module/item_flash";

export default configureStore({
  reducer: {
    banner: banner.reducer,
    custom_str: custom_str.reducer,
    user_info: user_info.reducer,
    item_cart: item_cart.reducer,
    item_fav: item_fav.reducer,
    item_liquid: item_liquid.reducer,
    item_machine: item_machine.reducer,
    item_acc: item_acc.reducer,
    item_all: item_all.reducer,
    item_flash: item_flash.reducer,
  },
});
