export default function andoConfirm({ title, desc, callback, show }) {
  //desc 부분 있을 때 검증 안 했음. 수정해서 쓰시오.

  const btn_func = () => {
    callback();
    show(false);
  };

  const btn_cancle = () => {
    show(false);
  };

  return (
    <div
      // 음영
      className="content-center fixed top-[0px] left-[0px] w-full h-full bg-[rgba(0,0,0)]/50 z-[22]"
    >
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[288px] h-auto rounded-[24px] px-[20px] py-[24px] text-left z-[23]">
        <div className="min-h-[60px]">
          <div className={`text-center text-xl mt-7 `}>
            <span>{title}</span>
          </div>
          {desc && (
            <div className=" text-center my-4 ">
              <span>{desc}</span>
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <button
              className="py-[15px] bg-black rounded-[40px] text-white w-full"
              onClick={btn_func}
            >
              네
            </button>
          </div>
          <div>
            <button
              className="py-[15px] bg-black rounded-[40px] text-white w-full"
              onClick={btn_cancle}
            >
              아니오
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
