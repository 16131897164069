import { memo, useCallback, useEffect, useState } from "react";

import Acc from "./MainShopping/Acc";
import Liq from "./MainShopping/Liq";
import MyDevFlow from "./MyDevFlow";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchMachineSaves } from "../../redux/module/item_machine";
import { fetchQuickAcc } from "../../redux/module/item_acc";
import axios from "axios";

const MyAndo = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();

  const machine_list = useSelector((state) => state.item_machine.save_machine_infos);
  const quickacc_list = useSelector((state) => state.item_acc.quickmenu_data);
  const loading = useSelector((state) => state.item_machine.loading);
  const [machine_idx, setIndex] = useState(0);

  useEffect(() => {
    dispatch(fetchMachineSaves());
  }, [dispatch]);

  useEffect(() => {
    if (machine_list.length && !quickacc_list.length) dispatch(fetchQuickAcc(machine_list));
  }, [dispatch, machine_list, quickacc_list.length]);

  useEffect(() => {
    if (!machine_list.length && (!loading || quickacc_list.length)) dispatch(fetchQuickAcc([{ item_id: "VA_M00001" }]));
  }, [dispatch, loading, machine_list.length, quickacc_list.length]);

  const [acc, setAcc] = useState(false);
  const [liq, setLiq] = useState(false);
  const [back_blur, setBackBlur] = useState(false);

  const handleAcc = useCallback(
    (bool) => {
      setAcc(bool);
      if (!machine_list.length) setBackBlur(bool);
    },
    [machine_list.length],
  );

  const handleLiq = useCallback(
    (bool) => {
      setLiq(bool);
      if (!machine_list.length) setBackBlur(bool);
    },
    [machine_list.length],
  );

  return (
    <div className="h-[523px] relative overflow-hidden">
      {!(machine_list.length || acc || liq) ? (
        <div className="absolute inset-x-0 mx-auto z-10 bg-[#F5F4F1]/50 backdrop-blur-[2px] mt-[150px] w-[256px] h-[78px] rounded-[17px] ando-animation text-14px">
          <p className="pt-[8px] text-[#5B5845] font-medium">기기를 등록해보세요.</p>
          <p
            className="rounded-[16px] bg-[#989384] w-fit text-white font-medium py-[6px] px-[20px] mt-[8px] mx-auto"
            onClick={() => {
              axios.get(`${process.env.REACT_APP_FRONT_URL}/api/user/checkLogin`).then((res) => {
                if (res.data === "login") {
                  sessionStorage.setItem("path", "/my/dev/add");
                  nav(`/kakaoLogin`);
                } else {
                  nav("/my/dev/add");
                }
              });
            }}
          >
            기기 등록
          </p>
        </div>
      ) : null}

      <MyDevFlow back_blur={back_blur} setIndex={setIndex} handleAcc={handleAcc} handleLiq={handleLiq} />

      <div className="flex w-fit mx-auto gap-[13px]">
        <div
          className="w-[102px] h-[102px] rounded-full border-2 border-solid border-[#F2F2F2] flex flex-col justify-center items-center relative"
          onClick={() => handleAcc(true)}
        >
          <div className="absolute bottom-[15px]">
            <img className="ml-[12px]" src="/imageDB/my_ando/acc.svg" width={50} alt="부품" />
            <p className="text-12px mt-[8px]">부품/악세사리</p>
          </div>
        </div>
        <div
          className="w-[102px] h-[102px] rounded-full border-2 border-solid border-[#F2F2F2] flex flex-col justify-center items-center relative"
          onClick={() => handleLiq(true)}
        >
          <div className="absolute bottom-[15px]">
            <img src="/imageDB/my_ando/liq.png" width={60} alt="액상" />
            <p className="text-12px">액상</p>
          </div>
        </div>
      </div>
      <div
        className={`h-[217px] bg-white my-ando-acc relative${
          acc
            ? ` go-up-acc${
                // 기기 등록 안 돼 있을 때는 퀵메뉴 윗쪽으로 그림자 지지 않도록 해줌
                machine_list.length ? " dev-acc" : ""
              }`
            : ""
        }`}
      >
        {!machine_list.length ? (
          <div className="w-full inset-x-0 mx-auto z-10 bg-[rgba(68,68,68)]/30 h-[217px] absolute bottom-0" onClick={() => handleAcc(false)}>
            <div className="bg-[#F5F4F1]/70 backdrop-blur-[2px] mt-[72px] w-[248px] h-[75px] rounded-[17px] mx-auto" onClick={(e) => e.stopPropagation()}>
              <p className="pt-[8px] text-14px text-[#5B5845] font-medium">기기를 등록하면 사용 가능합니다.</p>
              <p
                className="rounded-[16px] bg-[#989384] w-fit text-white text-14px font-medium py-[6px] px-[20px] mt-[4px] mx-auto"
                onClick={() => {
                  axios.get(`${process.env.REACT_APP_FRONT_URL}/api/user/checkLogin`).then((res) => {
                    if (res.data === "login") {
                      sessionStorage.setItem("path", "/my/dev/add");
                      nav(`/kakaoLogin`);
                    } else {
                      nav("/my/dev/add");
                    }
                  });
                }}
              >
                기기 등록
              </p>
            </div>
          </div>
        ) : null}

        <Acc dev={machine_list[machine_idx]} parts_list={quickacc_list.length ? quickacc_list[machine_idx].items : null} handleAcc={handleAcc} />
      </div>
      <div className={`h-[278px] bg-white my-ando-liq${liq ? " go-up-liq" : ""}`}>
        {!machine_list.length ? (
          <div className="absolute z-10 w-full bg-[rgba(68,68,68)]/30 h-[278px] bottom-0 bolt" onClick={() => handleLiq(false)}>
            <div className="bg-[#F5F4F1]/70 backdrop-blur-[2px] mt-[72px] w-[248px] h-[75px] rounded-[17px] mx-auto">
              <p className="pt-[8px] text-14px text-[#5B5845] font-medium" onClick={(e) => e.stopPropagation()}>
                기기를 등록하면 사용 가능합니다.
              </p>
              <p
                className="rounded-[16px] bg-[#989384] w-fit text-white text-14px font-medium py-[6px] px-[20px] mt-[4px] mx-auto"
                onClick={() => {
                  axios.get(`${process.env.REACT_APP_FRONT_URL}/api/user/checkLogin`).then((res) => {
                    if (res.data === "login") {
                      sessionStorage.setItem("path", "/my/dev/add");
                      nav("/kakaoLogin");
                    } else {
                      nav("/my/dev/add");
                    }
                  });
                }}
              >
                기기 등록
              </p>
            </div>
          </div>
        ) : null}

        <Liq handleLiq={handleLiq} />
      </div>
    </div>
  );
};

export default memo(MyAndo);
