import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";

const fetchItem = createAsyncThunk("item_flash/fetchItem", ({ query_clause }) =>
  Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/item/flash?${query_clause}`)
    .then((res) => {
      if (res.data && res.data.length) return res.data;
      else console.log("DB 서버 오류AF");
    })
    .catch(() => console.log("DB 통신 오류AF2"))
);

const item_flash = createSlice({
  name: "item_flash",
  initialState: {
    items: [],
    items_count: 0,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchItem.fulfilled, (state, action) => {
      if (action.payload) {
        if (action.payload[1].length) {
          state.items_count = action.payload[0];
          state.items = action.payload[1];
        } else {
          state.items_count = 0;
          state.items = [];
        }
      }
    });
  },
});

export { fetchItem, item_flash };
