import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addCartItem, setAddIdle } from "../../redux/module/item_cart";
import axios from "axios";

export default function DetailPage() {
  const { type, pid } = useParams();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const loc = useLocation();

  const user_id = useSelector((state) => state.user_info.data.user_id);

  const [purchasing, setPurchase] = useState(false);
  const [basketing, setBasket] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [item_option, setOption] = useState("");

  const queryURI = `${type[0].toUpperCase()}${pid.padStart(5, "0")}`;
  const init_state = {
    item_id: "",
    item_company: "",
    item_name: "",
    item_badges: [],
    item_price: 0,
    item_img: "",
    spec_img: "",
    review_rate: 0,
    review_count: 0,
    fav: -1,
    desc: "",
    back_img: "",
  };
  const [part, setPart] = useState(init_state);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (queryURI)
      Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/item/detail?type=${type}&item_id=VA_${queryURI}`)
        .then((res) => {
          setPart(res.data[0]);
          setLoaded(true);
        })
        .catch((e) => console.error(e.message));
  }, [queryURI, type]);

  return loaded ? (
    part && part.item_id ? (
      <div className={purchasing || basketing ? "h-screen overflow-hidden" : null}>
        <p
          style={
            part.back_img
              ? {
                  backgroundImage: `url('${
                    process.env.REACT_APP_IMAGE_BASE + "/IMAGE+DB/BACKGROUND/" + part.back_img
                  }.png?timestamp=${new Date().getTime()}&w=256&q=75&f=webp')`,
                  backgroundSize: "100%",
                  backgroundColor: "transparent",
                  backgroundPosition: "center, center",
                  backgroundRepeat: "no-repeat",
                  height: "220px",
                }
              : null
          }
        >
          <img
            style={{ display: "flex", height: "300px" }}
            width={280}
            src={process.env.REACT_APP_IMAGE_BASE + part.item_img + `?timestamp=${new Date().getTime()}&w=256&q=75&f=webp`}
            className="mx-auto"
            alt="액상 이미지"
          />
        </p>
        <div
          className="mx-auto"
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "144px",
            marginBottom: "8px",
            marginTop: "80px",
          }}
        >
          {part.item_badges && typeof part.item_badges === "object"
            ? part.item_badges.map(
                (badge, idx) =>
                  badge && (
                    <img key={idx} src={`${process.env.REACT_APP_IMAGE_BASE}${badge}`} style={{ height: "16px", marginRight: "4px" }} alt={`Badge ${idx}`} />
                  ),
              )
            : null}
        </div>
        <div className="-tracking-[0.28px]">
          <p
            // 제조사
            className="font-medium"
          >
            {part.item_company}
          </p>
          <p
            // 상품명
            className="mt-[3px] text-14px"
          >
            {part.item_name}
          </p>
          <p
            className="mt-[4px] text-12px"
            //설명
          >
            {part.item_desc}
          </p>
          {part.discount_price > 0 && (
            <span
              style={{
                fontFamily: "GOOD BRUSH",
                fontSize: "20px",
                color: "red",
              }}
            >{`${Math.round((1 - part.discount_price / part.item_price) * 100)}% `}</span>
          )}
          <span className="mt-[8px] font-bold">{(part.discount_price > 0 ? part.discount_price : part.item_price).toLocaleString("ko-KR")}원</span>

          <div
            // 별점 / 리뷰
            className="mt-[4px] text-12px flex justify-center"
            onClick={() => nav(`/review/${type}/${pid}`)}
          >
            <p className="relative text-20px text-[#EEEEEE]">
              ★★★★★
              <span
                className="text-[#FF8E00] text-20px absolute left-0 overflow-hidden"
                style={{
                  width: part.review_rate ? `${part.review_rate * 20}%` : 0,
                }}
              >
                ★★★★★
              </span>
            </p>
            <p className="inline self-center">
              <span className="ml-[4px]">
                {part.review_rate ? part.review_rate : 0}
                &nbsp;&nbsp;/&nbsp;&nbsp;
              </span>
              <span className="underline">{part.review_count ? part.review_count : 0}개의 리뷰</span>
            </p>
          </div>

          {part.spec_img.length
            ? part.spec_img
                .split(",")
                .filter((url) => url)
                .map((dt) => (
                  <img
                    src={process.env.REACT_APP_IMAGE_BASE + dt + `?timestamp=${new Date().getTime()}`}
                    className="mx-auto mt-[12px]"
                    alt="제품 설명 이미지"
                  />
                ))
            : null}

          {/* <div className="bf-banner" style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
            <iframe
              title="External HTML Viewer"
              src="https://gi.esmplus.com/og9og9/baterry/geekvape/PEAK/peakkit.html"
              style={{ position: "absolute", border: "0" }}
              allowFullScreen
            />
          </div> */}
          {/* 하단 버튼 모달과 충돌을 피하기 위해 90px 부스트업 */}
          <div className="bg-[#F3F3F3] py-[24px] mb-[90px] px-[16px] text-left tracking-[-0.28px]">
            <p className="font-medium">배송 안내</p>
            <p className="mt-[18px] text-left text-14px break-all text-[#1B1B1B]">
              · 결제 즉시 영업일 기준(주말 및 공휴일 제외) 2~3일 내에 배송 완료됩니다.
              <br />· 5만원 이상 주문은 무료배송, 5만원 미만은 3,000원의 배송비가 있습니다(제주도 및 도서산간 지역은 5,000원).
            </p>
            <p className="mt-[18px] font-medium">교환, 환불, 수리 안내</p>
            <p className="mt-[18px] text-left text-14px break-all text-[#1B1B1B]">
              · 상품 수령일로부터 7일 이내 교환 및 환불이 가능합니다.
              <br />· 제품 특성상 개봉 하였을 경우, 제품 자체에 문제가 없는 한 교환 및 환불이 어렵습니다.
              <br />· 변심 환불의 경우 왕복 배송비를 차감한 금액이 환불되며, 제품 및 포장 상태가 재판매 가능하여야 합니다.
              <br />· 상품 불량의 경우 배송비를 포함한 전액이 환불됩니다.
              <br />· 출고 이후 환불 요청 시 상품 회수 후 처리됩니다.
              <br />· 제품 수리는 기기 제조사 정책에 따라 상이하며,{" "}
              <a href="http://pf.kakao.com/_qJbjG/chat" target="_blank" rel="noreferrer" className="text-yellow-500">
                안도 카카오톡 플러스 계정
              </a>
              으로 문의 주시면 친절히 도와드리겠습니다.
            </p>
          </div>

          {purchasing || basketing ? (
            // 음영
            <p
              className="absolute top-[0px] w-full min-w-[360px] h-full bg-[rgba(28,28,28)]/80"
              onClick={() => {
                setBasket(false);
                setPurchase(false);
              }}
            />
          ) : null}
          {/* 하단 메뉴 */}
          <div className={`w-full min-w-[360px] bg-white fixed bottom-0 z-20${purchasing || basketing ? " h-[360]" : " h-[98px]"}`}>
            {purchasing || basketing ? (
              <div className="mx-[16px]">
                <div className="flex justify-between my-[15px]">
                  <p>{part.item_name}</p>
                  <div className="flex">
                    <p
                      className="w-[30px] h-[30px] rounded-full bg-[#F6F6F6] flex flex-col justify-center items-center"
                      onClick={() => {
                        if (quantity > 1) setQuantity((cur) => cur - 1);
                      }}
                    >
                      <img src="/imageDB/common/minus.svg" width={13.5} alt="덜기" />
                    </p>
                    <p>
                      <span className="mx-[6px] leading-[18.75px] inline-block align-bottom">{quantity}</span>
                    </p>
                    <p
                      className="w-[30px] h-[30px] rounded-full bg-[#F6F6F6] flex flex-col justify-center items-center"
                      onClick={() => setQuantity((cur) => cur + 1)}
                    >
                      <img src="/imageDB/common/plus.svg" width={13.5} alt="담기" />
                    </p>
                  </div>
                </div>

                <hr className="border-[#757575]" />

                <div className="flex justify-between">
                  {part.options && part.options.length ? (
                    <select className="self-end h-fit" onChange={(e) => setOption(e.target.value)} value={item_option}>
                      <option value="">옵션 선택</option>
                      {part.options.map((option, i) => (
                        <option value={option} key={`옵션_${i + 1}`}>
                          {option}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <p /> // 우측 정렬을 위한 placeholder
                  )}
                  <p className="mt-[10px] font-bold text-right text-[#0E0E0E]">
                    총 {(quantity * (part.discount_price > 0 ? part.discount_price : part.item_price)).toLocaleString("ko-KR")}원
                  </p>
                </div>
              </div>
            ) : null}

            <p className="mt-[21px] mb-[17px] w-full min-w-[360px] max-w-[720px] mx-auto flex flex-row justify-center">
              {!basketing ? (
                purchasing && part.stock <= 0 ? (
                  <button className="w-[328px] h-[52px] bg-black text-white rounded-[8px]" disabled style={{ backgroundColor: "#E3E3E3", color: "#969696" }}>
                    {part.stock <= 0 ? "품절" : "담기"}
                  </button>
                ) : (
                  <>
                    <button
                      className="border border-[#E6E6E6] rounded-[8px] w-[62px] h-[62px] mr-[3px] flex items-center justify-center"
                      onClick={async () => {
                        var res = await axios
                          .get(`${process.env.REACT_APP_FRONT_URL}/api/user/updateFav?item_id=${part.item_id}&increment=${part.fav === 1 ? -1 : 1}`)
                          .catch((err) => console.err(err));
                        if (res.data === "login") {
                          sessionStorage.setItem("path", loc.pathname);
                          return nav(`/kakaoLogin`);
                        } else {
                          setPart((prev) => ({ ...prev, fav: part.fav === 1 ? -1 : 1 }));
                        }
                      }}
                    >
                      <img
                        src={part.fav === 1 ? "/imageDB/common/fullHeart.svg" : "/imageDB/common/heart.svg"}
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                        alt="Ando-heart"
                      />
                    </button>
                    <button
                      className="border border-[#E6E6E6] rounded-[8px] w-[255px] h-[62px] ml-[4px] flex justify-center items-center"
                      style={{ backgroundColor: "#0081F8", color: "white" }}
                      onClick={() =>
                        axios
                          .get(`${process.env.REACT_APP_FRONT_URL}/api/user/checkLogin`)
                          .then((res) => {
                            if (res.data === "login") {
                              sessionStorage.setItem("path", loc.pathname);
                              nav(`/kakaoLogin`);
                            } else {
                              if (!basketing && !purchasing) setBasket(true);
                              else {
                                if (part.options && !item_option) {
                                  alert("옵션을 선택해주세요!");
                                  return;
                                }
                                dispatch(setAddIdle(false));
                                dispatch(
                                  addCartItem({
                                    user_id,
                                    item_id: part.item_id,
                                    item_option,
                                    quantity,
                                  }),
                                );
                                setBasket(false);
                                setPurchase(false);
                                setQuantity(1);
                                alert("장바구니에 추가되었습니다!");
                                setOption("");
                              }
                            }
                          })
                          .catch((err) => console.err(err))
                      }
                    >
                      구매하기
                    </button>
                    {/* <button
                      className="bg-black text-white rounded-[8px] w-[161px] h-[52px]"
                      onClick={() => {
                        axios.get(`${process.env.REACT_APP_FRONT_URL}/api/user/checkLogin`).then((res) => {
                          if (res.data === "login") {
                            sessionStorage.setItem("path", loc.pathname);
                            nav(`/kakaoLogin`);
                          } else {
                            if (!purchasing) setPurchase(true);
                            else {
                              if (part.options && !item_option) {
                                alert("옵션을 선택해주세요!");
                                return;
                              }
                              nav(`/purchase/${type}/${pid}`, {
                                state: {
                                  purchase_quantity: quantity,
                                  item_option,
                                },
                              });
                            }
                          }
                        });
                      }}
                    >
                      바로구매
                    </button> */}
                  </>
                )
              ) : basketing ? (
                <button
                  className="w-[328px] h-[62px] text-white rounded-[8px]"
                  disabled={part.stock <= 0}
                  style={part.stock <= 0 ? { backgroundColor: "#E3E3E3", color: "#969696" } : { backgroundColor: "#0081F8" }}
                  onClick={() => {
                    if (part.options && !item_option) {
                      alert("옵션을 선택해주세요!");
                      return;
                    }
                    dispatch(setAddIdle(false));
                    dispatch(
                      addCartItem({
                        user_id,
                        item_id: part.item_id,
                        item_option,
                        quantity,
                      }),
                    );
                    setBasket(false);
                    setPurchase(false);
                    setQuantity(1);
                    alert("장바구니에 추가되었습니다!");
                    setOption("");
                  }}
                >
                  {part.stock <= 0 ? "품절" : "장바구니 담기"}
                </button>
              ) : null}
            </p>
          </div>
        </div>
      </div>
    ) : (
      <h2 className="pt-[32px]">해당 페이지는 존재하지 않습니다.</h2>
    )
  ) : null;
}
