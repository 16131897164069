import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setFavs, setFLloading } from "../../redux/module/item_fav";
import { setSearchText } from "../../redux/module/custom_str";

export default function ItemCard({ item, type }) {
  const nav = useNavigate();
  const loc = useLocation();
  const dispatch = useDispatch();

  const favs = useSelector((state) => state.item_fav.favs);

  const alterFav = async (inc) => {
    var res = await axios
      .get(`${process.env.REACT_APP_FRONT_URL}/api/user/updateFav?item_id=${item.item_id}&increment=${inc}`)
      .catch((err) => console.err(err));
    if (res.data === "login") {
      sessionStorage("path", loc.pathname);
      return nav(`/kakaoLogin`);
    } else if (inc === -1) {
      const idx = favs.indexOf(item.item_id);
      const arr = [...favs];
      if (idx > -1) arr.splice(idx, 1);
      dispatch(setFavs([...arr]));
      dispatch(setFLloading(true));
    } else {
      dispatch(setFavs([...favs, item.item_id]));
      dispatch(setFLloading(true));
    }
  };

  return item ? (
    <div className="mt-[4px] mb-[16px] flex flex-col">
      <div
        className="bg-[#F5F5F5] h-[166px] w-full relative"
        onClick={() =>
          nav(
            `/detail/${item.item_id[3] === "C" ? "coil" : item.item_id[3] === "P" ? "pod" : item.item_id[3] === "M" ? "machine" : "liquid"}/${parseInt(
              item.item_id.slice(4),
            )}`,
          )
        }
      >
        {/* <div
          className="px-[8px] py-[5px] bg-[#FF2F61] text-white font-medium text-[14px]"
          style={{
            position: "absolute",
            width: "fit-content",
            marginLeft: 0,
            marginRight: "auto",
          }}
        >
          블프
        </div> */}
        {favs.includes(item.item_id) ? (
          <button
            className="absolute top-0 right-0"
            onClick={(e) => {
              e.stopPropagation();
              alterFav(-1);
            }}
          >
            <img src="/imageDB/common/heartFull.svg" alt="찜한 상품" />
          </button>
        ) : (
          <button
            className="absolute top-0 right-0"
            onClick={(e) => {
              e.stopPropagation();
              alterFav(1);
            }}
          >
            <img src="/imageDB/common/heartEmpty.svg" alt="찜하지 않은 상품" />
          </button>
        )}
        <img
          src={process.env.REACT_APP_IMAGE_BASE + item.item_img + `?timestamp=${new Date().getTime()}&w=256&q=75&f=webp`}
          className="mx-auto h-full"
          alt="상품"
        />
      </div>
      <div className="mt-[12px] text-left tracking-[-0.28px]">
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <p
            className="text-14px font-medium"
            style={{}}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setSearchText(item.item_company));
              window.scrollTo({ top: 0, left: 0 });
            }}
          >
            {item.item_company + " "}
          </p>
          <div
            style={
              item.stock <= 0
                ? {
                    display: "inline-block",
                    width: "28px",
                    height: "18px",
                    margin: "0 0 0 4px",
                    lineHeight: "18px",
                    borderRadius: "5px",
                    fontSize: "10px",
                    fontWeight: "bold",
                    color: "#FFFFFF",
                    backgroundColor: "#B8B8B8",
                    textAlign: "center",
                  }
                : { display: "none" }
            }
          >
            {item.stock <= 0 && "품절"}
          </div>
        </div>
        <p className="text-14px">{item.item_name}</p>
        {item.discount_price > 0 && (
          <span
            style={{
              fontFamily: "GOOD BRUSH",
              fontSize: "20px",
              color: "red",
            }}
          >{`${Math.round((1 - item.discount_price / item.item_price) * 100)}% `}</span>
        )}
        <span className="font-bold text-16px">{(item.discount_price > 0 ? item.discount_price : item.item_price).toLocaleString("ko-KR")}원</span>
        {Number(item.review_count) > 0 && (
          <div className="flex text-12px Roboto" onClick={() => nav(`/review/${type}/${parseInt(item.item_id.slice(4))}`)}>
            <img src="/imageDB/common/rankStar.svg" className="inline" alt="별점" />
            <p className="ml-[4px] text-[#464646]">
              <span className="align-sub">{item.review_rate.toFixed(1)}</span>
            </p>
            <p className="ml-[4px] text-[#A8A8A8]">
              <span className="align-sub">({item.review_count})</span>
            </p>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "144px",
            marginTop: "4px",
          }}
        >
          {item.item_badges && typeof item.item_badges === "object"
            ? item.item_badges.map(
                (badge, idx) =>
                  badge && (
                    <img key={idx} src={`${process.env.REACT_APP_IMAGE_BASE}${badge}`} style={{ height: "16px", marginRight: "4px" }} alt={`Badge ${idx}`} />
                  ),
              )
            : null}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
