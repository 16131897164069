import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMachineSaves,
  setMachineSaves,
} from "../../redux/module/item_machine";
import { setCustom } from "../../redux/module/custom_str";

export default function MyDevList() {
  const dispatch = useDispatch();
  const machine_infos = useSelector(
    (state) => state.item_machine.save_machine_infos
  );

  const [machine_select, setCheck] = useState([]);
  const [checkCount, setCheckCount] = useState(0);

  const removeDev = function () {
    let id_str = machine_infos
      .map((it, idx) => (machine_select[idx] ? it.item_id : null))
      .toString();
    axios
      .get(
        `${process.env.REACT_APP_FRONT_URL}/api/item/machine/deleteSaves?item_id=${id_str}`
      ) // 선택된 기기를 삭제하는 api
      .then(() => {
        const arr = [...machine_infos];
        for (let i = machine_infos.length - 1; i >= 0; i--) {
          if (machine_select[i]) arr.splice(i, 1);
        }
        dispatch(setMachineSaves([...arr]));
      })
      .catch((err) => console.err(err));
  };

  useEffect(() => {
    dispatch(setCustom("내 기기 목록"));
    if (!(machine_infos && machine_infos.length)) dispatch(fetchMachineSaves());
  }, [dispatch, machine_infos]);

  useEffect(() => {
    setCheck(new Array(machine_infos.length).fill(false));
    setCheckCount(0);
  }, [machine_infos]);

  return machine_infos && machine_infos.length ? (
    <>
      <div className="grid grid-cols-2 gap-[12px] my-[20px] mx-[16px]">
        {machine_infos.map((elem, itr) => (
          <div
            key={"my_dev_list_" + itr}
            className={`w-[158px] h-[156px] mx-auto bg-[#F5F5F5] rounded-[10px] border-2 ${
              machine_select[itr] ? "border-[#696969]" : "border-transparent"
            } text-12px relative`}
            onClick={() => {
              setCheck((arr) => {
                arr[itr] = !arr[itr];
                if (machine_select[itr]) setCheckCount((cnt) => cnt + 1);
                else setCheckCount((cnt) => cnt - 1);
                return [...arr];
              });
            }}
          >
            {machine_select[itr] ? (
              <p className="w-[24px] h-[24px] rounded-full bg-[#696969] flex absolute top-[12px] right-[12px]">
                <img
                  src="/imageDB/my_page/my_dev/dev_check.svg"
                  className="self-center mx-auto"
                  alt="선택"
                />
              </p>
            ) : null}
            <p className="mt-[8px] h-[93px] flex">
              <img
                src={process.env.REACT_APP_IMAGE_BASE + elem.item_img + `?timestamp=${new Date().getTime()}`}
                className="mx-auto self-center"
                width={93}
                alt="기기 이미지"
              />
            </p>
            <div className="h-[42px] flex flex-col justify-center">
              <p className="font-medium">{elem.item_company}</p>
              <p>{elem.item_name}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full min-w-[360px] fixed bottom-[33px]">
        {checkCount > 0 ? (
          <div
            className="w-[328px] h-[54px] rounded-[40px] mx-auto bg-black text-white"
            onClick={() => {
              removeDev();
            }}
          >
            <p className="mt-[14px] inline-block">
              {checkCount > 1 && `${checkCount}개 `}기기 삭제하기
            </p>
          </div>
        ) : (
          <div className="w-[328px] h-[54px] rounded-[40px] mx-auto bg-[#B9B9B9] text-white">
            <p className="mt-[14px] inline-block">기기 삭제하기</p>
          </div>
        )}
      </div>
      <p className="h-[100px]" />
    </>
  ) : (
    <p className="mt-[50%]">
      아직 나의 기기를 등록하지 않았어요
      <br />
      기기를 등록해보세요!
    </p>
  );
}
