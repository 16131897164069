import { useNavigate } from "react-router-dom";

export const PaginationAndo = ({ page, last }) => {
  const nav = useNavigate();

  let LSN = 0,
    MSN = 0; // 각각 제일 작은 수, 제일 큰 수

  if (page <= 3) LSN = 1;
  else if (last - 2 <= page) LSN = last - 4;
  else if (last - 1 <= page) LSN = last - 3;
  else LSN = page - 2;

  if (last <= 5) MSN = last;
  else if (page + 2 < 5) MSN = 5;
  else if (page + 2 <= last) MSN = page + 2;
  else if (page + 1 <= last) MSN = page + 1;
  else MSN = page;

  const page_array = new Array(MSN - LSN + 1).fill(0).map((_, itr) => itr + LSN);

  return (
    <div className="my-[16px] flex justify-center">
      {page !== 1 ? (
        <button onClick={() => nav(`../${page - 1}`)}>
          <img src="/imageDB/common/chevronLeft.svg" className="p-[12px]" alt="이전" />
        </button>
      ) : (
        <p className="w-[35px] h-[42px]" />
      )}
      {page_array.map((num, i) => (
        <button
          key={i}
          className={`text-20px w-[32px] mx-[8px]${page === num ? " font-bold underline" : ""}`}
          disabled={page === num}
          onClick={() => nav(`../${num}`)}
        >
          {num}
        </button>
      ))}
      {page !== last ? (
        <button onClick={() => nav(`../${page + 1}`)}>
          <img src="/imageDB/common/chevronLeft.svg" className="rotate-180 p-[12px]" alt="다음" />
        </button>
      ) : (
        <p className="w-[35px] h-[42px]" />
      )}
    </div>
  );
};
