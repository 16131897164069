import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";

const fetchItem = createAsyncThunk("item_all/fetchItem", ({ query_clause }) =>
  Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/item/all?${query_clause}`)
    .then(
      (res) => {
        if (res.data && res.data.length) return res.data;
        else console.log("DB 서버 오류AI");
      }
      // console.log(res.data)
    )
    .catch(() => console.log("DB 통신 오류AI2"))
);

const item_all = createSlice({
  name: "item_all",
  initialState: {
    items: [],
    items_count: 0,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchItem.fulfilled, (state, action) => {
      if (action.payload) {
        if (action.payload[1].length) {
          state.items_count = action.payload[0];
          state.items = action.payload[1];
        } else {
          state.items_count = 0;
          state.items = [];
        }
      }
    });
  },
});

export { fetchItem, item_all };
