import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCustom } from "../../redux/module/custom_str";
import DaumPostcode from "./DaumPostcode";
import { setUserInfo } from "../../redux/module/user_info";
import { useSearchParams } from "react-router-dom";

export default function MyAccount() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const user_info_store = useSelector((state) => state.user_info.data);
  const [user_info, setLocalUserInfo] = useState({});

  // const [isWelcome, setWelcome] = useState(false);

  useEffect(() => {
    if (searchParams.get("new")) {
      setSearchParams({});
      // setWelcome(true);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (user_info_store.user_id) setLocalUserInfo(user_info_store);
  }, [user_info_store]);

  useEffect(() => {
    dispatch(setCustom(""));
  }, [dispatch]);

  const [isActive, setActive] = useState(false);

  // const WelcomeModal = () => (
  //   <>
  //     {/* 음영과 이미지, 버튼 */}
  //     <div className="absolute top-0 z-[21] w-full min-w-[360px] h-screen bg-[rgba(28,28,28)]/80" onClick={() => setWelcome(false)}>
  //       <img
  //         src={`${process.env.REACT_APP_IMAGE_BASE}/IMAGE+DB/ETC/WELCOME_TO_ANDO.png?timestamp=${new Date().getTime()}`}
  //         width={286}
  //         className="mt-[65px] mx-auto"
  //         alt="welcome"
  //       />
  //       <button className="absolute top-[611px] inset-x-0 mx-auto rounded-[22px] w-[256px] h-[56px] bg-[#FFD600]">
  //         <p className="flex justify-center w-fit mx-auto">
  //           <img src="/imageDB/my_ando/AndoPoint.png" width={36} height={36} className="inline mr-[4px]" alt="안도 포인트" />
  //           <span className="self-center font-black">3,000P</span>
  //           &nbsp;
  //           <span className="font-bold self-center">받고 시작하기</span>
  //         </p>
  //       </button>
  //     </div>
  //   </>
  // );

  // const ShowModal = () => (
  //   <>
  //     <div className="flex">
  //       <p className="font-bold text-[24px]">안도에 오신 것을 환영합니다 😊</p>
  //     </div>

  //     <div className="text-14px">
  //       <div className="flex justify-between mt-[20px] h-[24px]">
  //         <div className="flex">
  //           <p className="text-[#858585] w-[88px] self-center">닉네임을 입력해주세요</p>
  //           <p className="w-[190px] pl-[4px] border-2 border-transparent">{user_info.user_nickname}</p>
  //         </div>
  //       </div>
  //       {/* <div className="flex h-[24px]">
  //         <p className="text-[#858585] w-[88px]">이름</p>
  //         <p>{user_info.user_name}</p>
  //       </div> */}
  //       <div className="flex h-[24px] mt-[16px]">
  //         <p className="text-[#858585] w-[88px] self-center">연락처</p>
  //         {user_info.user_contact ? (
  //           <p className="pl-[4px] border-2 border-transparent">{user_info.user_contact}</p>
  //         ) : (
  //           <p className="pl-[4px] border-2 border-transparent text-[#858585]">연락처를 입력 해주세요!</p>
  //         )}
  //       </div>
  //       <p className="h-[24px] mt-[16px] text-[#858585] w-[88px]">주소</p>
  //       {user_info.user_addr1 ? (
  //         <p className="mt-[8px] bg-[#F6F6F6] pl-[4px] border-2 border-transparent">{user_info.user_addr1}</p>
  //       ) : (
  //         <p className="mt-[8px] bg-[#F6F6F6] pl-[4px] border-2 border-transparent  text-[#858585]">주소를 입력 해주세요!</p>
  //       )}
  //       {user_info.user_addr2 ? (
  //         <p className="mt-[8px] bg-[#F6F6F6] pl-[4px] border-2 border-transparent">{user_info.user_addr2}</p>
  //       ) : (
  //         <p className="mt-[8px] bg-[#F6F6F6] pl-[4px] border-2 border-transparent text-[#858585]">상세주소를 입력 해주세요!</p>
  //       )}
  //       <p className="h-[24px] mt-[16px] text-[#858585]">배송 요청사항</p>
  //       {user_info.user_def_order_msg ? (
  //         <p className="mt-[8px] bg-[#F6F6F6] pl-[4px] border-2 border-transparent">{user_info.user_def_order_msg}</p>
  //       ) : (
  //         <p className="mt-[8px] bg-[#F6F6F6] pl-[4px] border-2 border-transparent text-[#858585]">배송 요청사항을 입력 해주세요!</p>
  //       )}
  //     </div>
  //   </>
  // );
  const ShowModal = () => (
    <>
      <div className="flex justify-between">
        <p className="font-medium">회원 정보</p>
        <p className="flex text-14px self-end">
          <button className="px-[4px]" onClick={() => setActive((cur) => !cur)}>
            변경하기
          </button>
        </p>
      </div>
      <hr className="mt-[16px] border-[#D2D2D2]" />

      <div className="text-14px">
        <div className="flex justify-between mt-[20px] h-[24px]">
          <div className="flex">
            <p className="text-[#858585] w-[88px] self-center">닉네임</p>
            <p className="w-[190px] pl-[4px] border-2 border-transparent">{user_info.user_nickname}</p>
          </div>
        </div>
        {/* <div className="flex h-[24px]">
          <p className="text-[#858585] w-[88px]">이름</p>
          <p>{user_info.user_name}</p>
        </div> */}
        <div className="flex h-[24px] mt-[16px]">
          <p className="text-[#858585] w-[88px] self-center">연락처</p>
          {user_info.user_contact ? (
            <p className="pl-[4px] border-2 border-transparent">{user_info.user_contact}</p>
          ) : (
            <p className="pl-[4px] border-2 border-transparent text-[#858585]">연락처를 입력 해주세요!</p>
          )}
        </div>
        <p className="h-[24px] mt-[16px] text-[#858585] w-[88px]">주소</p>
        {user_info.user_addr1 ? (
          <p className="mt-[8px] bg-[#F6F6F6] pl-[4px] border-2 border-transparent">{user_info.user_addr1}</p>
        ) : (
          <p className="mt-[8px] bg-[#F6F6F6] pl-[4px] border-2 border-transparent  text-[#858585]">주소를 입력 해주세요!</p>
        )}
        {user_info.user_addr2 ? (
          <p className="mt-[8px] bg-[#F6F6F6] pl-[4px] border-2 border-transparent">{user_info.user_addr2}</p>
        ) : (
          <p className="mt-[8px] bg-[#F6F6F6] pl-[4px] border-2 border-transparent text-[#858585]">상세주소를 입력 해주세요!</p>
        )}
        <p className="h-[24px] mt-[16px] text-[#858585]">배송 요청사항</p>
        {user_info.user_def_order_msg ? (
          <p className="mt-[8px] bg-[#F6F6F6] pl-[4px] border-2 border-transparent">{user_info.user_def_order_msg}</p>
        ) : (
          <p className="mt-[8px] bg-[#F6F6F6] pl-[4px] border-2 border-transparent text-[#858585]">배송 요청사항을 입력 해주세요!</p>
        )}
      </div>
    </>
  );

  const InputModal = () => {
    const nickname_ref = useRef();
    const contact_ref = useRef();
    const search_address_ref = useRef(user_info.user_addr1);
    const input_address_ref = useRef(user_info.user_addr2);
    const user_order_msg_ref = useRef();

    const setSearch = (val) => (search_address_ref.current = val);
    const setInput = (val) => (input_address_ref.current = val);

    const checkAndSubmit = () => {
      const regex = /^[ㄱ-ㅎ가-힣ㅏ-ㅣa-zA-Z0-9]{1,12}$/;
      const regex2 = /^[0-9]{10,11}$/;
      if (!regex.test(nickname_ref.current.value)) {
        alert("닉네임은 한글,영문,숫자로 작성 가능합니다. 최소 1글자 이상, 최대 12글자까지 지원되며 특수문자 및 띄어쓰기는 금지되어 있습니다.");
        return;
      }
      if (!regex2.test(contact_ref.current.value)) {
        alert("연락처는 10~11자리 번호만 적어주세요.");
        return;
      }
      const temp_user_info = {
        user_nickname: nickname_ref.current.value,
        user_contact: contact_ref.current.value,
        user_addr1: search_address_ref.current,
        user_addr2: input_address_ref.current,
        user_def_order_msg: user_order_msg_ref.current.value,
      };
      setLocalUserInfo(temp_user_info);
      dispatch(
        setUserInfo({
          ...temp_user_info,
          user_id: user_info_store.user_id,
        }),
      );
      setActive(false);

      //console.log("여까지 오긴 했냐");
    };

    return (
      <>
        <div className="flex justify-between">
          <p className="font-medium">회원 정보</p>
          <div className="flex text-14px self-end">
            <button className="mr-[8px] px-[4px]" onClick={() => setActive(false)}>
              취소하기
            </button>
            <button className="px-[4px]" onClick={checkAndSubmit}>
              저장하기
            </button>
          </div>
        </div>
        <hr className="mt-[16px] border-[#D2D2D2]" />

        <div className="text-14px">
          <div className="flex justify-between mt-[20px] h-[24px]">
            <div className="flex">
              <p className="text-[#858585] w-[88px] self-center">닉네임</p>
              <input className="w-[190px] pl-[4px] border-2" defaultValue={user_info.user_nickname} ref={nickname_ref} />
            </div>
          </div>
          <div className="flex h-[24px] mt-[16px]">
            <p className="text-[#858585] w-[88px] self-center">연락처</p>
            <input
              className="w-[190px] pl-[4px] border-2 invalid:border-red-500"
              placeholder="휴대폰 번호 11자리를 입력해 주세요"
              defaultValue={user_info.user_contact}
              type="text"
              pattern="(?:\d{10}|\d{11})"
              onChange={(e) => {
                if (e.target.value.length > 11) e.target.value = e.target.value.slice(0, 11);
              }}
              ref={contact_ref}
            />
          </div>
          <p className="h-[24px] mt-[16px] text-[#858585] w-[88px]">주소</p>
          <DaumPostcode setSearch={setSearch} setInput={setInput} />
          <p className="h-[24px] mt-[16px] text-[#858585]">배송 요청사항</p>
          <input className="w-[190px] pl-[4px] border-2" defaultValue={user_info.user_def_order_msg} ref={user_order_msg_ref} />
        </div>
      </>
    );
  };

  return (
    <>
      {/* {isWelcome && <WelcomeModal />} */}
      <div className="mt-[24px] mx-[16px] text-left">
        {!isActive ? <ShowModal /> : <InputModal />}

        {/* <hr className="mt-[32px] border-[#D2D2D2]" />
        <button className="mt-[9px] text-14px text-[#373737]" onClick={() => alert("가지 말아요~")}>
          탈퇴하기
        </button> */}
      </div>
    </>
  );
}
