const Login = () => {
  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  window.addEventListener("resize", () => setScreenSize());

  // const link = () => {
  //   //console.log("들어왔음1");
  //   //window.open(process.env.REACT_APP_KAKAO_LOGIN_AUTH_URL, "_self");
  //   axios.get("/api/oauth/kakao", (req, res) => {
  //     //
  //   });
  // };

  // 다른 계정으로 로그인 하기를 원한다면,
  // &prompt=login을 url 끝에 붙이면 됨!

  return (
    <div className="text-white" style={{ height: `calc(var(--vh, 1vh) * 100)`, backgroundColor: "#0063F8" }}>
      <img className="mx-auto login-image" style={{ padding: "5% 16px 0 16px" }} src="/imageDB/my_page/login/login_back.png" alt="전자담배의 모든것, 안도" />
      {/* <div className="mx-auto w-fit">
        <p className="mt-[24px] text-20px font-bold">전자담배의 모든 것</p>
        <p className="mt-[24px] text-15px">
          지금 바로 안도에 가입해
          <br />
          편안한 베이핑 생활을 시작해보세요.
        </p>
      </div> */}

      <button className="login-image" style={{ marginTop: "23px" }}>
        <a href={process.env.REACT_APP_KAKAO_LOGIN_AUTH_URL}>
          <img className="cursor-pointer" style={{ width: "100%", padding: "0 16px" }} alt="카카오 로그인" src="/imageDB/my_page/login/kakao_button.png" />
        </a>
      </button>
    </div>
  );
};

export default Login;
