import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// export function Banner({ banner_detail, banner_link, btn_text, search_text }) {
export function Banner() {
  const nav = useNavigate();
  const { id } = useParams();

  const banner_all = useSelector((state) => state.banner.all);
  const [banner, setBanner] = useState({
    banner_id: 0,
    banner_thumb: "",
    banner_detail: "",
    location: "",
    banner_link: "",
    btn_text: "",
    search_text: null,
  });
  useEffect(() => {
    if (banner_all.length) setBanner(banner_all[id - 1]);
  }, [banner_all, id]);

  // -1: 로그인 안 됨, 0: 초기 상태 (공백 표시), 1: 로그인 됨
  const [isLoggedIn, setLoggedIn] = useState(0);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FRONT_URL}/api/user/checkLogin`)
      .then((res) => setLoggedIn(res.data === "login" ? -1 : 1));
  }, [nav]);

  const navBanner = () => {
    banner.search_text
      ? nav(banner.banner_link, {
          state: {
            search_text: banner.search_text,
          },
        })
      : nav(banner.banner_link);
  };

  const BottomBarIn = () => (
    <button
      className="self-center w-[330px] h-[52px] rounded-[8px] bg-[#00E4BB] text-white"
      onClick={navBanner}
    >
      {banner.btn_text}
    </button>
  );

  const BottomBarOut = () => (
    <>
      <button
        className="self-center w-[161px] h-[52px] rounded-[8px] bg-white border border-[#E6E6E6] mr-[7px]"
        onClick={() => nav("/kakaoLogin")}
      >
        로그인 하기
      </button>
      <button
        className="self-center px-[35px] py-[14px] rounded-[8px] bg-[#00E4BB] text-white"
        onClick={navBanner}
      >
        {banner.btn_text}
      </button>
    </>
  );

  return banner.banner_id ? (
    <>
      <img
        src={`${process.env.REACT_APP_IMAGE_BASE}${banner.banner_detail}?timestamp=${new Date().getTime()}`}
        className="mx-auto mb-[90px]"
        alt="이벤트 상세설명"
      />
      <p className="fixed bottom-0 w-full h-[90px] bg-white flex justify-center z-20">
        {isLoggedIn === 1 ? (
          <BottomBarIn />
        ) : isLoggedIn === -1 ? (
          <BottomBarOut />
        ) : null}
      </p>
    </>
  ) : (
    <p className="mt-[32px]">배너 로딩 중...</p>
  );
}
