import Marquee from "react-fast-marquee";

const RollingText = () => {
  const bannerTexts = ["💝 HAPPY NEW YEAR 💝", "새해 맞이 특별 세일 시작 🤩", "🔮 HAPPY NEW YEAR 🔮", "행운의 주인공이 되어보세요 🥰", "🍀 HAPPY NEW YEAR 🍀"];

  return (
    <div className="bg-[#E2F354] h-[26px] flex space-x-10 overflow-hidden">
      <Marquee speed={30}>
        {bannerTexts.map((text, index) => (
          <div key={index} className="flex-none whitespace-nowrap text-black text-regular text-[14px] mr-[40px]">
            {text}
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default RollingText;
