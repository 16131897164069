import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";

const fetchBanner = createAsyncThunk("banner/fetchBanner", () =>
  Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/banner`)
    .then((res) => res.data)
    .catch(() => console.log("DB 통신 오류B"))
);

const banner = createSlice({
  name: "banner",
  initialState: {
    all: [],
    home: [],
    shop: [],
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBanner.fulfilled, (state, action) => {
      if (action.payload && action.payload.length > 0) {
        state.all = action.payload;
        state.home = action.payload.filter((b) =>
          b.length > 0 ? ["common", "home"].includes(b.location) : []
        );
        state.shop = action.payload.filter((b) =>
          b.length > 0 ? ["common", "shop"].includes(b.location) : []
        );
      }
    });
  },
});

export { fetchBanner, banner };
