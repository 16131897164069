import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import FilterMachine from "./FilterMachine";
import OrderModal from "../OrderModal";
import { PaginationAndo } from "../PaginationAndo";
import Meta from "../../Meta";

import { fetchBrands, fetchItem } from "../../../redux/module/item_machine";
import ItemCard from "../../common/itemCard";
import { useNavigate, useParams } from "react-router-dom";
import { setSearchPlaceholder, setSearchText } from "../../../redux/module/custom_str";

import RollingText from "../../../components/BlackFriday/RollingText";
// import CountdownTimer from "../../../components/BlackFriday/CountDown";

export default function ShopMachine() {
  const dispatch = useDispatch();
  const page = parseInt(useParams().page);
  const { state } = useLocation();

  useEffect(() => {
    dispatch(setSearchPlaceholder("브랜드, 기기명으로 검색해보세요!"));
  }, [dispatch]);

  const search_text = useSelector((state) => state.custom_str.search_text);

  const items = useSelector((state) => state.item_machine.items);
  const items_count = useSelector((state) => state.item_machine.items_count);
  const list_brand = useSelector((state) => state.item_machine.brands);

  const [order, setOrder] = useState("best");
  const [order_text, setOrderText] = useState("BEST");
  const [toggle_order, setToggleOrder] = useState(false); // 정렬 드롭다운 토글 여부
  const order_options = ["BEST", "NEW", "낮은 가격순", "높은 가격순"];

  const [typeFilter, setFilterType] = useState(0);
  const filterOptionsMachine = ["기능", "브랜드"];

  const setOrderOption = (text) => {
    let mode;
    switch (text) {
      case "BEST":
        mode = "best";
        break;
      case "NEW":
        mode = "new";
        break;
      case "낮은 가격순":
        mode = "cheap";
        break;
      case "높은 가격순":
        mode = "expensive";
        break;
      default:
        text = "NEW";
        mode = "new";
        break;
    }
    setOrder(mode);
    setOrderText(text);
    setToggleOrder(false);
  };

  const nav = useNavigate();

  const [query_clause, setQuery] = useState("");

  useEffect(() => {
    // dispatch(setSearchText(""))
    if (state && state.search_text) dispatch(setSearchText(state.search_text));
  }, [dispatch, state]);

  const [prev_val, setPrevVal] = useState({
    query_clause: "",
    search_text: "",
    order: "best",
  });

  useEffect(() => {
    dispatch(
      fetchItem({
        query_clause: `${query_clause}page=${page}&search_text=${search_text}&order=${order}`,
      }),
    );
    if ((query_clause !== prev_val.query_clause || search_text !== prev_val.search_text || order !== prev_val.order) && page > 1) {
      nav("../1");
      setPrevVal({ query_clause, search_text, order });
    }
  }, [dispatch, nav, order, page, prev_val.order, prev_val.query_clause, prev_val.search_text, query_clause, search_text]);

  useEffect(() => {
    if (!list_brand.length) dispatch(fetchBrands());
  }, [dispatch, list_brand.length]);

  return (
    <div className={`${typeFilter || toggle_order ? "h-screen overflow-hidden" : null}`}>
      <Meta
        title="기기 - 안도 전자담배"
        siteUrl="https://andocompany.com/shop/machine/1"
        desc="내 맘에 쏙 드는 전담 쇼핑!"
        tags={[
          "전자담배",
          "전자담배 액상",
          "전자담배 추천",
          "일회용 전자담배",
          " 전자담배 기기",
          " 궐련형 전자담배",
          "젤로 전자담배",
          "발라리안",
          " 편의점 전자담배",
          " 전자담배 기기 추천",
        ]}
      />
      <RollingText />
      {/* <CountdownTimer /> */}
      <div className="z-10 mt-[12px] mb-[20px] text-14px text-left font-medium text-[#BDBDBD] bg-white relative" onClick={() => setToggleOrder((cur) => !cur)}>
        <div className="mx-[16px] flex">
          <p>기기</p>
          <p className="mx-[6px] flex flex-col justify-center">
            <img src="/imageDB/shop_common/Chevron_Right_5_8_BDBDBD.svg" alt="Right" />
          </p>
          <p className="text-black">{order_text}&nbsp;</p>
          <p className="mx-[4px] flex flex-col justify-center">
            <img src="/imageDB/shop_common/Chevron_Down_8_5_black_2.svg" alt="Down" />
          </p>
        </div>
        <OrderModal
          toggle_order={toggle_order}
          order_options={order_options}
          order_text={order_text}
          setToggleOrder={setToggleOrder}
          setOrderOption={setOrderOption}
        />
      </div>
      <hr className="border-[#F2F2F2]" />
      <div className="flex rounded-[17.5px] mt-[20px] mx-[16px]">
        {
          // 상단 분류 메뉴
          filterOptionsMachine.map((elem, idx) => (
            <div
              className="w-[76px] h-[32px] mr-[8px] text-14px rounded-[17.5px] bg-[#F4F4F4] px-[8px] py-[4px] flex justify-around"
              onClick={() => setFilterType(idx + 1)}
              key={"fliter_mach_" + idx}
            >
              <p className="self-center">{elem}</p>
              <p className="flex flex-col justify-center">
                <img src="/imageDB/shop_common/Chevron_Down_8_5_4F4F4F_1.5.svg" alt="Down" />
              </p>
            </div>
          ))
        }
      </div>
      <div className="mt-[30px] mx-[12px] text-left text-14px grid grid-cols-2 gap-x-[12px]">
        {items.map((item, i) => (
          <ItemCard item={item} type="machine" key={`item_mach_${i}`} />
        ))}
      </div>
      {items_count ? <PaginationAndo page={page} last={items_count} /> : null}
      {/* 하단 분류 모달 */}
      <FilterMachine typeFilter={typeFilter} setFilterType={setFilterType} filterOptionsMachine={filterOptionsMachine} setQuery={setQuery} />
    </div>
  );
}
