export default function OrderModal({
  toggle_order,
  order_options,
  order_text,
  setToggleOrder,
  setOrderOption,
}) {
  return toggle_order ? (
    <>
      <p
        // 음영
        className="absolute top-[84px] w-full min-w-[360px] h-screen bg-[rgba(28,28,28)]/80"
        onClick={(e) => {
          e.stopPropagation();
          setToggleOrder(false);
        }}
      />

      <div
        className="absolute top-[20px] z-10 w-full min-w-[360px] bg-white"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-full min-w-[360px] py-[12px]">
          {order_options.map((opt, itr) => (
            <div
              className="py-[12px]"
              key={"sort_" + itr}
              onClick={() =>
                order_text === opt ? setToggleOrder(false) : setOrderOption(opt)
              }
            >
              <p
                className={`px-[16px]${
                  order_text === opt ? " text-black" : ""
                }`}
              >
                {opt}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  ) : null;
}
