import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCustom } from "../../redux/module/custom_str";
import axios from "axios";

export default function MyInvitations() {
    const dispatch = useDispatch();
    const user_info_store = useSelector((state) => state.user_info.data);
    const [user_info, setLocalUserInfo] = useState({});

    const input_nick_ref = useRef();
    const [referrer, setReferrer] = useState(null);

    useEffect(() => {
        if (user_info_store.user_id) setLocalUserInfo(user_info_store);
    }, [user_info_store]);

    useEffect(() => {
        dispatch(setCustom("추천인 입력"));
        axios
            .get(`${process.env.REACT_APP_FRONT_URL}/api/user/refer`)
            .then((res) => setReferrer(res.data))
            .catch((err) => console.log(err));
    }, [dispatch]);

    const Refer = (nickname) => {
        axios
            .post(`${process.env.REACT_APP_FRONT_URL}/api/user/refer`, {
                user_nick: nickname,
                uid: user_info.user_id,
            })
            .then((res) => {
                switch (res.data) {
                    case "ok":
                        setReferrer(nickname);
                        alert(`${nickname}님을 추천인으로 등록하였습니다. 3,000 포인트 적립! 축하드립니다!^0^`);
                        break;
                    case "dup":
                        alert("회원님은 이미 추천인을 입력하셨습니다.");
                        break;
                    case "limit":
                        alert(`${nickname}님은 이미 3명에게\n추천인으로 입력되었습니다.`);
                        break;
                    case "invalid":
                        alert("회원 정보가 존재하지 않습니다.\n추천인 닉네임을 다시 한 번 확인해 주세요.");
                        break;
                    case "self":
                        alert("자신을 추천인으로 등록할 수 없습니다.");
                        break;
                    case "not adult":
                        alert("본인인증을 한 성인만 등록할 수 있습니다.");
                        break;
                    default:
                        alert("예기치 못한 에러입니다.");
                }
            })
            .catch((err) => console.log(err));
    };

    const Clipboard = () => {
        navigator.clipboard.writeText(user_info.user_nickname);
        alert("회원님의 닉네임이\n클립보드에 복사되었습니다!");
    };

    return (
        <div className="mt-[24px] mx-[20px] text-left">
            <p className="text-[18px]">회원님의 닉네임</p>
            <p className="m-1 text-[24px] text-[#00B2FF]" onClick={Clipboard}>
                {user_info.user_nickname}
            </p>
            <div className="mt-[5px] text-[15px] mr-[39px] text-[#969696]">친구가 회원님의 닉네임을 추천인에 입력할 경우 3000P가 지급됩니다.</div>
            <div className="mt-[68px] text-[18px]">추천인 닉네임</div>
            <div className="mt-1 text-[16px] text-[#969696]">최대 3명으로 부터 추천 받을 수 있습니다. 안도를 주변에 알려보세요!</div>
            {referrer === "" ? (
                <form
                    className="mt-4 bg-[#F3F3F3] h-[57px] flex rounded-[13px] justify-between"
                    onSubmit={(e) => {
                        e.preventDefault();
                        Refer(input_nick_ref.current.value);
                    }}
                >
                    <input placeholder="추천인의 닉네임을 입력해주세요." className="bg-[#F3F3F3] ml-[16px] my-[16px] w-[215px]" ref={input_nick_ref} />

                    <button className="bg-[#00D36D] rounded-[10px] text-white text-[18px] m-[7px] px-[18px]">확인</button>
                </form>
            ) : (
                <div className="mt-4 bg-[#F3F3F3] h-[57px] flex rounded-[13px] justify-between">
                    <p className="bg-[#F3F3F3] ml-[16px] my-[16px] w-[215px] font-medium">{referrer}</p>
                    <button className="bg-[#939393] rounded-[10px] font-medium text-white text-[18px] m-[7px] px-[18px] cursor-default">완료</button>
                </div>
            )}
        </div>
    );
}
