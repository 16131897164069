import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCustom } from "../../redux/module/custom_str";
import { fetchMachineSaves } from "../../redux/module/item_machine";

export default function MyDev() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const machine_infos = useSelector(
    (state) => state.item_machine.save_machine_infos
  );
  useEffect(() => {
    dispatch(setCustom("기기 관리"));
  }, [dispatch]);

  useEffect(() => {
    if (!(machine_infos && machine_infos.length > 0))
      dispatch(fetchMachineSaves());
  }, [dispatch, machine_infos]);

  return (
    <>
      <hr className="border-t-[3px] border-[#F6F6F6] mt-[20px]" />

      <div className="mt-[12px] text-left mx-[16px]">
        <p onClick={() => nav("add")} className="py-[8px] flex">
          <img
            src="/imageDB/my_page/my_dev/Text,Item,List,Add,Plus1.png"
            width={24}
            className="mr-[8px]"
            alt="새 기기 추가"
          />
          새로운 기기 추가하기
        </p>
        <hr className="my-[16px] mx-[1px] border-t-[1px] font-bold text-[#EEEEEE]" />
        <p onClick={() => nav("list")} className="py-[8px] flex">
          <img
            src="/imageDB/my_page/my_dev/List,Menu,Item1.png"
            width={24}
            className="mr-[8px]"
            alt="내 기기 목록"
          />
          내 기기 목록
        </p>
        <hr className="my-[16px] mx-[1px] border-t-[1px] font-bold text-[#EEEEEE]" />
      </div>
    </>
  );
}
