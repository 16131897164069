import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCustom } from "../../redux/module/custom_str";
import { useLocation, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import axios from "axios";
import andoAlert from "../common/andoAlert";
import andoConfirm from "../common/andoConfirm";

export default function MyHistory() {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const user_info = useSelector((state) => state.user_info.data);

  const [showAlert, setAlert] = useState(false);
  const [showConfirm, setConfirm] = useState(false);

  useEffect(() => {
    dispatch(setCustom("주문 상세내역"));
  }, [dispatch]);

  const state = useLocation().state;
  const { order_info, order_items, invoice, invoice_track } = state;
  const [trackStr, setTrackStr] = useState(invoice_track);

  useEffect(() => {
    setTrackStr(invoice_track);
  }, [invoice_track]);

  if (!state) return <p className="mt-[40px]">잘못된 접근입니다.</p>;

  const requestCancle = () => {
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/purchase/cancel`, {
      order_id: order_info.order_id,
    });
    setAlert(true);
    setTrackStr("주문 취소");
  };

  // const requestRefund = () => {
  //   // TODO: 팝업 모달 컴포넌트 만들 것.
  //   //닫히면 이 함수를 호출?;

  //   axios.post(`${process.env.REACT_APP_SERVER_URL}/api/purchase/refund`, {
  //     order_id: order_info.order_id,
  //     memo: null, //
  //   });
  // };

  // case "refund":
  //   return Promise.resolve("취소/반품 요청");
  // case "recalling":
  //   return Promise.resolve("수거중");
  // case "review_refund":
  //   return Promise.resolve("반품 검토중");
  // case "review_change":
  //   return Promise.resolve("교환 검토중");

  let check_string = ["배송완료", "취소/반품 요청"]; // 나중에 뭐가 추가될 수 있음.

  return !order_items.length ? null : (
    <div className="mt-[27px] text-left pb-8">
      <div className="mx-[20px] Rubik">
        <p>
          {order_info.order_time.slice(0, 4)}.{order_info.order_time.slice(5, 7)}.{order_info.order_time.slice(8, 10)}
        </p>
      </div>
      <hr className="mt-[20px] border-[0.25px] border-black mx-[20px]" />
      <Swiper id="basket" width={102} slidesOffsetBefore={16} slidesOffsetAfter={-16} spaceBetween={10} className="mt-[8px]" modules={[Pagination]}>
        {order_items.map((order_item, i) =>
          order_item.order_id === order_info.order_id ? (
            <SwiperSlide
              key={i}
              className="basket-parts flex-col"
              onClick={() => {
                let type;
                switch (order_item.item_id[3]) {
                  case "L":
                    type = "liquid";
                    break;
                  case "M":
                    type = "machine";
                    break;
                  case "C":
                    type = "coil";
                    break;
                  case "P":
                    type = "pod";
                    break;
                  default:
                }
                nav(`/detail/${type}/${parseInt(order_item.item_id.slice(4))}`);
              }}
            >
              <div className="absolute top-[12px] w-full">
                <p className="h-[65px] flex">
                  <img
                    src={process.env.REACT_APP_IMAGE_BASE + order_item.item_info.item_img + `?timestamp=${new Date().getTime()}&w=256&q=75&f=webp`}
                    width={65}
                    className="mx-auto self-center"
                    alt={`상품 ${i}`}
                  />
                </p>
                <div className="text-12px text-left mx-[10px] font-medium">
                  <p className="mt-[9.7px]">{order_item.item_info.item_company}</p>
                  <p>{order_item.item_info.item_name}</p>
                  <p className="font-normal text-[#828282]">{order_item.item_info.item_desc}</p>
                  {order_item.item_option ? <p className="text-[#828282] font-normal">{order_item.item_option}</p> : null}
                </div>
              </div>
              <div className="absolute bottom-[4px] w-full">
                <p className="mt-[4px] mr-[10px] text-12px text-right font-semibold Roboto">1개 / ₩{order_item.item_info.item_price.toLocaleString("ko-KR")}</p>
                <hr className="mt-[8px] w-full border-[#D4D4D4]" />
                <p className="mt-[8px] text-14px text-[#3C2E06] font-medium Roboto">수량 {order_item.quantity}개</p>
              </div>
            </SwiperSlide>
          ) : null,
        )}
      </Swiper>
      <div className="mx-[20px]">
        <div className="mt-[16px]">
          <hr className="border-[#D2D2D2]" />
          <p className="mt-[16px] font-medium">결제 금액</p>
          <p className="mt-[5px]">{order_info.actual_price.toLocaleString("ko-KR")}원</p>
        </div>

        <div className="mt-[16px]">
          <hr className="border-[#D2D2D2]" />
          <p className="mt-[16px] font-medium">{trackStr}</p>
          {invoice && (
            <p className="mt-[5px]">
              <span className="mr-[7px]">로젠택배</span>{" "}
              <a href={`https://www.ilogen.com/web/personal/trace/${invoice}`} rel="noreferrer" target="_blank">
                <span className="underline text-[#4176FF]">{invoice}</span>
              </a>
            </p>
          )}
        </div>

        <div className="mt-[16px]">
          <hr className="border-[#D2D2D2]" />
          <p className="mt-[16px] font-medium">배송지 정보</p>
          <div className="text-14px">
            <div className="flex mt-[10px]">
              <p className="w-[80px] mr-[10px] text-[#858585]">받는 사람</p>
              <p>{user_info.user_nickname}</p>
            </div>
            <div className="flex mt-[12px]">
              <p className="w-[80px] mr-[10px] text-[#858585]">연락처</p>
              <p>
                {
                  // order_tel
                  order_info.order_tel.length === 11
                    ? `${order_info.order_tel.slice(0, 3)}-${order_info.order_tel.slice(3, 7)}-${order_info.order_tel.slice(7, 11)}`
                    : `${order_info.order_tel.slice(0, 2)}-${order_info.order_tel.slice(2, 6)}-${order_info.order_tel.slice(6, 10)}`
                }
              </p>
            </div>
            <div className="flex mt-[12px]">
              <p className="w-[80px] shrink-0 mr-[10px] text-[#858585]">주소</p>
              <p>
                {
                  // order_addr
                  order_info.order_addr
                }
              </p>
            </div>
            <div className="flex mt-[12px]">
              <p className="w-[80px] shrink-0 mr-[10px] text-[#858585]">배송요청사항</p>
              <p>
                {
                  // order_msg
                  order_info.order_msg
                }
              </p>
            </div>
          </div>
        </div>
      </div>
      {showConfirm &&
        andoConfirm({
          title: "주문을 취소하시겠습니까?",
          show: setConfirm,
          callback: requestCancle,
        })}
      {showAlert &&
        andoAlert({
          title: "주문 취소가 완료되었습니다.",
          desc: "환불은 영업일 기준 7일정도 소요될 수 있습니다.",
          show: setAlert,
        })}
      {trackStr === "주문 접수" && (
        <div className="flex justify-end  mx-[17px] p-[13px]">
          {user_info.user_nickname !== "" && (
            <p
              className="text-right w-fit text-base text-[#FF0000]"
              onClick={() => {
                setConfirm(true);
              }}
            >
              주문 취소
            </p>
          )}
        </div>
      )}
      {check_string.indexOf(trackStr) !== -1 && (
        <div className="mx-auto w-[328px] ">
          <a href="http://pf.kakao.com/_qJbjG/chat">
            <button
              className={`h-[54px] mx-auto w-[328px] ${trackStr === "배송완료" ? " bg-[#0075FF]" : "bg-[#ff0000]"} rounded-[40px] text-center text-white mt-6`}
              onClick={() => {
                //requestRefund();
                //카카오 채널 연결
              }}
            >
              반품, 교환 신청 {trackStr !== "배송완료" && "취소"}
            </button>
          </a>
        </div>
      )}
    </div>
  );
}
