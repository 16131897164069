import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSearchText } from "../../redux/module/custom_str";

export default function HeadShop() {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const href = window.location.href;
  const [searchbar, setSearch] = useState(false);

  let placeholder = useSelector((state) => state.custom_str.search_placeholder);

  let value = useSelector((state) => state.custom_str.search_text ?? "");

  return (
    <header className="flex items-center mb-[12px]" onClick={(e) => e.stopPropagation()}>
      {!searchbar ? (
        <>
          <p
            className="w-4/12 pl-[28px] pr-[4px]"
            onClick={() => {
              nav("/shop");
              dispatch(setSearchText(""));
            }}
          >
            {!href.includes("/shop/1") && <img src="/imageDB/common/chevronLeft.svg" alt="이전" />}
          </p>
          <p className="w-4/12" onClick={() => nav("/")}>
            <img src="/img/Ando_Logo.svg" className="mx-auto" alt="ANDO-Logo" />
          </p>

          <p className="w-4/12">
            <img onClick={() => setSearch(true)} src="/imageDB/header/icon_search.svg" className="ml-auto px-[16px]" alt="검색" />
          </p>
        </>
      ) : (
        <>
          <p className="w-1/6 pl-[28px] py-[8px]" onClick={() => setSearch(false)}>
            <img src="/imageDB/common/chevronLeft.svg" alt="이전" />
          </p>

          <form
            className="w-4/5 my-[4px] flex relative bg-[#F2F2F2] rounded-full"
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(setSearchText(e.target[0].value));
              setSearch(false);
            }}
          >
            <input
              type="text"
              placeholder={placeholder}
              value={value}
              onChange={(e) => dispatch(setSearchText(e.target.value))}
              className="ml-auto mr-[40px] w-full h-[40px] rounded-l-full text-14px pl-[17px] bg-transparent"
            />

            <button type="submit" className="px-[12px] self-center absolute right-[0px]">
              <img src="/imageDB/header/icon_search.svg" alt="search" />
            </button>
          </form>
        </>
      )}
    </header>
  );
}
