import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { useEffect, useState } from "react";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCustom } from "../redux/module/custom_str";
import { fetchCart, rewriteCartItem, setWriteIdle } from "../redux/module/item_cart";

export default function History() {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const user_info = useSelector((state) => state.user_info.data);

  useEffect(() => {
    dispatch(setCustom("지난 구매 내역"));
  }, [dispatch]);

  const [order_ids, setOrderID] = useState([]);
  const [order_items, setOrderItem] = useState([]);

  const [write_request, setWriteRequest] = useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_FRONT_URL}/api/user/history`).then((res) => {
      if (res.data && res.data.length) {
        setOrderID(res.data[0]);
        setOrderItem(res.data[1]);
      }
    });
  }, []);

  useEffect(() => {
    if (write_request) {
      dispatch(fetchCart(user_info.user_id));
      setWriteRequest(false);
    }
  }, [dispatch, user_info.user_id, write_request]);

  return order_ids.map((order, itr) => (
    <div className="mt-[27px]" key={`shipping_${itr}`}>
      <p className="mx-[20px] text-left">
        {/* {order[1].slice(0, 4)}년  */}
        {parseInt(order[1].slice(5, 7))}월 {parseInt(order[1].slice(8, 10))}일 주문내역
      </p>
      <Swiper id="basket" width={102} slidesOffsetBefore={16} slidesOffsetAfter={-16} spaceBetween={10} className="mt-[8px]" modules={[Pagination]}>
        {order_items.map((order_item, i) =>
          order_item.order_id === order[0] ? (
            <SwiperSlide
              key={i}
              className="basket-parts flex-col"
              onClick={() => {
                let type;
                switch (order_item.item_id[3]) {
                  case "L":
                    type = "liquid";
                    break;
                  case "M":
                    type = "machine";
                    break;
                  case "C":
                    type = "coil";
                    break;
                  case "P":
                    type = "pod";
                    break;
                  default:
                }
                nav(`/detail/${type}/${parseInt(order_item.item_id.slice(4))}`);
              }}
            >
              <div className="absolute top-[12px] w-full">
                <p className="h-[65px] flex">
                  <img
                    src={process.env.REACT_APP_IMAGE_BASE + order_item.item_info.item_img + `?timestamp=${new Date().getTime()}&w=256&q=75&f=webp`}
                    width={65}
                    className="mx-auto self-center"
                    alt={`상품 ${i}`}
                  />
                </p>
                <div className="text-12px text-left mx-[10px] font-medium">
                  <p className="mt-[9.7px]">{order_item.item_info.item_company}</p>
                  <p>{order_item.item_info.item_name}</p>
                  <p className="font-normal text-[#828282]">{order_item.item_info.item_desc}</p>
                  {order_item.item_option ? <p className="text-[#828282] font-normal">{order_item.item_option}</p> : null}
                </div>
              </div>
              <div className="absolute bottom-[4px] w-full">
                <p className="mt-[4px] mr-[10px] text-12px text-right font-semibold Roboto">1개 / ₩{order_item.item_info.item_price.toLocaleString("ko-KR")}</p>
                <hr className="mt-[8px] w-full border-[#D4D4D4]" />
                <p className="mt-[8px] text-14px text-[#3C2E06] font-medium Roboto">수량 {order_item.quantity}개</p>
              </div>
            </SwiperSlide>
          ) : null,
        )}
      </Swiper>

      <p className="mx-[20px] mt-[14px]">
        <button
          className="bg-black text-white w-full h-[56px] rounded-[40px]"
          onClick={() => {
            dispatch(
              rewriteCartItem({
                user_id: user_info.user_id,
                item_id: order_items.filter((item) => item.order_id === order[0]).map((item) => item.item_id),
                quantity: order_items.filter((item) => item.order_id === order[0]).map((item) => item.quantity),
                // idx: i, // 리덕스 상태를 제어하기 위한 매개변수. BE에선 활용 X
              }),
            );
            setWriteRequest(true);
            dispatch(setWriteIdle(false));
            alert("덮어쓰기 완료!");
          }}
        >
          장바구니에 덮어쓰기
        </button>
      </p>
      {itr < order_ids.length - 1 ? <hr className="mt-[40px] border-[#DADADA]" /> : null}
    </div>
  ));
}
