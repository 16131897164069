import { useRef, useState } from "react";
import Axios from "axios";
import ImpCert from "../../components/IMP/impCert";
import ImpPayment from "../../components/IMP/impPayment";
import { useDispatch } from "react-redux";
import { initUser } from "../../redux/module/user_info";
import { useNavigate } from "react-router-dom";
import andoAlert from "../../components/common/andoAlert";
import andoConfirm from "../../components/common/andoConfirm";

const Test = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();

  const [showAlert, setAlert] = useState(false);
  const [showConfirm, setConfirm] = useState(false);

  const hello = useRef(1);
  const tf = useRef(false);
  const [rendre, setRend] = useState(0);

  const [data, setData] = useState([]);
  const link = () => {
    let testInput = [90006357372];
    let input_str = "";
    for (const itr in testInput)
      if (parseInt(itr)) input_str += `,${testInput[itr]}`;
      else input_str += testInput[itr];
    Axios.get(
      `${process.env.REACT_APP_FRONT_URL}/api/invoice_tracker?invoices=${input_str}`
    ).then((res) => setData(res.data));
  };

  const logout = () => {
    Axios.get(`${process.env.REACT_APP_FRONT_URL}/api/logout`).then((res) => {
      //fds
      dispatch(initUser());
      nav("/");
    });
  };

  const testsession = () => {
    Axios.get(`${process.env.REACT_APP_FRONT_URL}/api/sessiontest`);
  };

  const testUpdate = () => {
    Axios.get(`${process.env.REACT_APP_FRONT_URL}/api/updateTest`).then(
      (res) => {
        console.log("읎뎃");
      }
    );
  };

  const testAndoAlert = () => {
    setAlert(true);
  };

  const testAndoConfirm = () => {
    setConfirm(true);
  };

  return (
    <>
      {showConfirm &&
        andoConfirm({
          title: "주문을 취소하시겠습니까?",
          show: setConfirm,
          callback: testAndoAlert,
        })}
      {showAlert &&
        andoAlert({
          title: "주문 취소가 완료되었습니다.",
          desc: "환불은 영업일 기준 7일정도 소요될 수 있습니다.",
          show: setAlert,
        })}
      <div
        className={`py-[20px] bg-rose-400 ${tf.current ? "text-teal-400" : ""}`}
        onClick={() => {
          tf.current = !tf.current;
          console.log(tf.current);
        }}
      >
        {hello.current}
      </div>
      <div
        className="py-[20px] bg-teal-400"
        onClick={() => {
          setRend((cur) => cur + 1);
          console.log(tf.current);
        }}
      >
        {rendre}
      </div>
      <div onClick={() => link()}>
        <p>트리거 버튼</p>
        {data.length
          ? data.map((elem, itr) => (
              <p className="bg-rose-400 my-[16px]" key={`data_${itr}`}>
                {elem ? elem : "없어용"}
              </p>
            ))
          : null}
      </div>
      {/* <div>컨텐츠 데이터 = {data}</div> */}

      <ImpCert />
      <br></br>
      <ImpPayment />
      <br />
      <button onClick={() => testsession()}>Test Session</button>
      <br></br>
      <a href={process.env.REACT_APP_KAKAO_LOGIN_AUTH_URL}>
        카카오로 바로 시작
      </a>
      <p onClick={() => logout()}>로그아웃</p>
      <div onClick={() => testUpdate()}>업데이트 테스트</div>
      <div onClick={() => testAndoAlert()}>안도얼럿 테스트</div>
      <div onClick={() => testAndoConfirm()}>안도컨펌 테스트</div>
    </>
  );
};

export default Test;
