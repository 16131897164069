import { createSlice } from "@reduxjs/toolkit";

const custom_str = createSlice({
  name: "custom_str",
  initialState: {
    head_text: "",
    search_text: "",
    search_placeholder: "",
  },
  reducers: {
    setCustom: (state, action) => ({ ...state, head_text: action.payload }),
    setSearchText: (state, action) => ({
      ...state,
      search_text: action.payload,
    }),
    setSearchPlaceholder: (state, action) => ({
      ...state,
      search_placeholder: action.payload,
    }),
  },
});

export const { setCustom, setSearchText, setSearchPlaceholder } =
  custom_str.actions;
export { custom_str };
