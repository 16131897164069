import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";

const fetchFavLiqs = createAsyncThunk("item_fav/fetchFavLiqs", () =>
  Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/user/favLiqs`)
    .then((res) => res.data)
    .catch((err) => console.log("DB 통신 오류FL"))
);

const fetchFavs = createAsyncThunk("item_fav/fetchFavs", () =>
  Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/user/favs`)
    .then((res) => res.data)
    .catch((err) => console.log("DB 통신 오류F"))
);

const initF = { favs: [], favLiqs: [], favLiq_loading: true };

const item_fav = createSlice({
  name: "item_fav",
  initialState: {
    ...initF,
  },
  reducers: {
    initFav: (state) => (state = { ...initF }),
    setFavs: (state, action) => ({ ...state, favs: action.payload }),
    setFavLiqs: (state, action) => ({ ...state, favLiqs: action.payload }),
    setFLloading: (state, action) => ({
      ...state,
      favLiq_loading: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFavLiqs.fulfilled, (state, action) => {
        if (action.payload)
          if (action.payload.length) state.favLiqs = action.payload;
        state.favLiq_loading = false;
      })
      .addCase(fetchFavs.fulfilled, (state, action) => {
        if (action.payload)
          if (action.payload.length) state.favs = action.payload;
      });
  },
});

export { fetchFavLiqs, fetchFavs, item_fav };
export const { initFav, setFavs, setFavLiqs, setFLloading } = item_fav.actions;
