import React, { useEffect, useState } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useSelector } from "react-redux";

const DaumPostcode = ({ setSearch, setInput }) => {
  const user_info = useSelector((state) => state.user_info.data);

  // user_addr = api 불러온 주소 + 상세주소
  // searchAddr = user_info.user_addr
  const [searchAddr, setSearchAddr] = useState("");

  useEffect(() => {
    if (user_info.user_addr1)
      setSearchAddr(user_info.user_addr1, { replace: true });
  }, [user_info.user_addr1]);

  const open = useDaumPostcodePopup();

  const handleComplete = (data) => {
    // e.g. (04779) 서울 성동구 왕십리로2길 20 (성수동1가)
    let fullAddress = `(${data.zonecode}) ${data.address}`;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    if (fullAddress !== searchAddr) {
      setSearchAddr(fullAddress);
      setSearch(fullAddress);
    }
  };

  const handleClick = () => open({ onComplete: handleComplete });

  return (
    <div className="mt-[8px] flex">
      <p>
        <input
          className="pl-[4px] border-2 w-11/12"
          readOnly={true}
          value={searchAddr ? searchAddr : "주소를 검색해주세요"}
          onClick={() => handleClick()}
        />
        <input
          placeholder={
            !searchAddr ? "주소를 검색해주세요" : "상세 주소를 입력해주세요"
          }
          disabled={!searchAddr}
          defaultValue={user_info.user_addr2}
          className="mt-[8px] pl-[4px] border-2 w-11/12"
          onChange={(e) => setInput(e.target.value)}
        />
      </p>
      <button
        className="p-[8px] h-fit self-center bg-[#F6F6F6] rounded-md"
        type="button"
        onClick={handleClick}
      >
        주소&nbsp;찾기
      </button>
    </div>
  );
};

export default DaumPostcode;
