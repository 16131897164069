import Range from "rc-slider";
import { useEffect, useRef, useState } from "react";
import "../../../css/rc-slider.css";
import { useDispatch, useSelector } from "react-redux";
import { setSearchText } from "../../../redux/module/custom_str";

export default function FilterLiquid({ typeFilter, setFilterType, filterOptionsLiquid, setQuery }) {
  const dispatch = useDispatch();

  const list_brand = useSelector((state) => state.item_liquid.brands);
  const list_taste = ["단맛", "쿨링", "타격감"];
  const intensity_taste = ["없음", "약함", "중간", "강함"];
  const list_category = useSelector((state) => state.item_liquid.category);

  const ref_brand = useRef([]);
  const tmp_brand = useRef([]);
  useEffect(() => {
    if (list_brand.length && !ref_brand.current.length) {
      ref_brand.current = new Array(list_brand.length).fill(false);
      tmp_brand.current = [...ref_brand.current];
    }
  }, [list_brand.length]);

  const ref_min_taste = useRef(new Array(list_taste.length).fill(0));
  const ref_max_taste = useRef(new Array(list_taste.length).fill(intensity_taste.length - 1));

  const [category_loaded, setCategoryLoad] = useState(false);
  const ref_category1 = useRef([]);
  const ref_category2 = useRef([]);
  const tmp_category1 = useRef([]);
  const tmp_category2 = useRef([]);
  useEffect(() => {
    if (list_category.length && !ref_category1.current.length) {
      ref_category1.current = new Array(list_category.length).fill([]);
      for (let i = 0; i < list_category.length; i++) ref_category2.current.push(new Array(list_category[i][1].length).fill(false));

      tmp_category1.current = ref_category1.current.map((v) => [...v]);
      tmp_category2.current = ref_category2.current.map((v) => [...v]);
      setCategoryLoad(true);
    }
  }, [list_category]);

  const tmp_min_taste = useRef([...ref_min_taste.current]);
  const tmp_max_taste = useRef([...ref_max_taste.current]);

  const handleFilter = (type) => {
    tmp_brand.current = [...ref_brand.current];
    tmp_category1.current = ref_category1.current.map((v) => [...v]);
    tmp_category2.current = ref_category2.current.map((v) => [...v]);
    tmp_min_taste.current = [...ref_min_taste.current];
    tmp_max_taste.current = [...ref_max_taste.current];
    setFilterType(type);
  };

  const MakeQuery = () => {
    let query_brand = "";
    for (let i = 0; i < ref_brand.current.length; i++) {
      if (ref_brand.current[i]) {
        if (query_brand) query_brand += `,${list_brand[i]}`;
        else query_brand += `item_company=${list_brand[i]}`;
      }
    }

    let query_min_taste = "";
    for (let i = 0; i < ref_min_taste.current.length; i++) {
      if (!(ref_min_taste.current[i] === 0 && ref_max_taste.current[i] === intensity_taste.length - 1)) {
        if (query_min_taste) query_min_taste += `&liquid_min_taste_${i}=${ref_min_taste.current[i]}`;
        else query_min_taste += `liquid_min_taste_${i}=${ref_min_taste.current[i]}`;
      }
    }

    let query_max_taste = "";
    for (let i = 0; i < ref_max_taste.current.length; i++) {
      if (!(ref_min_taste.current[i] === 0 && ref_max_taste.current[i] === intensity_taste.length - 1)) {
        if (query_max_taste) query_max_taste += `&liquid_max_taste_${i}=${ref_max_taste.current[i]}`;
        else query_max_taste += `liquid_max_taste_${i}=${ref_max_taste.current[i]}`;
      }
    }
    let query_category2 = "";
    {
      let itr = 0;
      for (; itr < ref_category1.current.length; itr++)
        if (ref_category1.current[itr].length) {
          query_category2 = `liquid_category2=${ref_category1.current[itr]}`;
          break;
        }
      for (let i = itr + 1; i < ref_category1.current.length; i++) {
        if (ref_category1.current[i].length) {
          if (query_category2) query_category2 += `,${ref_category1.current[i]}`;
          else query_category2 = `liquid_category2=${ref_category1.current[i]}`;
        }
      }
    }

    let query_final = "";
    query_final += query_brand ? query_brand : "";
    query_final += query_min_taste ? (query_final.length > 1 ? `&${query_min_taste}` : query_min_taste) : "";
    query_final += query_max_taste ? (query_final.length > 1 ? `&${query_max_taste}` : query_max_taste) : "";
    query_final += query_category2 ? (query_final.length > 1 ? `&${query_category2}` : query_category2) : "";
    query_final += query_final.length > 1 ? "&" : "";

    // 쿼리 스트링에 특수문자가 들어가면 서버에서 문자열이 오염돼서
    // 퍼센트 인코딩 과정을 추가로 거쳐야 함.
    query_final = encodeURI(query_final);
    setQuery(query_final);
  };

  const ModalBrand = () => {
    const [select_brand, setBrand] = useState(tmp_brand.current);

    return (
      <div className="overflow-y-scroll hide-scrollbar h-[345px]">
        {list_brand.map((elem, itr) => (
          <div key={"liquid_brand_" + itr}>
            <div
              className="flex justify-between px-[16px] pt-[7px] pb-[8px]"
              onClick={() => {
                tmp_brand.current[itr] = !tmp_brand.current[itr];
                setBrand([...tmp_brand.current]);
              }}
            >
              <p className={`${select_brand[itr] ? "text-black" : ""}`}>{elem}</p>
              <p>
                {select_brand[itr] ? (
                  <img src="/imageDB/common/checked.svg" width={16} alt="checked" />
                ) : (
                  <img src="/imageDB/common/unchecked.svg" width={16} alt="unchecked" />
                )}
              </p>
            </div>
            {itr !== list_brand.length - 1 ? <hr className="mt-[8px] mb-[7px] border-[#F4F4F4]" /> : null}
          </div>
        ))}
      </div>
    );
  };

  const ModalTaste = () => (
    <div className="mx-[16px]">
      {list_taste.map((elem, itr) => (
        <div className="w-full h-[89px] bg-[#F5F5F5] rounded-[12px] mb-[10px]" key={"liquid_taste_" + itr}>
          <p className="pt-[10px] pb-[8px] text-black font-bold">{elem}</p>
          <Range
            range
            allowCross={false}
            min={0}
            max={intensity_taste.length - 1}
            defaultValue={[tmp_min_taste.current[itr], tmp_max_taste.current[itr]]}
            marks={intensity_taste}
            onChange={(val) => {
              tmp_min_taste.current[itr] = val[0];
              tmp_max_taste.current[itr] = val[1];
            }}
          />
        </div>
      ))}
    </div>
  );

  const ModalCategory = () => {
    const [select_category1, setCategory1] = useState(tmp_category1.current);
    const [select_category2, setCategory2] = useState(tmp_category2.current);
    // mode1_2 매개변수 설명
    // 0번째: 1차 계열의 모달인지, 아니면 2차 계열의 모달인지
    // 1번째: 몇 번째의 2차 계열인지 (0번째 값이 1일 땐 뭐가 돼도 무관)
    const [mode1_2, setMode] = useState([1, 0]);

    const handleCat1Check = (itr) => {
      if (select_category1[itr].length) {
        tmp_category1.current[itr] = [];
        tmp_category2.current[itr] = new Array(tmp_category2.current[itr].length).fill(false);
        setCategory1([...tmp_category1.current]);
        setCategory2([...tmp_category2.current]);
      } else {
        tmp_category1.current[itr] = [...list_category[itr][1]];
        tmp_category2.current[itr] = new Array(tmp_category2.current[itr].length).fill(true);
        setCategory1([...tmp_category1.current]);
        setCategory2([...tmp_category2.current]);
      }
    };

    const handleCat2Check = (idx, itr) => {
      tmp_category2.current[idx][itr] = !tmp_category2.current[idx][itr];
      const arr = [];
      for (let i = 0; i < tmp_category2.current[idx].length; i++) {
        if (tmp_category2.current[idx][i]) arr.push(list_category[idx][1][i]);
      }

      tmp_category1.current[idx] = [...arr];
      setCategory1([...tmp_category1.current]);
      setCategory2([...tmp_category2.current]);
    };

    return category_loaded ? (
      mode1_2[0] === 1 ? (
        <div className="overflow-y-scroll hide-scrollbar h-[345px]">
          {list_category.map((elem, itr) => (
            <div key={"liquid_category1_" + itr}>
              <div className="flex justify-between px-[16px] pt-[7px] pb-[8px]" onClick={() => setMode([2, itr])}>
                <p className={`flex${select_category1[itr].length ? " text-black" : ""}`}>{elem[0]}</p>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCat1Check(itr);
                  }}
                >
                  {select_category1[itr].length ? (
                    <img src="/imageDB/common/checked.svg" width={16} alt="checked" />
                  ) : (
                    <img src="/imageDB/common/unchecked.svg" width={16} alt="unchecked" />
                  )}
                </button>
              </div>
              {itr !== list_category.length - 1 ? <hr className="mt-[8px] mb-[7px] border-[#F4F4F4]" /> : null}
            </div>
          ))}
        </div>
      ) : (
        <div className="overflow-y-scroll hide-scrollbar h-[345px]">
          <p className="flex px-[16px] pt-[7px] pb-[8px] text-black" onClick={() => setMode([1, 0])}>
            <img src="/imageDB/common/chevronLeft.svg" className="mr-[16px]" alt="이전" />
            <span>{list_category[mode1_2[1]][0]}</span>
          </p>
          <hr className="mt-[8px] mb-[7px] border-[#F4F4F4]" />
          {list_category[mode1_2[1]][1].map((elem, itr) => (
            <div key={"liquid_category2_" + itr}>
              <div className="flex justify-between px-[16px] pt-[7px] pb-[8px]" onClick={() => handleCat2Check(mode1_2[1], itr)}>
                <p className={`flex${select_category2[mode1_2[1]][itr] ? " text-black" : ""}`}>{elem}</p>
                <button>
                  {select_category2[mode1_2[1]][itr] ? (
                    <img src="/imageDB/common/checked.svg" width={16} alt="checked" />
                  ) : (
                    <img src="/imageDB/common/unchecked.svg" width={16} alt="unchecked" />
                  )}
                </button>
              </div>
              {itr !== list_category[mode1_2[1]][1].length - 1 ? <hr className="mt-[8px] mb-[7px] border-[#F4F4F4]" /> : null}
            </div>
          ))}
        </div>
      )
    ) : null;
  };

  return typeFilter ? (
    <>
      {/* 음영 */}
      <div className="absolute top-0 z-[22] w-full min-w-[360px] h-screen bg-[rgba(28,28,28)]/80" onClick={() => handleFilter(0)} />

      <div className="bg-white w-full min-w-[360px] h-[580px] rounded-t-[20px] fixed z-[22] bottom-0">
        <div className="w-[40px] h-[5px] rounded-[2.5px] bg-[#F0F0F0] mx-auto mt-[10px]" onClick={() => handleFilter(0)} />

        <div className="mt-[25px] flex font-bold text-[#C3C3C3] mb-[28px]">
          {/* 하단 메뉴 */}
          {filterOptionsLiquid.map((elem, itr) => (
            <p className={`ml-[16px] ${typeFilter === itr + 1 ? "text-black" : null}`} key={"liquid_filter_tab_" + itr} onClick={() => setFilterType(itr + 1)}>
              {elem}
            </p>
          ))}
        </div>
        <div className="text-[#A0A0A0] font-medium text-14px">
          {typeFilter === 1 ? <ModalBrand /> : typeFilter === 2 ? <ModalTaste /> : typeFilter === 3 ? <ModalCategory /> : null}
          {/* <div className="absolute bottom-[81px] w-full mt-[12px] flex bg-teal-500"> */}
          {/* 이 부분은 마지막에 검토!! */}
          {/* {["김치볶음밥", "잔치국수", "튀김우동"].map((elem) => (
            <div className="bg-black rounded-[18.5px] text-white w-[79px] h-[37px] mr-[8px] flex flex-col justify-center">
              <p>{elem}</p>
            </div>
          ))}
        </div> */}
        </div>
        <div className="w-full min-w-[360px] h-[130px] flex absolute bottom-0">
          <div className="mt-[12px] mb-[17px] w-full relative">
            <button
              className="bg-white border border-[#E6E6E6] rounded-[8px] w-[114px] h-[52px] mr-[6px]"
              onClick={() => {
                ref_brand.current = new Array(list_brand.length).fill(false);
                ref_min_taste.current = new Array(list_taste.length).fill(0);
                ref_max_taste.current = new Array(list_taste.length).fill(intensity_taste.length - 1);
                ref_category1.current = new Array(list_category.length).fill([]);
                ref_category2.current = [];
                for (let i = 0; i < list_category.length; i++) ref_category2.current.push(new Array(list_category[i][1].length).fill(false));

                dispatch(setSearchText(""));
                MakeQuery();
                handleFilter(0);
              }}
            >
              초기화
            </button>
            <button
              className="bg-black text-white rounded-[8px] w-[208px] h-[52px]"
              onClick={() => {
                // 실제로 상품 필터 적용하는 코드
                ref_brand.current = [...tmp_brand.current];
                ref_min_taste.current = [...tmp_min_taste.current];
                ref_max_taste.current = [...tmp_max_taste.current];
                ref_category1.current = tmp_category1.current.map((v) => [...v]);
                ref_category2.current = tmp_category2.current.map((v) => [...v]);
                MakeQuery();
                handleFilter(0);
              }}
            >
              상품 보기
            </button>
          </div>
        </div>
      </div>
    </>
  ) : null;
}
