import Axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setCustom } from "../../redux/module/custom_str";

export default function ReviewPage() {
  const { type, pid } = useParams();
  const dispatch = useDispatch();
  const user_info = useSelector((state) => state.user_info.data);

  const queryURI = `${type[0].toUpperCase()}${pid.padStart(5, "0")}`;

  const [reviews, setReview] = useState([]);
  const [read_write, setReadWrite] = useState(0);

  const [parent_stars, setParentStars] = useState(5);
  const [parent_jotdown, setParentJotdown] = useState("");

  useEffect(() => {
    dispatch(setCustom("리뷰"));
  }, [dispatch]);

  useEffect(() => {
    if (queryURI) {
      Axios.get(
        `${process.env.REACT_APP_FRONT_URL}/api/item/review?type=${type}&item_id=${queryURI}`
      )
        .then((res) => setReview(res.data))
        .catch(() => console.log("DB 통신 오류R"));
    }
  }, [queryURI, type]);

  const rvInit = useMemo(
    () => ({
      review_id: "",
      user_id: "",
      item_id: "",
      review_content: "",
      review_img: "",
      review_rate: "",
      review_date: "",
    }),
    []
  );
  const [myReview, setMyReview] = useState(rvInit);
  const myReviewIdx = useRef(-1);

  useEffect(() => {
    if (reviews.length && user_info.user_id) {
      setMyReview(() => {
        const myRev = reviews.filter((review, idx) => {
          if (review.user_id === user_info.user_id) myReviewIdx.current = idx;
          return review.user_id === user_info.user_id;
        })[0];

        if (myRev) {
          setParentStars(myRev.review_rate);
          setParentJotdown(myRev.review_content);

          return myRev;
        }
        return rvInit;
      });
    }
  }, [reviews, rvInit, user_info.user_id]);

  const SendReview = ({ stars, text }) =>
    Axios.post(`${process.env.REACT_APP_FRONT_URL}/api/item/review`, {
      user_id: user_info.user_id,
      item_id: `VA_${queryURI}`,
      stars,
      text,
    });

  const ShowReview = ({ review }) => (
    <>
      <div className="mt-[24px] mx-[16px] flex justify-between">
        <div className="flex">
          {review.user_info && review.user_info.user_img[0] > 0 ? (
            <img
              src={`/imageDB/my_page/profile_picture/${
                review.user_info.user_img[0]
              }-${review.user_info.user_img[1].padStart(2, "0")}.png`}
              className="mx-auto h-[40px] w-[40px]"
              alt="프로필 이미지"
            />
          ) : (
            <div className="w-[40px] h-[40px] rounded-full bg-[#C6D8EA] ml-auto relative">
              <p className="w-[16px] h-[16px] rounded-full bg-white absolute inset-x-0 mx-auto top-[5px]" />
              <p className="w-[33px] h-[33px] rounded-full bg-white absolute inset-x-0 mx-auto top-[24px]" />
            </div>
          )}
          <div className="ml-[15px] text-14px">
            <p className="font-medium">
              {review.user_info ? review.user_info.user_nickname : ""}님
            </p>
            <p className="text-[#878787] Roboto">
              {review.review_date.slice(0, 10)}
            </p>
          </div>
        </div>
        <p>
          <span className="relative text-[#EEEEEE]">
            ★★★★★
            <span
              className="text-[#FF8E00] absolute left-0 overflow-hidden"
              style={{
                width: `${
                  review.review_rate > 5 ? 100 : review.review_rate * 20
                }%`,
              }}
            >
              ★★★★★
            </span>
          </span>
        </p>
      </div>

      <p className="mt-[19px] mb-[30px] mx-[16px] bg-[#F6F6F6] py-[12px] px-[12px] text-[12px]">
        {review.review_content}
      </p>
    </>
  );

  const WriteReview = (props) => {
    const [stars, setStars] = useState(props.star_obj.parent_stars);
    const [jotdown, setJotdown] = useState(props.jotdown_obj.parent_jotdown);

    return (
      <>
        <div className="mt-[24px] mx-[16px] flex justify-between">
          <div className="flex">
            {user_info.user_img[0] > 0 ? (
              <img
                src={`/imageDB/my_page/profile_picture/${
                  user_info.user_img[0]
                }-${user_info.user_img[1].padStart(2, "0")}.png`}
                className="mx-auto h-[40px] w-[40px]"
                alt="프로필 이미지"
              />
            ) : (
              <div className="w-[40px] h-[40px] rounded-full bg-[#C6D8EA] ml-auto relative">
                <p className="w-[16px] h-[16px] rounded-full bg-white absolute inset-x-0 mx-auto top-[5px]" />
                <p className="w-[33px] h-[33px] rounded-full bg-white absolute inset-x-0 mx-auto top-[24px]" />
              </div>
            )}
            <div className="ml-[15px] text-14px">
              <p className="font-medium">
                {user_info ? user_info.user_nickname : ""}님
              </p>
              {myReview.review_date ? (
                <p className="text-[#878787] Roboto">
                  {myReview.review_date.slice(0, 10)}
                </p>
              ) : null}
            </div>
          </div>
          <p className="text-[#EEEEEE]">
            {[1, 2, 3, 4, 5].map((elem, i) => (
              <span
                key={`star_${i}`}
                className={stars >= elem ? "text-[#FF8E00]" : null}
                onClick={() => setStars(elem)}
              >
                ★
              </span>
            ))}
          </p>
        </div>

        <p className="px-[20px] text-center">
          <textarea
            value={jotdown}
            onChange={(e) => setJotdown(e.target.value)}
            placeholder="리뷰를 작성해주세요"
            autoFocus={true}
            className="mt-[19px] p-[12px] w-full h-[200px] resize-none bg-[#EBEBEB] text-[12px]"
          />
        </p>

        <p className="mt-[16px] mx-[16px] flex gap-[20px] justify-around">
          <button
            className="w-[100px] h-[50px] bg-slate-300"
            onClick={() => {
              SendReview({ stars, text: jotdown })
                .then((res) => {
                  switch (res.data) {
                    case 0:
                      setMyReview((cur) => ({
                        ...cur,
                        review_rate: stars,
                        review_content: jotdown,
                      }));
                      setReview((cur) => {
                        const myrv =
                          myReviewIdx.current !== -1
                            ? cur[myReviewIdx.current]
                            : { ...rvInit };
                        myrv.user_id = user_info.user_id;
                        myrv.item_id = `VA_${queryURI}`;
                        myrv.review_content = jotdown;
                        myrv.review_rate = stars;
                        myrv.review_date = new Date().toISOString();
                        myrv.user_info = {
                          user_id: user_info.user_id,
                          user_nickname: user_info.user_nickname,
                          user_img: user_info.user_img,
                        };
                        if (myReviewIdx.current !== -1)
                          cur[myReviewIdx.current] = myrv;
                        else cur.unshift(myrv);

                        return [...cur];
                      });
                      props.star_obj.setParentStars(stars);
                      props.jotdown_obj.setParentJotdown(jotdown);
                      alert("리뷰가 작성 되었습니다!");
                      break;
                    case 1:
                      alert("예상치 못한 에러가 발생했 습니다!");
                      break;
                    case 2:
                      alert("리뷰 중복 작성은 불가능 합니다!");
                      break;
                    case 3:
                      alert("구매후 리뷰 작성 가능합니다!");
                      break;
                    default:
                  }
                })
                .catch((err) => console.log(err));
              props.setReadWrite(0);
            }}
          >
            저장하기
          </button>
          <button
            className="w-[100px] h-[50px] bg-slate-100"
            onClick={() => props.setReadWrite(0)}
          >
            취소하기
          </button>
        </p>
      </>
    );
  };

  return (
    <div className="mt-[12px]">
      {!read_write ? (
        <div className="flex justify-between mx-[16px]">
          <p className="text-left font-medium">
            총 {reviews.length.toLocaleString("ko-KR")}개의 리뷰
          </p>
          <p
            className="flex"
            onClick={() => {
              if (myReview.review_content === "") setReadWrite((cur) => !cur);
              else alert("이미 리뷰를 작성 하셨습니다!");
            }}
          >
            <img
              src="/imageDB/common/write.svg"
              width={20}
              alt="작성"
              className="self-end"
            />
          </p>
        </div>
      ) : null}
      <div className="mt-[16px] text-left">
        {!read_write ? (
          <>
            {
              // 내 리뷰가 존재하지만 리뷰 중 마지막이 아닌 경우에만 표시한다.
              myReview.review_content && myReviewIdx.current !== 0 ? (
                <>
                  <hr className="border-[#EBEBEB]" />
                  <ShowReview review={myReview} />
                </>
              ) : null
            }
            {
              // 필터링 작업은 위에서 해주니까 여기는 그냥 존재하는 경우에 띄우기만 하면 됨
              reviews.length
                ? reviews.map((elem, itr) => (
                    <div key={"review_" + pid + "_" + itr}>
                      <hr className="border-[#EBEBEB]" />
                      <ShowReview review={elem} itr={itr} />
                    </div>
                  ))
                : null
            }
          </>
        ) : (
          <WriteReview
            star_obj={{ parent_stars, setParentStars }}
            jotdown_obj={{ parent_jotdown, setParentJotdown }}
            setReadWrite={setReadWrite}
          />
        )}
      </div>
    </div>
  );
}
