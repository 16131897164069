// Swiper 라이브러리
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import { useDispatch, useSelector } from "react-redux";
import { addCartItem, setAddIdle, setBasket, setIOstatus } from "../../../redux/module/item_cart";

export default function Acc({ dev, parts_list, handleAcc }) {
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.user_info.data.user_id);

  return (
    <>
      <div className="mx-[16px] pt-[17px] flex justify-between">
        <p className="text-14px">
          <span className="font-bold">{dev ? dev.item_name : "브이쓰루 프로"}</span>
          &nbsp;
          <span className="font-medium">전용 부품</span>
        </p>
        <p className="flex px-[4px]" onClick={() => handleAcc(false)}>
          <img src="/imageDB/common/blackTimes.svg" alt="닫기" />
        </p>
      </div>
      {parts_list && parts_list.length ? (
        <Swiper className="mt-[16px]" width={154} slidesOffsetBefore={16} slidesOffsetAfter={-16} spaceBetween={10} modules={[Pagination]}>
          {parts_list.map((part, i) => (
            <SwiperSlide key={i} className="acc-parts text-12px">
              <div className="absolute left-0 top-0 w-full flex">
                <p className="absolute top-[9px] h-[64px] flex">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_BASE}${part.item_img}?timestamp=${new Date().getTime()}&w=256&q=75&f=webp`}
                    alt={`부품_${i + 1}`}
                    className="self-center"
                    width={64}
                  />
                </p>
                <div className="absolute top-[11px] right-[12px] w-[78px] text-left font-medium break-all">
                  <p>{part.item_company}</p>
                  <p className="">{part.item_name}</p>
                </div>
              </div>
              <p className="absolute left-[64px] bottom-[49px] font-semibold Roboto">1개 / ₩{part.item_price.toLocaleString("ko-KR")}</p>
              <button
                className="absolute bottom-[10px] w-[134px] h-[32px] rounded-[7px] bg-white flex"
                style={part.stock <= 0 ? { backgroundColor: "#E3E3E3", color: "#969696" } : {}}
                onClick={() => {
                  dispatch(setAddIdle(false));
                  dispatch(setIOstatus(0));
                  dispatch(
                    addCartItem({
                      user_id,
                      item_id: part.item_id,
                    }),
                  );
                  dispatch(setBasket(true));
                }}
                disabled={part.stock <= 0}
              >
                <span className="font-medium text-14px self-center mx-auto">{part.stock <= 0 ? "품절" : "담기"}</span>
              </button>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p className="mt-[17px] mx-[16px] h-[148px] rounded-[14px] bg-[#F5F5F5] text-[#6C6C6C] flex">
          <span className="mx-auto self-center text-center text-14px">
            준비중입니다!
            <br />
            빠른 시일내에 입고하겠습니다
          </span>
        </p>
      )}
    </>
  );
}
