import { memo, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCart, setAddIdle, setBasket } from "../../redux/module/item_cart";
import HeadShop from "./HeadShop";

const Header = () => {
  const dispatch = useDispatch();
  const href = window.location.href;
  const nav = useNavigate();
  const head_text = useSelector((state) => state.custom_str.head_text);
  const [item, setItem] = useState([]);
  const user_info = useSelector((state) => state.user_info.data);
  const store_cart = useSelector((state) => state.item_cart);

  useEffect(() => {
    if (!store_cart.addIdle && user_info.user_id && store_cart.IOongoing === 1) {
      dispatch(fetchCart(user_info.user_id));
      dispatch(setAddIdle(true));
    }
  }, [dispatch, store_cart.addIdle, store_cart.IOongoing, user_info.user_id]);

  useEffect(() => {
    setItem((prevItem) => {
      // Use the updater callback to ensure the latest state
      if (store_cart.data.length !== prevItem.length) {
        return store_cart.data;
      }
      return prevItem;
    });
  }, [store_cart.data, item]);

  const HeadLanding = () => (
    <p className="pl-[28px] w-4/12" onClick={() => nav(-1)}>
      <img src="/imageDB/common/chevronLeft.svg" width={11} alt="back" />
    </p>
  );

  const HeadMain = () => (
    <>
      <p className="mx-auto">
        <img src="/img/Ando_Logo.svg" className="mx-auto" alt="ANDO-Logo" />
      </p>
      <hr className="mt-[12px] border-[#EFEFEF] w-full" />
    </>
  );

  const HeadLogo = () => (
    <>
      <p className="mx-auto" onClick={() => nav("/")}>
        <img src="/img/Ando_Logo.svg" className="mx-auto" alt="ANDO-Logo" />
      </p>
      <hr className="mt-[12px] border-[#EFEFEF] w-full" />
    </>
  );

  const HeadCustom = () => (
    <div className="flex items-center pt-[12px] pb-[18px]">
      <p className="w-4/12 pl-[28px]" onClick={() => nav(-1)}>
        <img src="/imageDB/common/chevronLeft.svg" alt="back" />
      </p>
      <p className="w-4/12 Rubik">{head_text}</p>
      <p className="w-4/12" />
    </div>
  );

  const HeadPrev = () => (
    <div className="flex items-center pt-[12px] pb-[18px]">
      <p className="w-4/12 my-[3px] pl-[28px]" onClick={() => nav(-1)}>
        <img src="/imageDB/common/chevronLeft.svg" alt="back" />
      </p>
    </div>
  );

  const HeadMyPage = () => (
    <div className="flex items-center pt-[12px] pb-[18px]">
      {head_text && (
        <p className="w-4/12 pl-[28px]" onClick={() => nav("/my")}>
          <img src="/imageDB/common/chevronLeft.svg" alt="back" />
        </p>
      )}
      <p className="w-4/12 Rubik">{head_text}</p>
      <p className="w-4/12" />
    </div>
  );

  const HeadElse = () => (
    <div className="flex items-center pb-[12px]">
      <p className="w-4/12 pl-[28px]" onClick={() => nav(-1)}>
        <img src="/imageDB/common/chevronLeft.svg" alt="back" />
      </p>
      <p
        className="w-4/12"
        onClick={() => {
          nav("/");
        }}
      >
        <img src="/img/Ando_Logo.svg" className="mx-auto" alt="ANDO-Logo" />
      </p>
      <p className="w-4/12">
        {item.length > 0 ? (
          <div
            onClick={() => {
              dispatch(setBasket(true));
              nav("/#basket");
            }}
            style={{
              position: "absolute",
              borderRadius: "50px",
              backgroundColor: "red",
              width: "13px",
              height: "13px",
              left: "auto",
              top: "10px",
              right: "30px",
              fontSize: "8px",
              color: "white",
            }}
          >
            {item.length}
          </div>
        ) : null}
        <img
          src="/imageDB/header/cart.svg"
          onClick={() => {
            dispatch(setBasket(true));
            nav("/#basket");
          }}
          style={{ marginRight: "28px", marginLeft: "auto" }}
          alt="ANDO-cart"
        />
      </p>
    </div>
  );

  const MemoHead = useMemo(() => {
    let Head = null;
    if (href.includes("landing")) {
      if (!href.endsWith("landing")) Head = HeadLanding;
    } else if (
      href.endsWith(process.env.REACT_APP_FRONT_URL) ||
      href.endsWith(`${process.env.REACT_APP_FRONT_URL}/` || href.endsWith(`${process.env.REACT_APP_FRONT_URL}/#basket`))
    )
      Head = HeadMain;
    else if (href.includes("my/dev") || href.includes("review") || href.includes("shipping") || href.endsWith("history") || href.endsWith("invitations"))
      Head = HeadCustom;
    else if (href.includes("my/account")) Head = HeadMyPage;
    else if (href.endsWith("my")) Head = null;
    else if (href.endsWith("curation") || href.includes("event") || href.includes("banner")) Head = HeadPrev;
    else if (href.endsWith("shop")) Head = HeadLogo;
    else if (href.includes("shop")) Head = HeadShop;
    else Head = HeadElse;

    return Head && memo(Head);
    // eslint-disable-next-line
  }, [href, item]);

  return (
    <header
      className={
        href.endsWith(process.env.REACT_APP_FRONT_URL) || href.includes("shop")
          ? "pt-[12px] flex flex-col sticky top-0 bg-white z-20"
          : "flex flex-col sticky top-0 bg-white z-20 pt-[12px]"
      }
      onClick={(e) => e.stopPropagation()}
    >
      {MemoHead && <MemoHead />}
    </header>
  );
};

export default memo(Header);
