import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Swiper 라이브러리
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";

import { fetchCart, delCartItem, editCartItem, setAddIdle, setDelIdle, setEditIdle, setIOstatus, setBasket } from "../../redux/module/item_cart";
import onClickPayment from "../IMP/onClickPayment";

export default function Basket() {
  const dispatch = useDispatch();
  const nav = useNavigate();

  const user_info = useSelector((state) => state.user_info.data);
  const store_cart = useSelector((state) => state.item_cart);

  const [cart_item, setItem] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const qtyRef = useRef([]);
  const delete_idx = useRef(-1);

  // 장바구니 수정이 가능한지 여부
  // 수정 중인 경우 traffic이 false
  // const [traffic, setTraffic] = useState(true);
  const traffic = useRef(true);

  const [total_price, setTotal] = useState(0);
  const [use_point, setUse] = useState(false);
  const [pointAmount, setPoint] = useState(0);
  const [inputAmount, setInput] = useState(null);

  useEffect(() => {
    // 처음. 데이터를 DB에서 받아와야 함
    if (!store_cart.addIdle && user_info.user_id && store_cart.IOongoing === 1) {
      dispatch(fetchCart(user_info.user_id));
      dispatch(setAddIdle(true));
    }

    // 데이터를 DB에서 받아왔고,
    // 로컬 state에 그 데이터를 적용해야 함
    if (store_cart.IOongoing === 2) {
      dispatch(setIOstatus(0));
      setItem(store_cart.data);
      const qty = store_cart.data.map((elem) => elem.quantity);
      setQuantity(qty);
      qtyRef.current = [...qty];
    }

    // DB에서 데이터 로드는 이미 했고, 리덕스 장바구니는 내용물이 있는데,
    // 로컬 state는 비어있네? => 다른 페이지를 갔다온 경우
    if (!store_cart.IOongoing && store_cart.data.length && !cart_item.length) {
      setItem(store_cart.data);
      setQuantity(store_cart.data.map((elem) => elem.quantity));
      qtyRef.current = store_cart.data.map((elem) => elem.quantity);
    }
  }, [dispatch, cart_item.length, store_cart.addIdle, store_cart.data, store_cart.IOongoing, user_info.user_id]);

  useEffect(() => {
    if (store_cart.delIdle && delete_idx.current !== -1) {
      traffic.current = true;
      setItem((cur) => {
        const arr = [...cur];
        arr.splice(delete_idx.current, 1);
        delete_idx.current = -1;
        return arr;
      });
      setQuantity([...qtyRef.current]);
    }
  }, [dispatch, store_cart.delIdle]);

  useEffect(() => {
    if (store_cart.editIdle && !traffic.current) {
      traffic.current = true;
      setQuantity([...qtyRef.current]);
    }
  }, [dispatch, store_cart.editIdle]);

  useEffect(() => {
    const tot_sum = cart_item
      .filter((dt) => dt.stock > 0)
      .reduce((acc, cur, idx) => acc + (cur.discount_price > 0 ? cur.discount_price : cur.item_price) * quantity[idx], 0);
    let temp_sum = tot_sum >= 50000 ? tot_sum : tot_sum + 3000;

    if (temp_sum - pointAmount < 100) {
      setPoint(temp_sum - 100);
      temp_sum = 100;
    }
    setTotal(temp_sum);
  }, [cart_item, quantity, pointAmount]);

  const basket_open = useSelector((state) => state.item_cart.basket_open);

  // 0: 거래 전, 1: 거래 시도, 2: 거래 성공
  // 3: 거래 실패 (결제 취소 등), 4: 통신 실패
  const [contract, setContract] = useState(0);
  if (false) setContract(0);

  // 밀어서 바로 받기용 변수
  // const [start_x, setStart] = useState(0);
  // const [x_coord, setX] = useState(0);
  // const [slide, setSlide] = useState(0);
  // const [pressed, setPress] = useState(false);

  const navDetail = (item_id) => {
    switch (item_id[3]) {
      case "L":
        nav(`/detail/liquid/${parseInt(item_id.slice(4))}`);
        break;
      case "M":
        nav(`/detail/machine/${parseInt(item_id.slice(4))}`);
        break;
      case "P":
        nav(`/detail/pod/${parseInt(item_id.slice(4))}`);
        break;
      case "C":
        nav(`/detail/coil/${parseInt(item_id.slice(4))}`);
        break;
      default:
        break;
    }
  };

  // const SlideEndCapture = () => {
  //   if (pressed) setX(slide);
  //   setPress(false);
  //   if (slide >= window.innerWidth / 2) {
  //     const itemDatas = store_cart.data.filter((dt) => dt.stock > 0);
  //     console.log(itemDatas, itemDatas.length);
  //     if (itemDatas.length === 0) return alert("구매할 상품이 없습니다.");
  //     // 본인인증 시스템 도입한 이후, 구매자가 성인인증이 완료된 유저인지 체크가 필요함.
  //     onClickPayment({
  //       user_data: {
  //         user_id: user_info.user_id,
  //         orderer_name: user_info.user_nickname,
  //         tel: user_info.user_contact,
  //         addr: `${user_info.user_addr1} ${user_info.user_addr2}`.substring(8),
  //         postal_code: `${user_info.user_addr1} ${user_info.user_addr2}`.substring(1, 6),
  //         user_def_order_msg: user_info.user_def_order_msg,
  //         user_adult: user_info.user_adult,
  //       },
  //       items: itemDatas,
  //       point_used: use_point ? pointAmount : 0,
  //       total_price,
  //     });
  //   }
  //   setSlide(0);
  //   setX(0);
  // };

  return (
    <div className="w-full min-w-[360px] bg-[#F5F4F1]">
      <p className="font-medium py-[16px] flex justify-center" onClick={() => dispatch(setBasket(!basket_open))}>
        <span className="mr-[6px]">장바구니 </span>
        <img src={basket_open ? "/imageDB/common/bracketUp.svg" : "/imageDB/common/bracketDown.svg"} width={14} alt={basket_open ? "열기" : "닫기"} />
        {cart_item.length ? (
          <div
            style={{
              borderRadius: "50px",
              backgroundColor: "red",
              color: "white",
              width: "25px",
              height: "25px",
              fontSize: "16px",
              marginLeft: "4px",
            }}
          >
            {cart_item.length}
          </div>
        ) : null}
      </p>
      <div
        className={`basket overflow-hidden transition-all duration-700${contract ? " bg-[#DCD4CB]" : ""}`}
        // 하드코딩이 좋은 건 아닌데, "auto"로 할 경우 transition 애니메이션이 나타나지 않아서
        // 어쩔 수 없이 height를 재서 기록함.
        // "auto"로 이 기능을 쓰고 싶다면 react-animate-height 라이브러리를 써야 함.
        style={{ height: basket_open ? (cart_item.length ? 437 : 297) : 0 }}
      >
        {cart_item.length ? (
          <div>
            {!contract && cart_item.length ? (
              <>
                <Swiper id="basket" width={102} slidesOffsetBefore={16} slidesOffsetAfter={-16} spaceBetween={10} modules={[Pagination]} className="relative">
                  {cart_item.map((item, i) => (
                    <SwiperSlide key={`cart_item${i + 1}`} className="basket-parts">
                      <p
                        className="bg-white w-[30px] h-[30px] rounded-full absolute -top-[8px] -right-[8px] border-4 border-[#F5F5F5] flex justify-center items-center"
                        onClick={() => {
                          if (traffic.current) {
                            qtyRef.current.splice(i, 1);
                            delete_idx.current = i;
                            traffic.current = false;
                            dispatch(setDelIdle(false));
                            dispatch(
                              delCartItem({
                                user_id: user_info.user_id,
                                item_id: item.item_id,
                                idx: i, // FE에서 리덕스 상태를 제어하기 위한 매개변수. BE에선 활용 X
                              }),
                            );
                          }
                        }}
                      >
                        <img src="/imageDB/common/B8B8B8_times.svg" width={10} className="mx-auto" alt="제거" />
                      </p>
                      <div className="text-12px text-left">
                        <div
                          className="absolute top-[15px] inset-x-0 mx-[10px] font-medium"
                          // 스크롤하는 것만으로 페이지 이동이 일어날 경우
                          // 이 부분을 없애면 됨
                          onClick={() => navDetail(item.item_id)}
                        >
                          <p className="h-[65px] flex">
                            <img
                              width={65}
                              src={process.env.REACT_APP_IMAGE_BASE + item.item_img + `?timestamp=${new Date().getTime()}&w=256&q=75&f=webp`}
                              className="mx-auto self-center"
                              alt={`장바구니 상품 ${i + 1}`}
                            />
                          </p>
                          <p className="mt-[10px]">{item.item_company}</p>
                          <p className="text-[#333333]">{item.item_name}</p>
                          {item.item_option ? <p className="text-[#828282] font-normal">{item.item_option}</p> : null}
                        </div>
                        <div className="absolute bottom-[8px] inset-x-0 mx-[10px]">
                          <p className="mt-[18px] font-semibold Roboto text-right">
                            1개 / ₩{(item.discount_price > 0 ? item.discount_price : item.item_price).toLocaleString("ko-KR")}
                          </p>
                          <div className="mt-[4px] flex justify-between">
                            {item.stock <= 0 ? (
                              <div
                                className="self-center text-center rounded-lg text-white bg-[#292929]"
                                style={{
                                  width: "100%",
                                  height: "30px",
                                  lineHeight: "30px",
                                  backgroundColor: "#E3E3E3",
                                  color: "#969696",
                                }}
                                disabled
                              >
                                품절
                              </div>
                            ) : (
                              <>
                                <p
                                  className="w-[30px] h-[30px] rounded-full bg-[#F6F6F6] flex flex-col justify-center items-center"
                                  onClick={() => {
                                    if (traffic.current && quantity[i] > 1) {
                                      qtyRef.current[i] = quantity[i] - 1;
                                      traffic.current = false;
                                      dispatch(setEditIdle(false));
                                      dispatch(
                                        editCartItem({
                                          user_id: user_info.user_id,
                                          item_id: item.item_id,
                                          quantity: quantity[i] - 1,
                                        }),
                                      );
                                    }
                                  }}
                                >
                                  <img src="/imageDB/common/minus.svg" width={13.5} alt="덜기" />
                                </p>
                                <p className="mx-[6px] text-16px self-center font-medium Roboto">{quantity[i]}</p>
                                <p
                                  className="w-[30px] h-[30px] rounded-full bg-[#F6F6F6] flex flex-col justify-center items-center"
                                  onClick={() => {
                                    if (traffic.current) {
                                      qtyRef.current[i] = quantity[i] + 1;
                                      traffic.current = false;
                                      dispatch(setEditIdle(false));
                                      dispatch(
                                        editCartItem({
                                          user_id: user_info.user_id,
                                          item_id: item.item_id,
                                          quantity: quantity[i] + 1,
                                        }),
                                      );
                                    }
                                  }}
                                >
                                  <img src="/imageDB/common/plus.svg" width={13.5} alt="담기" />
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>

                <div className="mx-[16px] flex justify-between">
                  <button className="w-[100px] h-[26px] bg-[#5F5F5F] text-white text-14px rounded-[5px]" onClick={() => nav("/history")}>
                    최근 구매내역
                  </button>

                  <div className="flex">
                    <img src="/imageDB/my_ando/AndoPoint.png" width={25} height={25} alt="안도 포인트" />
                    {!use_point ? (
                      <>
                        <p className="font-bold text-12px ml-[2px] self-center">{user_info.user_points.toLocaleString("ko-KR")}p</p>
                        <form
                          className="ml-[4px] flex"
                          onSubmit={(e) => {
                            e.preventDefault();

                            if (inputAmount > 0) {
                              setPoint(Number(inputAmount));
                              setUse(true);
                            } else {
                              let selectedAmount = 0;

                              if (user_info.user_points >= total_price) {
                                selectedAmount = Number(total_price);
                              } else if (user_info.user_points > 0) {
                                selectedAmount = Number(user_info.user_points);
                              }

                              setPoint(selectedAmount);
                              setUse(true);
                            }
                          }}
                        >
                          <input
                            className="w-[56px] h-[22px] pl-[6px] bg-transparent text-12px border border-[#b1b1b1] rounded-[3px] self-center"
                            type="number"
                            value={inputAmount ?? 0}
                            onChange={(e) => {
                              let point = Number(e.target.value.replace(/^0+/, ""));
                              if (point >= total_price) point = Number(total_price);
                              else if (point >= user_info.user_points) point = Number(user_info.user_points);
                              else if (point < 0) point = 0;

                              setInput((prev) => point);
                            }}
                          />
                          &nbsp;
                          <button className="bg-[#0085FF] text-white rounded-full w-[68px] h-[28px] text-12px self-center">
                            {inputAmount ? "포인트 사용" : "전체 사용"}
                          </button>
                        </form>
                      </>
                    ) : (
                      <>
                        <input
                          className="w-[56px] h-[22px] pl-[6px] bg-[rgba(0,0,0)]/0 text-12px font-bold border border-[#b1b1b1] rounded-[3px] self-center"
                          value={`${pointAmount.toLocaleString("ko-KR")}p`}
                          disabled={true}
                        />
                        &nbsp;
                        <button
                          className="text-12px h-[28px] self-center"
                          onClick={() => {
                            setUse(false);
                            setInput(isNaN(inputAmount) ? 0 : inputAmount);
                          }}
                        >
                          취소
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <p className="mt-[12px] mr-[12px] text-12px text-right">
                  배송비 3,000원
                  <br />
                  *50,000원 이상 구매 시 무료
                </p>
                <div className="text-right mt-[8px] mr-[16px] font-semibold">
                  총 ₩{(!use_point ? total_price : total_price - pointAmount).toLocaleString("ko-KR")}
                </div>
                <div className="flex justify-center items-center mt-[8px]">
                  <button
                    className="flex border border-[#E6E6E6] rounded-[12px] text-[18px] w-[328px] h-[62px] justify-center items-center"
                    style={{ backgroundColor: "#0081F8", color: "white", margin: "0 16px" }}
                    onClick={() => {
                      const itemDatas = store_cart.data.filter((dt) => dt.stock > 0);
                      console.log(itemDatas, itemDatas.length);
                      if (itemDatas.length === 0) return alert("구매할 상품이 없습니다.");
                      // 본인인증 시스템 도입한 이후, 구매자가 성인인증이 완료된 유저인지 체크가 필요함.
                      onClickPayment({
                        user_data: {
                          user_id: user_info.user_id,
                          orderer_name: user_info.user_nickname,
                          tel: user_info.user_contact,
                          addr: `${user_info.user_addr1} ${user_info.user_addr2}`.substring(8),
                          postal_code: `${user_info.user_addr1} ${user_info.user_addr2}`.substring(1, 6),
                          user_def_order_msg: user_info.user_def_order_msg,
                          user_adult: user_info.user_adult,
                        },
                        items: itemDatas,
                        point_used: use_point ? pointAmount : 0,
                        total_price,
                      });
                    }}
                  >
                    구매하기
                  </button>
                </div>
                {/* <div
                  className="mt-[28px] h-[55px] font-bold bg-[#EDEBE4] flex items-center"
                  onMouseDownCapture={(e) => {
                    setStart(e.clientX);
                    setPress(!(e.target.classList.contains("swiper-slide") || e.target.classList.contains("swiper")));
                  }}
                  onMouseMoveCapture={(e) => {
                    if (pressed) {
                      let sliding = x_coord + e.clientX - start_x;
                      if (sliding > window.innerWidth / 2) sliding = window.innerWidth / 2;
                      else if (sliding < 0) sliding = 0;
                      setSlide(sliding);
                    }
                  }}
                  onMouseUpCapture={SlideEndCapture}
                  onTouchStartCapture={(e) => {
                    setStart(e.touches[0].clientX);
                    setPress(!(e.target.classList.contains("swiper-slide") || e.target.classList.contains("swiper")));
                  }}
                  onTouchMoveCapture={(e) => {
                    if (pressed) {
                      let sliding = x_coord + e.touches[0].clientX - start_x;
                      if (sliding > window.innerWidth / 2) sliding = window.innerWidth / 2;
                      else if (sliding < 0) sliding = 0;
                      setSlide(sliding);
                    }
                  }}
                  onTouchEndCapture={SlideEndCapture}
                  style={{
                    transition: pressed ? "none" : "0.3s",
                    transform: `translateX(${slide}px)`,
                  }}
                >
                  <div className="flex items-center w-fit mx-auto">
                    <p className="text-[#363636]">👉 밀어서 바로 받기</p>
                    &nbsp;&nbsp;
                    <p className="mt-[2px] slide-to-buy">
                      <img draggable="false" src="/imageDB/my_ando/doubleArrowBrack.svg" alt="밀기" />
                    </p>
                  </div>
                </div> */}
              </>
            ) : contract === 1 ? (
              <>
                <video muted autoPlay loop src="/1.success.mp4" className="mt-[10px] inset-x-0 mx-auto" type="video/mp4">
                  <strong>Your browser does not support the video tag.</strong>
                </video>
                <div className="absolute max-w-max top-[227px] inset-x-0 mx-auto">
                  <p className="text-18px font-medium">결제완료</p>
                  <p className="text-[#5F5F5F]">빠르게 배송해드릴게요!</p>
                  <button className="mt-[17px] w-[98px] h-[42px] bg-[#373737] rounded-[12px] text-white Roboto" onClick={() => dispatch(setBasket(false))}>
                    닫기
                  </button>
                </div>
              </>
            ) : contract === -1 ? (
              <>
                <video muted autoPlay loop src="/2.transaction_fail.mp4" className="absolute -top-[10px]" type="video/mp4">
                  <strong>Your browser does not support the video tag.</strong>
                </video>
                <div className="absolute max-w-max top-[227px] inset-x-0 mx-auto">
                  <p className="text-18px font-medium">결제실패</p>
                  <p className="text-[#5F5F5F]">다시 시도해주세요.</p>
                  <button className="mt-[17px] w-[98px] h-[42px] bg-[#373737] rounded-[12px] text-white Roboto" onClick={() => dispatch(setBasket(false))}>
                    닫기
                  </button>
                </div>
              </>
            ) : contract === -2 ? (
              <>
                <video muted autoPlay loop src="/3.conn_fail.mp4" width={300} className="mx-auto" type="video/mp4">
                  <strong>Your browser does not support the video tag.</strong>
                </video>
                <div className="absolute max-w-max top-[227px] inset-x-0 mx-auto">
                  <p className="text-18px font-medium">통신장애</p>
                  <p className="text-[#5F5F5F]">네트워크 환경을 확인해주세요!</p>
                  <button className="mt-[17px] w-[98px] h-[42px] bg-[#373737] rounded-[12px] text-white Roboto" onClick={() => dispatch(setBasket(false))}>
                    닫기
                  </button>
                </div>
              </>
            ) : null}
          </div>
        ) : (
          <>
            <div className="basket-parts w-[102px] flex flex-col mt-[12px] ml-[16px]">
              <p className="text-12px mt-[67.83px]">장바구니가 비어있어요!</p>
              <p className="mt-[16px] mx-[28px] w-[48px] h-[48px] bg-[#F6F6F6] rounded-full flex justify-center items-center" onClick={() => nav("/shop")}>
                <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 12H22" stroke="#525252" strokeWidth="1.5" strokeLinecap="round" />
                  <path d="M12 1L12 23" stroke="#525252" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </p>
            </div>
            <div className="mx-[16px] flex justify-between pb-[24px]">
              <div className="w-[100px] h-[26px] bg-[#5F5F5F] rounded-[5px] flex flex-col justify-center" onClick={() => nav("/history")}>
                <p className="text-white text-14px">최근 구매내역</p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
