import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserImage } from "../../redux/module/user_info";
import { useNavigate } from "react-router-dom";

export default function MyAvatar() {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const usr_img = useSelector((state) => state.user_info.data.user_img);
  const [im_idx, setIndex] = useState(usr_img);

  const imgCount = [6, 8, 5];

  // im_idx가 [2,3]이 되면 2번째 섹션의 3번째 이미지를 설정한 것
  return (
    <>
      <div className="mt-[27px] mx-[20px]">
        <p className="text-left font-bold text-[20px]">
          프로필 사진을 선택해보세요
        </p>
        {new Array(3).fill(1).map((compo, compoItr) => (
          <div key={`component_${compoItr + 1}`}>
            <div className="mt-[30px] grid grid-cols-3 gap-x-[10px] gap-y-[10px]">
              {new Array(imgCount[compoItr]).fill(1).map((elem, itr) => (
                <div
                  className="mx-auto w-[100px] h-[100px] rounded-full relative overflow-hidden"
                  key={`images_${itr + 1}`}
                  onClick={() => setIndex([compoItr + 1, itr + 1])}
                >
                  <img
                    src={`/imageDB/my_page/profile_picture/${compoItr + 1}-${(
                      itr +
                      1 +
                      ""
                    ).padStart(2, "0")}.png`}
                    width={100}
                    height={100}
                    alt={`프로필 이미지 ${compoItr + 1}-${itr + 1}`}
                  />
                  {im_idx[0] === compoItr + 1 && im_idx[1] === itr + 1 ? (
                    <p className="absolute inset-0 w-[100px] h-[100px] bg-[#2E2E2E]/80 flex">
                      <img
                        src="/imageDB/my_page/profile_picture/Check_Profile.svg"
                        className="self-center mx-auto"
                        alt="선택"
                      />
                    </p>
                  ) : null}
                </div>
              ))}
            </div>
            {compoItr < imgCount.length - 1 ? (
              <hr className="mt-[30px] mx-[4px] border-[#EBEBEB]" />
            ) : null}
          </div>
        ))}

        {/* 하단 여백 */}
        <div className="mt-[110px]" />
      </div>
      {im_idx[0] ? (
        <div
          className="w-full min-w-[360px] h-[77px] bg-black fixed bottom-0 z-20"
          onClick={() => {
            dispatch(setUserImage(im_idx));
            nav(-1);
          }}
        >
          <p className="mt-[16px] text-white font-bold">확인</p>
        </div>
      ) : null}
    </>
  );
}
