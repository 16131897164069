import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";

const fetchBrands = createAsyncThunk("item_acc/fetchBrands", () =>
  Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/item/acc/brands`)
    .then((res) => res.data)
    .catch((err) => console.log("DB 통신 오류AB"))
);

const fetchItem = createAsyncThunk("item_acc/fetchItem", ({ query_clause }) =>
  Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/item/acc?${query_clause}`)
    .then((res) => res.data)
    .catch((e) => console.log("DB 통신 오류AI1"))
);

const fetchQuickAcc = createAsyncThunk(
  "item_acc/fetchQuickAcc",
  (machine_arr) =>
    Axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/item/quickacc?machines=${[
        ...machine_arr.map((mach) => mach.item_id),
      ]}`
    )
      .then((res) => res.data)
      .catch(() => console.log("DB 통신 오류QA"))
);

const item_acc = createSlice({
  name: "item_acc",
  initialState: {
    brands: [],
    items: [],
    items_count: 0,
    quickmenu_data: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrands.fulfilled, (state, action) => {
        if (action.payload && action.payload.length)
          state.brands = action.payload;
      })
      .addCase(fetchItem.fulfilled, (state, action) => {
        if (action.payload) {
          if (action.payload[1].length) {
            state.items_count = action.payload[0];
            state.items = action.payload[1];
          } else {
            state.items_count = 0;
            state.items = [];
          }
        }
      })
      .addCase(fetchQuickAcc.fulfilled, (state, action) => {
        if (action.payload)
          if (action.payload.length) state.quickmenu_data = action.payload;
      });
  },
});

export { fetchBrands, fetchItem, fetchQuickAcc, item_acc };
