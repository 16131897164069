import Axios from "axios";
import { useEffect, useReducer, useState } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import onClickPayment from "../IMP/onClickPayment";

const AddressModal = ({ pleaseDo, user_info, user_state }) => {
  const [phone_num, setPhoneNum] = useState("");

  useEffect(() => {
    if (user_info.user_contact) {
      const num = user_info.user_contact;
      if (num.length === 10) setPhoneNum(`${num.slice(0, 3)}-${num.slice(3, 6)}-${num.slice(6, 10)}`);
      else setPhoneNum(`${num.slice(0, 3)}-${num.slice(3, 7)}-${num.slice(7, num.length)}`);
    }
  }, [user_info.user_contact]);

  const handleComplete = (data) => {
    pleaseDo({ type: "valid", bit: 1, valid: 1 });
    pleaseDo({
      type: "post_search",
      post_search: `(${data.zonecode}) ${data.address}`,
    });
  };

  const open = useDaumPostcodePopup();
  const handleClick = () => open({ onComplete: handleComplete });

  return (
    <>
      <p className="text-left font-medium">배송 정보</p>
      <div className="mt-[13px] flex">
        {["기본 배송지", "신규 입력"].map((elem, itr) => (
          <p
            className={`w-6/12 py-[8px] text-14px ${user_state.tab === itr ? "border-b-[1px] border-black" : ""}`}
            key={`배송지_${itr}`}
            onClick={() => pleaseDo({ type: "tab", tab: itr })}
          >
            {elem}
          </p>
        ))}
      </div>
      <hr className="border-[#9F9F9F]" />
      {!user_state.tab ? (
        <div className="py-[20px] ml-[40px] text-left">
          <p>
            <span>{user_info.user_name}</span>
            <span className="ml-[3px] font-medium text-[#2454FF]">(기본)</span>
          </p>
          <p className="mt-[5px] font-medium text-14px">{user_info.user_addr1}</p>
          <p className="mt-[5px] font-medium text-14px">{user_info.user_addr2}</p>
          <p className="mt-[5px] text-14px">{phone_num}</p>
          <p className="mt-[5px] text-14px">{user_info.user_def_order_msg}</p>
        </div>
      ) : (
        <div className="text-left my-[12px]">
          <p>성함</p>
          <input
            className="mt-[8px] pl-[8px] w-[100px] h-[40px] text-14px border border-[#E4E4E4]"
            defaultValue={user_state.name}
            onBlur={(e) => {
              if (!e.target.value.length) pleaseDo({ type: "valid", bit: 0, valid: 0 });
              else pleaseDo({ type: "valid", bit: 0, valid: 1 });

              pleaseDo({ type: "name", name: e.target.value });
            }}
          />

          <p className="mt-[12px]">주소</p>
          <p className="mt-[8px] font-medium" onClick={handleClick}>
            <input
              className="h-[40px] pl-[8px] w-full text-14px border border-[#E4E4E4]"
              defaultValue={user_state.post_search}
              placeholder="여기를 눌러 주소를 검색해보세요"
              disabled
            />
          </p>
          <p className="mt-[5px] font-medium" onClick={() => !user_state.post_search && handleClick()}>
            <input
              className="h-[40px] pl-[8px] w-full text-14px border border-[#E4E4E4]"
              defaultValue={user_state.post_input}
              placeholder="상세 주소를 입력하세요"
              onBlur={(e) => {
                if (!e.target.value.length) pleaseDo({ type: "valid", bit: 2, valid: 0 });
                else pleaseDo({ type: "valid", bit: 2, valid: 1 });

                pleaseDo({ type: "post_input", post_input: e.target.value });
              }}
              required
            />
          </p>

          <p className="mt-[12px]">연락처</p>
          <input
            className="w-[190px] h-[40px] mt-[8px] pl-[8px] text-14px border invalid:border-red-500 invalid:rounded-md invalid:border-2"
            placeholder="01012345678"
            defaultValue={user_state.contact}
            type="text"
            pattern="(?:\d{10}|\d{11})" // https://bit.ly/3ZnbO1X
            onChange={(e) => {
              if (e.target.value.length > 11) e.target.value = e.target.value.slice(0, 11);
            }}
            onBlur={(e) => {
              if (!e.target.value.length) pleaseDo({ type: "valid", bit: 3, valid: 0 });
              else pleaseDo({ type: "valid", bit: 3, valid: 1 });
              pleaseDo({ type: "contact", contact: e.target.value });
            }}
          />
          <p className="mt-[12px]">배송 메시지</p>
          <input
            className="mt-[8px] pl-[8px] w-full h-[40px] text-14px border border-[#E4E4E4]"
            defaultValue={user_state.order_msg}
            onBlur={(e) => pleaseDo({ type: "order_msg", order_msg: e.target.value })}
          />
        </div>
      )}
      <hr className="border-[#DFDFDF]" />
    </>
  );
};

export default function PurchasePage() {
  const { pid, type } = useParams();
  let item_type = type;
  if (type === "coil" || type === "pod") item_type = "acc";

  const nav = useNavigate();
  const { state } = useLocation();
  const user_info = useSelector((state) => state.user_info.data);

  const [item, setItem] = useState({
    item_id: "",
    item_company: "",
    item_name: "",
    item_price: 0,
    item_img: "",
    quantity: 1,
  });

  const purchase_quantity = state ? state.purchase_quantity : 1;
  const option = state && state.item_option ? state.item_option : item && item.options ? item.options[0] : null;

  useEffect(() => {
    // 데이터를 받아오는 동안 시간이 소요되므로 await 로 대기
    Axios.get(`${process.env.REACT_APP_FRONT_URL}/api/item/${item_type}?item_id=VA_${type[0].toUpperCase()}${pid.padStart(5, "0")}`)
      .then((res) => setItem(res.data[0]))
      .catch((e) => console.error(e));
  }, [item_type, pid, type]);

  const agreeList = [
    ["(필수) 개인정보 수집/이용 동의", "/my", "red"],
    ["(필수) 개인정보 제3자 제공 동의", "/shop", "orange"],
  ];

  const pow2 = (exp) => Math.pow(2, exp);
  const select_all = pow2(agreeList.length) - 1;

  const [use_point, setUse] = useState(false);
  const [point_amount, setAmount] = useState(0);

  const [agree, setAgree] = useState(0);

  // 필수 입력사항의 입력 여부를 체크하는 배열이고,
  // 배송 메시지는 필수 사항이 아니므로, 이 배열에 넣을 필요 없음
  const inputList = ["성함", "연락처", "주소1", "주소2"];
  const valid_all = pow2(inputList.length) - 1;

  const initState = {
    valid: 0,
    tab: 0,
    name: "",
    contact: "",
    post_search: "",
    post_input: "",
    order_msg: "",
  };

  const sureThing = (state, action) => {
    switch (action.type) {
      case "valid":
        return {
          ...state,
          valid: !action.valid ? state.valid & (valid_all - pow2(action.bit)) : state.valid | pow2(action.bit),
        };
      case "tab":
        return { ...state, tab: action.tab };
      case "name":
        return { ...state, name: action.name };
      case "contact":
        return { ...state, contact: action.contact };
      case "post_search":
        return { ...state, post_search: action.post_search };
      case "post_input":
        return { ...state, post_input: action.post_input };
      case "order_msg":
        return { ...state, order_msg: action.order_msg };
      default:
    }
  };
  const [user_state, pleaseDo] = useReducer(sureThing, initState);

  const total_price =
    (item.discount_price > 0 ? item.discount_price : item.item_price) * purchase_quantity >= 50000
      ? (item.discount_price > 0 ? item.discount_price : item.item_price) * purchase_quantity
      : (item.discount_price > 0 ? item.discount_price : item.item_price) * purchase_quantity + 3000;

  return (
    <>
      <div className="mt-[18px] pb-[32px] mx-[16px] tracking-[-5%]">
        <AddressModal pleaseDo={pleaseDo} user_info={user_info} user_state={user_state} />

        <div className="my-[20px] text-left">
          <p className="font-medium">주문 정보</p>
          <div className="mt-[20px] flex">
            <p className="w-[80px] h-[84px] bg-[#F5F5F5] flex flex-col justify-center items-center">
              {item.item_img.length ? (
                <img
                  src={process.env.REACT_APP_IMAGE_BASE + item.item_img + `?timestamp=${new Date().getTime()}&w=256&q=75&f=webp`}
                  width={70}
                  alt="주문 품목"
                />
              ) : null}
            </p>
            <div className="ml-[21px]">
              <p className="font-medium">{item.item_name}</p>
              {option ? <p className="text-12px">{option}</p> : null}
              <p className="mt-[5px] text-12px text-[#2454FF]">
                {(item.discount_price > 0 ? item.discount_price : item.item_price).toLocaleString("ko-KR")}원 / 수량&nbsp;
                {purchase_quantity}개
              </p>
            </div>
          </div>
        </div>
        <hr className="border-[#DFDFDF]" />

        <div className="my-[20px] text-left">
          <p className="font-medium">적립금</p>
          <div className="mt-[20px]">
            <form
              className="flex justify-between"
              onSubmit={(e) => {
                e.preventDefault();
                setUse((cur) => !cur);
              }}
            >
              <input
                className="w-3/5 min-w-[220px] h-[44px] pl-[12px] text-14px border border-[#E4E4E4] self-center"
                type={!use_point ? "number" : null}
                value={!use_point ? point_amount : `${point_amount.toLocaleString("ko-KR")}p`}
                disabled={use_point}
                max={user_info.user_points > total_price ? total_price : user_info.user_points}
                onChange={(e) => {
                  let tmp_point_amount = parseInt(e.target.value);
                  if (tmp_point_amount >= total_price) tmp_point_amount = total_price;
                  if (tmp_point_amount >= user_info.user_points) tmp_point_amount = user_info.user_points;
                  if (tmp_point_amount < 0) tmp_point_amount = 0;
                  e.target.value = tmp_point_amount;
                  setAmount(tmp_point_amount);
                }}
              />
              {!use_point ? (
                !point_amount ? (
                  <button
                    className="w-[98px] h-[44px] text-14px bg-[#F3F3F3] self-center"
                    onClick={() => {
                      setAmount(total_price < user_info.user_points ? total_price : user_info.user_points);
                    }}
                  >
                    모두 사용
                  </button>
                ) : (
                  <button className="w-[98px] h-[44px] text-14px bg-[#F3F3F3] self-center">사용하기</button>
                )
              ) : (
                <button className="w-[98px] h-[44px] text-14px bg-[#F3F3F3] self-center">취소</button>
              )}
            </form>
          </div>
          <p className="mt-[10px] text-12px">
            보유 적립금 <span className="font-medium">{user_info.user_points.toLocaleString("ko-KR")}p</span>
          </p>
        </div>
        <hr className="border-[#DFDFDF]" />

        <div className="my-[20px]">
          <p className="font-medium text-left">결제 방법</p>
        </div>
        <hr className="border-[#DFDFDF]" />

        <div className="my-[20px] text-left">
          <p onClick={() => setAgree((cur) => (cur === select_all ? 0 : select_all))}>
            {agree === select_all ? (
              <img src="/imageDB/common/checked.svg" width={16} className="inline" alt="checked" />
            ) : (
              <img src="/imageDB/common/unchecked.svg" width={16} className="inline" alt="unchecked" />
            )}
            <span className="ml-[12px] text-14px">주문 내용을 확인했으며, 아래 내용에 모두 동의합니다.</span>
          </p>
          {agreeList.map((elem, itr) => (
            <p className="ml-[28px]" key={`개인정보약관_${itr + 1}`}>
              <span
                onClick={() => {
                  setAgree((cur) => (cur & pow2(itr) ? cur & (select_all - pow2(itr)) : cur | pow2(itr)));
                }}
              >
                {agree & pow2(itr) ? (
                  <img src="/imageDB/common/checked.svg" width={16} className="inline" alt="checked" />
                ) : (
                  <img src="/imageDB/common/unchecked.svg" width={16} className="inline" alt="unchecked" />
                )}
                <span className="text-12px text-[#747474]">
                  <span className="ml-[12px]">{elem[0]}</span>
                  <button
                    className={`px-[5px] text-${elem[2]}-400 underline`}
                    onClick={(e) => {
                      e.stopPropagation();
                      nav(elem[1]);
                    }}
                  >
                    보기
                  </button>
                </span>
              </span>
            </p>
          ))}
        </div>
      </div>

      <p
        className={`w-full min-w-[360px] h-[77px] pt-[16px] font-bold text-white ${
          agree === select_all && (!user_state.tab || user_state.valid === valid_all) ? "bg-black" : "bg-[#999999]"
        } fixed bottom-0 z-20`}
        onClick={() => {
          if (agree === select_all) {
            !user_state.tab
              ? onClickPayment({
                  user_data: {
                    user_id: user_info.user_id,
                    orderer_name: user_info.user_nickname,
                    tel: user_info.user_contact,
                    addr: `${user_info.user_addr1} ${user_info.user_addr2}`.substring(8),
                    postal_code: `${user_info.user_addr1} ${user_info.user_addr2}`.substring(1, 6),
                    user_def_order_msg: user_info.user_def_order_msg,
                    user_adult: user_info.user_adult,
                  },
                  items: [{ ...item, quantity: purchase_quantity, item_option: option }],
                  point_used: use_point ? point_amount : 0,
                  total_price,
                })
              : user_state.valid === valid_all
              ? // TODO: 아래 배송 메시지 관련 변수
                onClickPayment({
                  user_data: {
                    user_id: user_info.user_id,
                    orderer_name: user_state.name,
                    tel: user_state.contact,
                    addr: `${user_state.post_search} ${user_state.post_input}`.substring(8),
                    postal_code: `${user_info.user_addr1} ${user_info.user_addr2}`.substring(1, 6),
                    user_def_order_msg: user_state.order_msg,
                    user_adult: user_info.user_adult,
                  },
                  items: [{ ...item, quantity: purchase_quantity }],
                  point_used: use_point ? point_amount : 0,
                  total_price,
                })
              : alert("주문자 정보를 모두 입력해주세요.");
          } else alert("주문 약관에 모두 동의해주세요.");
        }}
      >
        {(total_price - (use_point ? point_amount : 0)).toLocaleString("ko-KR")}원 결제하기
      </p>
      <div className="h-12" />
    </>
  );
}
