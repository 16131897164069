import "./css/Animation.css";
import "./css/App.css";
import "./css/Slide.css";
import "./css/Text.css";

import axios from "axios";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "./components/common/ScrollToTop";

import { fetchUser } from "./redux/module/user_info";
import { fetchFavs, initFav } from "./redux/module/item_fav";
import { fetchBanner } from "./redux/module/banner";

import Header from "./components/Header/Header";
import BottomNav from "./components/BottomNav";
import { AskMeAnything } from "./components/common/AskMeAnything";

// import Landing from "./view/Landing";

import MainPage from "./view/MainPage";
import History from "./view/History";

import MyPage from "./view/MyPage.js";
import MyAvatar from "./components/MyPage/MyAvatar";
import MyAccount from "./components/MyPage/MyAccount";
import MyDev from "./components/MyPage/MyDev";
import MyDevAdd from "./components/MyPage/MyDevAdd";
import MyShipping from "./components/MyPage/MyShipping";
import MyShippingDetail from "./components/MyPage/MyShippingDetail";

import ShopLobby from "./view/ShopLobby";
import ShopOutline from "./view/ShopOutline";

import PurchasePage from "./components/Purchase/PurchasePage";
import AddressChangePage from "./components/Purchase/AddressChangePage";
import DetailPage from "./components/ShoppingPage/DetailPage";
import ReviewPage from "./components/ShoppingPage/ReviewPage";

//로그인
import Login from "./view/Kakao/LoginPage";

import Test from "./view/test/test";
import CurationItems from "./components/MainPage/CurationItems";

import MyDevList from "./components/MyPage/MyDevList";

import ShopInner from "./components/ShoppingPage/ShopInner";
import ShopAll from "./components/ShoppingPage/All/ShopAll";
import ShopLiquid from "./components/ShoppingPage/Liquid/ShopLiquid";
import ShopMachine from "./components/ShoppingPage/Machine/ShopMachine";
import ShopAcc from "./components/ShoppingPage/Accessory/ShopAcc";
// import ShopFlash from "./components/ShoppingPage/FlashSale/ShopFlash";

import { Banner } from "./components/common/Banner";

import { initMachine } from "./redux/module/item_machine";
import { initCart } from "./redux/module/item_cart";
import MyInvitations from "./components/MyPage/MyInvitations";

//export const user_ay_dee = "U_100001";
export const { IMP } = window;

export default function App() {
  const dispatch = useDispatch();

  //아래 두 개는 리덕스로 가면 좋을 것..
  const user_info = useSelector((state) => state.user_info.data);
  //const [user, setUser] = useState(null);

  useEffect(() => {
    if (user_info.user_id === "") {
      //console.log("안그인");
      dispatch(initFav());
      dispatch(initMachine());
      dispatch(initCart());
    }
  }, [dispatch, user_info]);

  // DB 가져오기 + Iamport init
  useEffect(() => {
    IMP.init(process.env.REACT_APP_IMP_CODE);
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_FRONT_URL}/api/login/success`).then((res) => {
      if (res.data !== null && res.data.user) {
        //console.log("로그인");
        dispatch(fetchUser(res.data.user.uid));
        dispatch(fetchFavs(res.data.user.uid));
      }
    });

    dispatch(fetchBanner());
  }, [dispatch]);
  // DB 가져오기 끝

  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
  useEffect(() => {
    setScreenSize();
  });

  const [switchAskMe, setSwitchAskme] = useState(false);
  const [showAskMe, setShowAskme] = useState(true);
  const [removeAskMe, setRemoveAskMe] = useState(false);
  useEffect(() => {
    if (switchAskMe) setTimeout(() => setShowAskme(false), 3000);
  }, [switchAskMe]);
  useEffect(() => {
    if (!showAskMe) setTimeout(() => setRemoveAskMe(true), 1000);
  }, [showAskMe]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="App bg-white w-full mx-auto unselectable">
        <Routes>
          <Route path="/kakaoLogin" element={<Login />} />
          <Route
            // path=""
            element={
              <>
                <Header />
                <Outlet />
                <BottomNav />
                <AskMeAnything show={showAskMe} remove={removeAskMe} />
              </>
            }
          >
            <Route index path="/" element={<MainPage setSwitchAskme={setSwitchAskme} />} />
            <Route path="/shop" element={<ShopLobby setSwitchAskme={setSwitchAskme} />} />
            <Route path="/my" element={<MyPage setSwitchAskme={setSwitchAskme} />} />
          </Route>

          <Route
            path=""
            element={
              <>
                <Header />
                <Outlet />
              </>
            }
          >
            <Route path="/detail/:type/:pid/" element={<DetailPage />} />
            <Route path="/my/avatar" element={<MyAvatar />} />
            <Route path="/my/account" element={<MyAccount />} />
            <Route path="/my/dev" element={<MyDev />} />
            <Route path="/my/dev/list" element={<MyDevList />} />
            <Route path="/my/dev/add" element={<MyDevAdd />} />
            <Route path="/my/shipping" element={<MyShipping />} />
            <Route path="/my/shipping/detail" element={<MyShippingDetail />} />
            <Route path="/my/invitations" element={<MyInvitations />} />
            <Route path="/purchase/:type/:pid" element={<PurchasePage />} />
            <Route path="/purchase/change" element={<AddressChangePage />} />
            <Route path="/banner/:id" element={<Banner />} />
          </Route>

          <Route
            path=""
            element={
              <>
                <Header />
                <Outlet />
                <BottomNav />
              </>
            }
          >
            {/* <Route path="/landing" element={<Landing />} />
            <Route path="/landing/:page" element={<Landing />} /> */}
            <Route path="/history" element={<History />} />
            <Route path="/shop" element={<ShopOutline />}>
              <Route path=":page" element={<ShopAll />} />
              <Route path="all" element={<ShopInner />}>
                <Route path=":page" element={<ShopAll />} />
              </Route>
              <Route path="liquid" element={<ShopInner />}>
                <Route path=":page" element={<ShopLiquid />} />
              </Route>
              <Route path="machine" element={<ShopInner />}>
                <Route path=":page" element={<ShopMachine />} />
              </Route>
              <Route path="accessory" element={<ShopInner />}>
                <Route path=":page" element={<ShopAcc />} />
              </Route>
              {/* <Route path="flashsale" element={<ShopInner />}>
                                <Route path=":page" element={<ShopFlash />} />
                            </Route> */}
            </Route>
            <Route path="/curation" element={<CurationItems />} />
            {/* <Route path="/shop/starter" element={<Shop />} /> */}
            <Route path="/review/:type/:pid" element={<ReviewPage />} />

            <Route path="/testset" element={<Test />} />

            <Route path="*" element={<h2 className="pt-[32px]">해당 페이지는 존재하지 않습니다.</h2>} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}
