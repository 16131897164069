import { memo, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";

import MyAndo from "../components/MainPage/MyAndo";
import Basket from "../components/MainPage/Basket";
import BannerHome from "../components/MainPage/BannerHome";
import CurationLiq from "../components/MainPage/Curation_Liq";
import Footer from "../components/Footer/Footer";
import Meta from "../components/Meta";
import RollingText from "../components/BlackFriday/RollingText";
// import CountdownTimer from "../components/BlackFriday/CountDown";

import { useLocation, useNavigate } from "react-router-dom";

import Axios from "axios";
import { setBasket } from "../redux/module/item_cart";
import { Link } from "react-router-dom";
// import CurationTip from "../components/MainPage/Curation_Tip";

const MainPage = ({ setSwitchAskme }) => {
  const nav = useNavigate();
  const ref = useRef(null);
  const loc = useLocation();
  const ref2 = useRef(null);
  const [imagesLoaded, setImagesLoaded] = useState(true);

  const handleClick = () => {
    const offset = -70; // Adjust this value as needed

    const element = ref.current;
    if (element) {
      const topPosition = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: topPosition + offset,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const path = sessionStorage.getItem("path");
    sessionStorage.clear();
    if (path) {
      nav(path);
    }
  }, [nav]);
  useEffect(() => {
    if (loc.hash === "#basket" && imagesLoaded) {
      const offset = -70;

      const element = ref2.current;
      console.log(element);
      if (element) {
        const topPosition = element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: topPosition + offset,
          behavior: "smooth",
        });
      }
    }
  }, [loc.hash, imagesLoaded]);

  useEffect(() => {
    setSwitchAskme(true);
  }, [setSwitchAskme]);

  const dispatch = useDispatch();
  const [curItems, setItems] = useState([]);
  useEffect(() => {
    // 데이터를 받아오는 동안 시간이 소요되므로 await 로 대기
    Axios.get(process.env.REACT_APP_SERVER_URL + "/api/curations")
      .then((res) => setItems(res.data))
      .catch(() => console.log("큐레이션 받아오기 오류"));
    return () => dispatch(setBasket(false));
  }, [dispatch]);

  return (
    <>
      <Link to="/shop/all/1" />
      <Link to="/shop/machine/1" />
      <Link to="/shop/liquid/1" />
      <Link to="/shop/accessory/1" />
      {/* <Link to="/shop/flashsale/1" /> */}
      <div
        //메인 페이지 구역
        className="overflow-hidden"
      >
        <Meta
          title="안도 전자담배"
          siteUrl="https://andocompany.com"
          desc="내 맘에 쏙 드는 전담 쇼핑!"
          tags={[
            "전자담배",
            "전자담배 액상",
            "전자담배 추천",
            "일회용 전자담배",
            " 전자담배 기기",
            " 궐련형 전자담배",
            "젤로 전자담배",
            "발라리안",
            " 편의점 전자담배",
            " 전자담배 기기 추천",
          ]}
        />
        <BannerHome />
        <RollingText />
        {/* <CountdownTimer /> */}
        <div>
          <img
            src="/imageDB/event/ny_banner.png"
            onLoad={() => {
              setImagesLoaded(true);
            }}
            alt="신년이벤트"
            style={{ width: "100%" }}
          />
          <img
            className="bf-banner"
            src="/imageDB/event/ny_button.png"
            alt="신년이벤트"
            style={{ display: "inline-block", padding: "0 16px", marginTop: "-46%", width: "100%" }}
            onClick={handleClick}
          />
        </div>
        <MyAndo /> {/* 내 기기, 부품, 액상. 즉 장바구니 윗쪽. */}
        <div ref={ref2} style={{ visibility: "hidden" }} />
        <Basket /> {/* 장바구니 */}
        {/* <RollingText /> */}
        {curItems && curItems.length > 0
          ? curItems.map((it, i) => (
              <div key={"Cur_" + i} ref={i === 0 ? ref : null}>
                <CurationLiq parameters={it} />
                {i !== curItems.length - 1 ? <hr className="my-[40px]" /> : null}
              </div>
            ))
          : null}
        <Footer />
        {/* <CurationTip />
        <hr /> */}
      </div>
    </>
  );
};

export default memo(MainPage);
