import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/bundle";

// import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import Axios from "axios";

import addDev from "./images/addDev.svg";

export default function MyDevFlow({ back_blur, setIndex, handleAcc, handleLiq }) {
  const nav = useNavigate();
  const machine_infos = useSelector((state) => state.item_machine.save_machine_infos);
  const loading = useSelector((state) => state.item_machine.loading);

  const [temp_machine, setTempM] = useState(null);

  useEffect(() => {
    if (!loading && !machine_infos.length)
      Axios.get(`${process.env.REACT_APP_FRONT_URL}/api/item/machine?item_id=VA_M00001`).then((res) => setTempM(res.data[0]));
  }, [loading, machine_infos.length]);

  const [show_button, setShow] = useState(true);

  return (
    <div
      className={back_blur ? "blur-[2px]" : "h-[384px]"}
      onClick={() => {
        if (back_blur) {
          handleAcc(false);
          handleLiq(false);
        }
      }}
    >
      {machine_infos.length ? (
        <Swiper
          navigation={true}
          pagination={true}
          onSliderFirstMove={() => {
            if (machine_infos.length !== 1) setShow(false);
          }}
          onRealIndexChange={(swiper) => setIndex(swiper.realIndex)}
          onTransitionEnd={(swiper) => setShow(!swiper.realIndex)}
          modules={[Navigation, Pagination]}
          className="Dev"
        >
          {machine_infos.map((elem, itr) => (
            <SwiperSlide key={`saved_machine_${itr}`}>
              <div className="top-[40px] right-[16px] text-14px absolute flex">
                <p className="font-light break-all w-3">{machine_infos[itr].item_name}</p>
                <p className="font-medium ml-3 break-all w-3">{machine_infos[itr].item_company}</p>
              </div>
              <p className="w-[360px] h-[360px] mb-[22px] flex">
                <img
                  src={process.env.REACT_APP_IMAGE_BASE + machine_infos[itr].item_img + `?timestamp=${new Date().getTime()}&w=256&q=75&f=webp`}
                  className="self-center"
                  alt={`저장한 기기 ${itr + 1}`}
                  onClick={() => nav("/my/dev/list")}
                />
              </p>
              {show_button ? <img src={addDev} className="absolute left-1/2 translate-x-[-120px]" onClick={() => nav("/my/dev/add")} alt="기기 추가" /> : null}
            </SwiperSlide>
          ))}
        </Swiper>
      ) : loading ? (
        <div className="h-[282px] flex flex-col justify-center">
          <p>나의 기기 로드 중...</p>
        </div>
      ) : temp_machine ? (
        <Swiper navigation={true} pagination={true} modules={[Navigation, Pagination]} className="Dev">
          <SwiperSlide key="temp_machine" className="relative">
            <div className="top-[40px] right-[16px] text-14px absolute flex">
              <p className="font-light break-all w-4">{temp_machine.item_name}</p>
              <p className="font-medium ml-1 break-all w-[0.75rem]">{temp_machine.item_company}</p>
            </div>
            <p className="w-[360px] h-[360px] mt-[2px] mb-[22px] flex">
              <img
                src={process.env.REACT_APP_IMAGE_BASE + temp_machine.item_img + `?timestamp=${new Date().getTime()}&w=256&q=75&f=webp`}
                className="mx-auto w-full self-center"
                alt="예시 기기"
              />
            </p>
          </SwiperSlide>
        </Swiper>
      ) : (
        <p className="h-[282px] flex flex-col justify-center" />
      )}
    </div>
  );
}
