import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  initUser,
  setUserImage,
  setUserNickname,
} from "../redux/module/user_info";
import axios from "axios";

export default function MyPage({ setSwitchAskme }) {
  useEffect(() => {
    setSwitchAskme(true);
  }, [setSwitchAskme]);

  const dispatch = useDispatch();
  const nav = useNavigate();
  const user_info = useSelector((state) => state.user_info.data);

  const [nick_text, setNickText] = useState(user_info.user_nickname);
  const [toggle_shadow, setShadow] = useState(false);
  const [toggle_nickname, setNickname] = useState(false);

  const inputRef = useRef();
  useEffect(() => {
    if (toggle_nickname) inputRef.current.focus();
  }, [toggle_nickname]);

  useEffect(() => {
    setNickText(user_info.user_nickname);
  }, [user_info]);

  const logout = () => {
    axios.get(`${process.env.REACT_APP_FRONT_URL}/api/logout`).then(() => {
      dispatch(initUser());
      nav("/");
    });
  };

  const nicknameChanger = (input_str) => {
    const regex = /^[ㄱ-ㅎ가-힣ㅏ-ㅣa-zA-Z0-9]{1,12}$/;
    if (regex.test(input_str)) {
      //닉네임 변경 로직
      dispatch(setUserNickname(input_str));
      setNickname(false);
    } else
      alert(
        "닉네임은 한글,영문,숫자로 작성 가능합니다. 최소 1글자 이상, 최대 12글자까지 지원되며 특수문자 및 띄어쓰기는 금지되어 있습니다."
      );
  };

  return (
    <div className="pt-[20px] pb-[16px]">
      {toggle_shadow ? (
        <>
          <p
            // 음영
            className="absolute top-[0px] w-full min-w-[360px] h-screen bg-[rgba(0,0,0)]/50 z-[22]"
            onClick={(e) => {
              e.stopPropagation();
              setShadow(false);
            }}
          />

          <div className="bg-white w-full min-w-[360px] h-auto rounded-t-[20px] px-[26px] fixed text-left z-[22] bottom-0">
            <p className="mt-[18px] py-[8px]" onClick={() => nav("avatar")}>
              아바타 선택하기
            </p>
            <hr className="mt-[18px] border-[#B3B3B3]" />
            <p
              className="mt-[18px] py-[8px] text-[#FF0000]"
              onClick={() => {
                dispatch(setUserImage([0, 0]));
                setShadow(false);
              }}
            >
              현재 사진 삭제
            </p>
            <p className="mb-[48px]" />
          </div>
        </>
      ) : null}

      <div className="flex justify-end">
        {user_info.user_nickname !== "" && (
          <p
            className="text-right w-fit text-gray-400 underline pr-5 pb-4 text-sm"
            onClick={logout}
          >
            로그아웃
          </p>
        )}
      </div>
      <div className="w-[171px] h-[171px] rounded-full mx-auto relative overflow-hidden">
        <img
          src={
            user_info.user_img[0] > 0
              ? `imageDB/my_page/profile_picture/${`${user_info.user_img[0]}`}-${`${user_info.user_img[1]}`.padStart(
                  2,
                  "0"
                )}.png`
              : "/imageDB/my_page/profile_picture/default.png"
          }
          width={171}
          className="mx-auto"
          alt="프로필 이미지"
        />
        {user_info.user_id ? (
          <div className="w-[171px] absolute bottom-0 h-[29px] bg-[#1D1D1D]/60 flex flex-col justify-center">
            <p
              className="text-white text-12px"
              onClick={() => {
                if (user_info.user_img[0]) setShadow(true);
                else nav("avatar");
              }}
            >
              편집
            </p>
          </div>
        ) : null}
      </div>

      {!toggle_nickname ? (
        <div className="flex justify-center mt-[16px] font-bold text-[28px]">
          <p>
            {user_info.user_nickname ? `${user_info.user_nickname}님` : "손님"}
          </p>
          {user_info.user_nickname && (
            <img
              className="ml-1 w-8 h-8 self-center"
              //@@@@@ 지금 커런트가 안 먹고 있음. 아마 없는 컴포넌트라 그런 것 같음. 순서변경 방법?
              onClick={() => setNickname(true)}
              src={"/imageDB/common/write.svg"}
              alt="수정"
            />
          )}
        </div>
      ) : (
        <form
          className="flex justify-center mt-[16px] font-bold text-[28px]"
          onSubmit={(e) => {
            e.preventDefault();
            nicknameChanger(inputRef.current.value);
          }}
        >
          <input
            ref={inputRef}
            className="max-w-xs border-3 px-2 border-gray-700 rounded-lg"
            defaultValue={nick_text}
          />
          <button className="ml-1">
            <img
              className="self-center"
              width={32}
              height={32}
              src={"/imageDB/common/write.svg"}
              alt="수정"
            />
          </button>
        </form>
      )}

      <div className="bg-[#A85E1C] mx-[16px] h-[142px] rounded-t-[15px] mt-[36px] relative">
        <img
          src="/imageDB/my_page/point_coin.svg"
          className="absolute left-[20px] top-[18px]"
          alt="Point_Coin"
        />
        <div className="absolute top-[20px] right-[20px] text-white">
          <p className="text-12px">지금까지 모은 적립금</p>
          <p className="-mt-[6px] text-[26px] text-right font-bold">
            {user_info.user_points.toLocaleString("ko-KR")}P
          </p>
        </div>
        <p className="absolute bottom-0 w-full h-[64px] rounded-t-[20px] bg-[#B86B24] shadow-[0_-8px_11px_rgba(0,0,0,0.14)]" />
        <p className="absolute bottom-0 w-full h-[33px] rounded-t-[20px] bg-[#B86B24] shadow-[0_-8px_11px_rgba(0,0,0,0.14)]" />
      </div>

      <div className="mx-[20px]">
        <div className="mt-[36px] text-left">
          <p className="font-medium text-[20px]">배송</p>
          <div
            className="mt-[20px] flex justify-between"
            onClick={() => {
              if (user_info.user_id) nav("shipping");
              else alert("로그인 후에 이용하실 수 있습니다.");
            }}
          >
            <p className="mx-[1px]">배송 현황 관리</p>
            <img
              src="imageDB/shop_common/Chevron_Right_8_14_C4C4C4_1.5.svg"
              alt="Right"
            />
          </div>
          <hr className="border-[#EBEBEB] mt-[16px]" />
        </div>
        <div className="mt-[37px] text-left">
          <p className="font-medium text-[20px] pb-[4px]">관리</p>
          {[
            { str: "개인정보 수정", URI: "account" },
            { str: "기기 관리", URI: "dev" },
            { str: "추천인", URI: "invitations" },
            // { str: "결제 정보 관리", URI: "payment" },
          ].map((elem, itr, arr) => (
            <Fragment key={`관리 메뉴 ${itr + 1}`}>
              <div
                className="mt-[16px] flex justify-between"
                onClick={() => {
                  if (user_info.user_id) nav(elem.URI);
                  else alert("로그인 후에 이용하실 수 있습니다.");
                }}
              >
                <p className="mx-[1px]">{elem.str}</p>
                <img
                  src="imageDB/shop_common/Chevron_Right_8_14_C4C4C4_1.5.svg"
                  alt="Right"
                />
              </div>
              {itr !== arr.length - 1 ? (
                <hr className="border-[#EBEBEB] mt-[16px]" />
              ) : null}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}
