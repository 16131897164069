import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";

const fetchUser = createAsyncThunk("user_info/fetchUser", () =>
  Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/user/private`)
    .then((res) => res.data)
    .catch(() => console.log("DB 통신 오류U"))
);

const setUserInfo = createAsyncThunk("user_info/setUserInfo", (user_info) =>
  Axios.post(`${process.env.REACT_APP_SERVER_URL}/api/user/private`, user_info)
    .then((res) => res.data)
    .catch((e) => ({ NG: e.response.data }))
);

const setUserNickname = createAsyncThunk(
  "user_info/setUserNickname",
  (nickname) =>
    Axios.post(`${process.env.REACT_APP_SERVER_URL}/api/user/nickname`, {
      nickname,
    })
      .then((res) => res.data)
      .catch((e) => ({ NG: e.response.data }))
);

const initU = {
  user_id: "",
  //user_login_id: "", //가려야함
  //user_name: "",
  user_nickname: "",
  user_addr1: "",
  user_addr2: "",
  user_contact: "",
  //user_sex: 0,
  //user_age: 0,
  //user_signin_date: "",
  //user_validate: "",
  user_adult: "",
  user_grade: "",
  user_points: 0,
  user_img: [0, 0],
  //user_memo: "",
  user_status: "",
  user_def_order_msg: "",
};

const user_info = createSlice({
  name: "user_info",
  initialState: {
    data: {
      ...initU,
    },
  },
  reducers: {
    initUser: () => ({ data: { ...initU } }),
    setUserImage: (state, action) => {
      state.data.user_img = action.payload;
      Axios.post(`${process.env.REACT_APP_SERVER_URL}/api/user/image`, {
        user_image: action.payload,
      });
      return state;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        // 데이터를 못 받아왔을 때를 위한 fallback
        if (action.payload)
          if (action.payload.length) state.data = action.payload[0];
      })
      .addCase(setUserInfo.fulfilled, (state, action) => {
        //console.log(action.payload);
        if (action.payload && !action.payload.NG)
          state.data = { ...state.data, ...action.payload };
        else {
          if (action.payload.NG === "dup")
            alert("이미 사용 중인 닉네임입니다!");
          else alert("예상치 못한 오류로 닉네임 변경에 실패했습니다.");
        }
      })
      .addCase(setUserNickname.fulfilled, (state, action) => {
        if (!action.payload.NG) state.data.user_nickname = action.payload;
        else {
          if (action.payload.NG === "dup")
            alert("이미 사용 중인 닉네임입니다!");
          else alert("예상치 못한 오류로 닉네임 변경에 실패했습니다.");
        }
      });
  },
});

export { fetchUser, setUserInfo, setUserNickname, user_info };
export const { initUser, setUserImage } = user_info.actions;
