import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const BottomNav = () => {
  const href = window.location.href;
  const nav = useNavigate();
  //const user = useSelector((state) => state.user_info);
  const usr_img = useSelector((state) => state.user_info.data.user_img);
  const user = useSelector((state) => state.user_info.data);
  //console.log("user", user);

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <p className="bottom-0 h-[55px]" />
      {/* <footer className="fixed bottom-0 inset-x-0 mx-auto bg-white w-full min-w-[360px] h-[55px] z-20"> */}
      <footer
        style={{
          position: "fixed",
          width: "100%",
          height: "55px",
          bottom: 0, // 추가: 화면 하단에 고정
          zIndex: 1000, // 추가: 다른 요소 위에 표시되도록 z-index 설정
          backgroundColor: "white",
        }}
      >
        <hr className="border-[#DBDBDB]" />
        <div className="flex">
          <p className="w-1/6" />
          <div className="w-1/6 relative">
            <div
              className="absolute -left-[12px] px-[12px] py-[12px]"
              onClick={() => {
                sessionStorage.clear();
                nav("/");
              }}
            >
              <img
                src={
                  href.endsWith(process.env.REACT_APP_FRONT_URL) || href.endsWith(`${process.env.REACT_APP_FRONT_URL}/`)
                    ? "/imageDB/bottom_nav/home_sel.svg"
                    : "/imageDB/bottom_nav/home_unsel.svg"
                }
                width={18}
                alt="홈"
              />
              <p
                className={`text-[9px] ${
                  href.endsWith(process.env.REACT_APP_FRONT_URL) || href.endsWith(`${process.env.REACT_APP_FRONT_URL}/`) ? "" : "text-[#C4C4C4]"
                }`}
              >
                홈
              </p>
            </div>
          </div>

          <div className="w-1/3">
            <div
              className="w-fit mx-auto px-[8px] py-[7px]"
              onClick={() => {
                sessionStorage.clear();
                nav("/shop");
              }}
            >
              <img src={href.includes("/shop") ? "/imageDB/bottom_nav/bag_sel.svg" : "/imageDB/bottom_nav/bag_unsel.svg"} width={24} alt="샵" />
              {/* <img src={href.includes("/shop") ? "/imageDB/event/blackfriday_shop.svg" : "/imageDB/event/blackfriday_shop.svg"} width={24} alt="샵" /> */}
              <p
                className={`text-[9px] 
            ${href.includes("/shop") ? "" : "text-[#C4C4C4]"}`}
              >
                샵
              </p>
            </div>
          </div>

          <div className="w-1/6 relative">
            {usr_img[0] > 0 ? (
              <div
                className="absolute -right-[20px] px-[8px] py-[7px]"
                onClick={() => {
                  //console.log(user);
                  if (user.user_id !== "") nav("/my");
                  else nav("/kakaoLogin");
                }}
              >
                <div className="relative">
                  <img
                    src={`/imageDB/my_page/profile_picture/${`${usr_img[0]}`}-${`${usr_img[1]}`.padStart(2, "0")}.png`}
                    width={24}
                    className="mx-auto"
                    alt="마이 페이지"
                  />
                  {href.includes("/my") ? <p className="w-[24px] h-[24px] rounded-full border border-black absolute top-0 inset-x-0 mx-auto z-10" /> : null}
                </div>
                <p className={`text-[9px] ${href.includes("/my") ? "" : "text-[#C4C4C4]"}`}>마이 페이지</p>
              </div>
            ) : (
              <div
                className="absolute -right-[20px] px-[8px] py-[7px]"
                onClick={() => {
                  //console.log(user);
                  if (user.user_id !== "") nav("/my");
                  else nav("/kakaoLogin");
                }}
              >
                <img src="/imageDB/my_page/profile_picture/default.png" width={24} className="mx-auto" alt="기본 프로필" />
                {/* html로 구현한 기본 프사 */}
                {/* <div className="w-[24px] h-[24px] rounded-full bg-[#C6D8EA] ml-auto relative">
                {href.includes("/my") ? (
                  <p className="w-[24px] h-[24px] rounded-full border border-black absolute z-10"></p>
                ) : null}
                <p className="w-[10px] h-[10px] rounded-full bg-white absolute inset-x-0 mx-auto top-[3px]"></p>
                <p className="w-[20px] h-[20px] rounded-full bg-white absolute inset-x-0 mx-auto top-[15px]"></p>
              </div> */}
                <p className={`text-[9px] ${href.includes("/my") ? "" : "text-[#C4C4C4]"}`}>마이 페이지</p>
              </div>
            )}
          </div>
          <p className="w-1/6" />
        </div>
      </footer>
    </div>
  );
};

export default memo(BottomNav);
