import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";

const fetchBrands = createAsyncThunk("item_machine/fetchBrands", () =>
  Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/item/machine/brands`)
    .then((res) => res.data)
    .catch((err) => console.log("DB 통신 오류MB"))
);

const fetchItem = createAsyncThunk(
  "item_machine/fetchItem",
  ({ query_clause }) =>
    Axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/item/machine?${query_clause}`
    )
      .then((res) => res.data)
      .catch((err) => console.log("DB 통신 오류MI"))
);

const fetchMachineSaves = createAsyncThunk(
  "item_machine/fetchMachineSaves",
  () =>
    Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/item/machine/saves`)
      .then((res) => res.data)
      .catch((err) => console.log("DB 통신 오류MS"))
);

const initM = {
  items: [],
  items_count: 0,
  brands: [],
  save_logs: [],
  save_machine_infos: [],
  loading: true,
};

const item_machine = createSlice({
  name: "item_machine",
  initialState: {
    ...initM,
  },
  reducers: {
    initMachine: () => ({ ...initM }),
    setMachineSaves: (state, action) => ({
      ...state,
      save_machine_infos: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrands.fulfilled, (state, action) => {
        if (action.payload && action.payload.length > 0)
          state.brands = action.payload;
      })
      .addCase(fetchItem.fulfilled, (state, action) => {
        if (action.payload.length > 0) {
          if (action.payload[1].length > 0) {
            state.items_count = action.payload[0];
            state.items = action.payload[1];
          } else {
            state.items_count = 0;
            state.items = [];
          }
        }
      })
      .addCase(fetchMachineSaves.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchMachineSaves.fulfilled, (state, action) => {
        const item_saves = [],
          item_infos = [];
        if (action.payload && action.payload.length > 0)
          if (action.payload[0].length > 0 && action.payload[1].length > 0) {
            action.payload[0].map((log) => item_saves.push(log));
            action.payload[1].map((info) => item_infos.push(info));
            state.save_logs = item_saves;
            state.save_machine_infos = item_infos;
          }
        state.loading = false;
      });
  },
});

export { fetchItem, fetchBrands, fetchMachineSaves, item_machine };
export const { initMachine, setMachineSaves } = item_machine.actions;
