export default function andoAlert({ title, desc, show, callback }) {
  //제목, 설명,
  const btn_func = () => {
    if (callback) callback();
    show(false);
  };

  return (
    <div
      // 음영
      className="content-center fixed top-[0px] left-[0px] w-full h-full bg-[rgba(0,0,0)]/50 z-[22]"
    >
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[288px] h-auto rounded-[24px] px-[20px] py-[24px] text-left z-[23]">
        <div className=" text-center text-xl mt-7 ">
          <span>{title}</span>
        </div>
        {desc && (
          <div className=" text-center text-lg mt-1 mb-6">
            <span>{desc}</span>
          </div>
        )}

        <div className="flex justify-between">
          <button
            className="py-[15px] w-full bg-black rounded-[40px] text-white"
            onClick={btn_func}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
}
