import onClickPayment from "./onClickPayment";

// const {
//   error_msg, // -> 에러시 에러메시지
//   success, // true -> 성공여부
//   apply_num, //"00419182" -> ?
//   bank_name, //null -> 은행. 가상계좌시?
//   buyer_addr, //"테스트 주소"
//   buyer_email, //"test@mail.com"
//   buyer_name, //"테스트고객명"
//   buyer_postcode, //"테스트 우편번호"
//   buyer_tel, //"010-test-data"
//   card_name, //"현대카드" -> 카드사
//   card_number, //"417233*********9" -> 카드번호
//   card_quota, //0 -> 할부
//   currency, //"KRW" -> 통화
//   custom_data, //null -> ?
//   imp_uid, //"imp_453531785125" -> uid
//   merchant_uid, //"ODR_2023031424536566" -> 결제번호 (idtt)
//   name, //"팥빙수외 2 건" -> 주문 명
//   paid_amount, //320 -> 가격
//   paid_at, //1678729567
//   pay_method, //"card" -> 결제 방법
//   pg_provider, //"html5_inicis" -> pg사
//   pg_tid, //"StdpayCARDINIpayTest20230314024606509887" -> pg사 결제번호
//   pg_type, //"payment" -> ??
//   receipt_url, //"https://iniweb.inicis.com/DefaultWebApp/mall/cr/cm/mCmReceipt_head.jsp?noTid=StdpayCARDINIpayTest20230314024606509887&noMethod=1" -> 영수증
//   status, //"paid"
// } = response;
//console.log(response);

//item_id, 이름, 가격, 옵션, 수량
const testCartItems = [
  {
    item_id: "VA_L00001",
    item_name: "팥빙수",
    item_price: 200,
    item_option: "팥 많이",
    quantity: 1,
  },
  {
    item_id: "VA_C00005",
    item_name: "코일 0.25",
    item_price: 50,
    item_option: null,
    quantity: 2,
  },
  {
    item_id: "VA_P00005",
    item_name: "AVP 일체형 팟",
    item_price: 20,
    item_option: null,
    quantity: 1,
  },
];
// 코일, 팟 id 1은 DB에서 제거되었으므로 임시로 변경해놨음

export default function ImpPayment() {
  return <button onClick={() => onClickPayment({ items: testCartItems, point_used: 0 })}>결제 테스트</button>;
}
