import axios from "axios";
import { IMP } from "../../App";
import onClickCertification from "./impCert";

async function onClickPayment({ items, user_data, point_used, total_price }) {
  if (
    user_data.addr === null ||
    user_data.addr === "" ||
    user_data.postal_code === null ||
    user_data.postal_code === "" ||
    user_data.tel === null ||
    user_data.tel === ""
  ) {
    // if (user_data.addr === null || user_data.addr === "") alert("addr");
    // if (user_data.postal_code === null || user_data.postal_code === "")
    //   alert("postal_code");
    // if (user_data.tel === null || user_data.tel === "") alert("tel");
    alert("결제를 위해 회원 정보를 작성해 주세요!");
    window.location.href = process.env.REACT_APP_FRONT_URL + `/my/account`;
    return;
  } else if (user_data.user_id === "" || user_data.user_id === null) {
    window.location.href = process.env.REACT_APP_FRONT_URL + `/kakaoLogin`;
    return;
  }
  // else if (user_data.user_adult !== "TRUE") {
  //   // 다날 인증 로직
  //   //alert(user_data.user_adult);
  //   alert("결제를 위해 최초 1회 성인 인증 부탁 드립니다!");
  //   await onClickCertification({ user_data });
  // }
  else {
    const date = new Date();
    const order_id =
      "ODR_" +
      date.getFullYear().toString() +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      date.getDate().toString().padStart(2, "0") +
      date.getHours().toString().padStart(2, "0") +
      date.getMinutes().toString().padStart(2, "0") +
      date.getSeconds().toString().padStart(2, "0") +
      date.getMilliseconds().toString().padStart(3, "0");
    const orderName = items[0].item_name + (items.length > 1 ? `외 ${items.length - 1} 건` : "");

    // total_price를 넘겨받으므로 따로 계산할 필요가 없음
    // let orderPrice = 0;
    // items.forEach((element) => {
    //   orderPrice += element.item_price * element.quantity;
    // });
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/purchase/pre`, {
        //사전 데이터 입력. 결제 이후 데이터 변조가 있었는지를 확인하기 위함.
        order_id,
        user_data, //
        name: orderName,
        items,
        point_used,
        total_price,
      })
      .then((res, err) => {
        if (err) {
          // alert("일단 들어왔다!");
          axios.post(`${process.env.REACT_APP_SERVER_URL}/api/purchase/deletePre`, {
            order_id,
          });
          alert("결제시도중 오류가 발생했습니다!");
          return;
        } else if (res.data === "위조된 가격") {
          // alert("위조 들어왔다!");
          axios.post(`${process.env.REACT_APP_SERVER_URL}/api/purchase/deletePre`, {
            order_id,
          });
          alert("위조된 결제 시도입니다!");
          return;
        } else if (res.data === "결과 반환 실패") {
          alert("서버에 문제가 생겼습니다. 다시 시도해주세요.");
          return;
        }
        if (total_price - point_used > 0) {
          alert(`결제가 성공적으로 진행되었습니다!`);
          window.location.href = process.env.REACT_APP_FRONT_URL + "/my/shipping";
        }
        // IMP.request_pay(
        //   {
        //     pg: "html5_inicis",
        //     pay_method: "card",
        //     merchant_uid: order_id, // order_id = unique. 결제위변조에 확인해야하므로 고유값일 필요가 있음
        //     name: orderName,
        //     amount: total_price - point_used, // 가격 - 사용한 포인트
        //     // 우리한테 사용자의 메일 정보가 있다는 보장이 없음
        //     buyer_email: "", // test mail. 한글 불가.
        //     buyer_name: user_data.orderer_name,
        //     buyer_tel: user_data.tel, // must
        //     buyer_addr: user_data.addr, // addr.substring(8) -> '(우편번호) '이후
        //     buyer_postcode: user_data.postal_code, // addr.substring(1, 6) -> ('우편번호') 5자리
        //     m_redirect_url: process.env.REACT_APP_FRONT_URL,
        //   },
        //   (response) => {
        //     //콜백함수 정의
        //     if (response.success) {
        //       alert(`결제가 성공적으로 진행되었습니다!`);

        //       //nav("/my/shipping");
        //       //이것과 같은 기능을 구현해서 정의해야함.
        //       window.location.href = process.env.REACT_APP_FRONT_URL + "/my/shipping";
        //     } else {
        //       axios.post(`${process.env.REACT_APP_SERVER_URL}/api/purchase/deletePre`, {
        //         order_id,
        //       });
        //       alert(`결제 실패: ${response.error_msg}`);
        //     }
        //   },
        // );
        else {
          alert(`결제가 성공적으로 진행되었습니다!`);
          window.location.href = process.env.REACT_APP_SERVER_URL + "/my/shipping";
        }
      });
  }
}

export default onClickPayment;
