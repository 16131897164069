import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";

const fetchBrands = createAsyncThunk("item_liquid/fetchBrands", () =>
  Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/item/liquid/brands`)
    .then((res) => res.data)
    .catch(() => console.log("DB 통신 오류LB"))
);

const fetchCategory = createAsyncThunk("item_liquid/fetchCategory", () =>
  Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/item/liquid/category`)
    .then((res) => res.data)
    .catch(() => console.log("DB 통신 오류Cat"))
);

const fetchItem = createAsyncThunk(
  "item_liquid/fetchItem",
  ({ query_clause }) =>
    Axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/item/liquid?${query_clause}`
    )
      .then((res) => res.data)
      .catch(() => console.log("DB 통신 오류LI"))
);

const fetchCuration = createAsyncThunk(
  "item_liquid/fetchCuration",
  (category1) =>
    Axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/item/liquid?category1=${category1}`
    )
      .then((res) => res.data)
      .catch(() => console.log("DB 통신 오류LC"))
);

const fetchLobby = createAsyncThunk("item_liquid/fetchLobby", (query_clause) =>
  Axios.get(
    `${process.env.REACT_APP_SERVER_URL}/api/item/liquid?${query_clause}`
  )
    .then((res) => res.data)
    .catch(() => console.log("DB 통신 오류Lo"))
);

const item_liquid = createSlice({
  name: "item_liquid",
  initialState: {
    brands: [],
    category: [],
    curation_item: [],
    lobby_item: [],
    lobby_count: 0,
    shop_item: [],
    shop_count: 0,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrands.fulfilled, (state, action) => {
        if (action.payload && action.payload.length)
          state.brands = action.payload;
      })
      .addCase(fetchCategory.fulfilled, (state, action) => {
        if (action.payload && action.payload.length)
          state.category = action.payload;
      })
      .addCase(fetchCuration.fulfilled, (state, action) => {
        if (action.payload) {
          const item_curation = [];
          action.payload.map((elem) => item_curation.push(elem));
          state.curation_item = item_curation;
        }
      })
      .addCase(fetchItem.fulfilled, (state, action) => {
        if (action.payload) {
          if (action.payload[1].length) {
            state.shop_count = action.payload[0];
            state.shop_item = action.payload[1];
          } else {
            state.shop_count = 0;
            state.shop_item = [];
          }
        }
      })
      .addCase(fetchLobby.fulfilled, (state, action) => {
        if (action.payload) {
          if (action.payload[1].length) {
            state.lobby_count = action.payload[0];
            state.lobby_item = action.payload[1];
          } else {
            state.lobby_count = 0;
            state.lobby_item = [];
          }
        }
      });
  },
});

export {
  fetchBrands,
  fetchCategory,
  fetchCuration,
  fetchItem,
  fetchLobby,
  item_liquid,
};
export const { setOrder } = item_liquid.actions;
