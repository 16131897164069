import { useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { useSelector } from "react-redux";

export function BannerShop() {
  const nav = useNavigate();

  const banners = useSelector((state) => state.banner.shop);

  return (
    <Swiper
      pagination
      loop
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Pagination]}
      className="Banner"
    >
      {banners.map((banner, i) => (
        <SwiperSlide key={i}>
          <img
            src={`${process.env.REACT_APP_IMAGE_BASE}${banner.banner_thumb}?timestamp=${new Date().getTime()}`}
            alt={`샵 배너${i}`}
            onClick={() => nav(`/banner/${banner.banner_id}`)}
            className="mx-auto"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
