// Swiper 라이브러리
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow } from "swiper";

import { useDispatch, useSelector } from "react-redux";
// import "swiper/css/effect-coverflow";
import Axios from "axios";

import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchFavLiqs, setFavLiqs, setFavs } from "../../../redux/module/item_fav";
import { addCartItem, setAddIdle, setBasket, setIOstatus } from "../../../redux/module/item_cart";

const Liq = ({ handleLiq }) => {
  const dispatch = useDispatch();
  const nav = useNavigate();

  const user_info = useSelector((state) => state.user_info.data);
  const user_id = user_info.user_id;
  const user_nickname = user_info.user_nickname;

  const items = useSelector((state) => state.item_fav.favLiqs);
  const [items_ids, setItemsIds] = useState([]);
  const favs = useSelector((state) => state.item_fav.favs);
  const loading = useSelector((state) => state.item_fav.favLiq_loading);

  useEffect(() => {
    if (loading) dispatch(fetchFavLiqs());
  }, [dispatch, loading]);

  useEffect(() => {
    setItemsIds([...items.map((item) => item.item_id)]);
  }, [items]);

  const [liq_index, setIndex] = useState(1);

  return (
    <>
      <div className="mx-[16px] pt-[16px] flex justify-between">
        <p className="text-14px">
          <span className="font-medium">{user_nickname ? user_nickname : "안도"}</span>
          님의 액상
        </p>
        <p className="flex px-[4px]" onClick={() => handleLiq(false)}>
          <img src="/imageDB/common/blackTimes.svg" alt="닫기" />
        </p>
      </div>
      <div className="px-[40px]">
        {!loading ? (
          <Swiper
            effect="coverflow"
            centeredSlides={true}
            slidesPerView={3}
            onRealIndexChange={(sw) =>
              // + 카드를 누르자마자 이동되는 것을 막기 위함
              // 카드 전환이 다 끝나고 나면 바로 밑의 함수에서 후처리를 해줌
              setIndex(sw.realIndex ? sw.realIndex : -1)
            }
            onTransitionEnd={(sw) => !sw.realIndex && setIndex(0)}
            loop={true}
            loopedSlidesLimit={false}
            loopAdditionalSlides={10}
            initialSlide={1}
            slideToClickedSlide={true}
            coverflowEffect={{
              rotate: 0,
              stretch: 22.75,
              depth: 189,
              slideShadows: false,
            }}
            modules={[EffectCoverflow]}
            className="liq-flow mx-[20px]"
          >
            <SwiperSlide onClick={() => !liq_index && nav("/shop/liquid/1")}>
              <p className="rounded-[8px] bg-[#E3E3E3] border border-[#F5F4F1] w-[72px] h-[90px] flex flex-col justify-center">
                <img src="/imageDB/common/whitePlus.svg" className="mx-auto" alt="추가" />
              </p>
            </SwiperSlide>
            {items.length ? (
              <>
                {items.map((item, i) => (
                  <SwiperSlide className="liq-parts" key={i}>
                    {liq_index === i + 1 ? (
                      <p
                        className="bg-white w-[30px] h-[30px] rounded-full absolute top-1/4 left-1/4 translate-x-full -translate-y-full flex
                      drop-shadow-[0_0_4px_rgba(0,0,0,0.1)]
                      "
                        onClick={() => {
                          const idx_liq = items_ids.indexOf(item.item_id);
                          if (idx_liq > -1) {
                            const idx_fav = favs.indexOf(item.item_id);
                            if (idx_fav > -1) {
                              if (liq_index === items.length) if (items.length > 4) setIndex((idx) => idx - 1);
                              // 액상 찜 아이디 배열 먼저 바꿔주고
                              const arr_ids = [...items_ids];
                              arr_ids.splice(idx_liq, 1);
                              setItemsIds([...arr_ids]);
                              // 액상 찜 객체 배열 바꿔주고
                              const arr_liq = [...items];
                              arr_liq.splice(idx_liq, 1);
                              dispatch(setFavLiqs([...arr_liq]));

                              // 찜 객체 배열 바꿔준 후에
                              const arr_fav = [...favs];
                              arr_fav.splice(idx_fav, 1);
                              dispatch(setFavs([...arr_fav]));

                              // 서버에 있는 찜 목록까지 바꿔주면 완료
                              Axios.get(`${process.env.REACT_APP_FRONT_URL}/api/user/updateFav?item_id=${item.item_id}&increment=-1`).catch((err) =>
                                console.log(err),
                              );
                            }
                          }
                        }}
                      >
                        <img src="/imageDB/common/blackTimes.svg" width={10} className="mx-auto" alt="제거" />
                      </p>
                    ) : null}
                    <img
                      className="self-center w-full min-w-[120px] max-w-[158px]"
                      src={process.env.REACT_APP_IMAGE_BASE + item.item_img + `?timestamp=${new Date().getTime()}&w=256&q=75&f=webp`}
                      alt={`찜한 액상 ${i + 1}`}
                    />
                  </SwiperSlide>
                ))}
                {new Array(Math.max(4 - items.length, 0)).fill(0).map((elem, i) => (
                  <SwiperSlide key={i} onClick={() => nav("/shop/liquid/1")}>
                    <p className="rounded-[8px] bg-[#E3E3E3] border border-[#F5F4F1] w-[72px] h-[90px] flex flex-col justify-center">
                      <img src="/imageDB/common/whitePlus.svg" className="mx-auto" alt="추가" />
                    </p>
                  </SwiperSlide>
                ))}
              </>
            ) : (
              new Array(4).fill(0).map((elem, i) => (
                <SwiperSlide onClick={() => nav("/shop/liquid/1")} key={i}>
                  <p className="rounded-[8px] bg-[#E3E3E3] border border-[#F5F4F1] w-[72px] h-[90px] flex flex-col justify-center">
                    <img src="/imageDB/common/whitePlus.svg" className="mx-auto" alt="추가" />
                  </p>
                </SwiperSlide>
              ))
            )}
          </Swiper>
        ) : (
          <p className="h-[150px]" />
        )}
      </div>
      <div className="mx-[16px] h-[76px] bg-[#F5F5F5] rounded-[7px] text-left flex flex-col justify-center">
        <div className="flex justify-between">
          <div className="ml-[17px]">
            <p className="text-14px font-medium">
              {items.length && 0 < liq_index && liq_index <= items.length ? items[liq_index - 1].item_company : "선호하는 액상을 추가해보세요."}
            </p>
            {items.length && 0 < liq_index && liq_index <= items.length ? (
              <p
                className="text-12px self-center"
                onClick={() => items.length && liq_index > 0 && nav(`/detail/liquid/${parseInt(items[liq_index - 1].item_id.slice(4))}`)}
              >
                {items[liq_index - 1].item_name}
              </p>
            ) : null}
            {0 < liq_index && liq_index <= items.length ? (
              <p className="text-12px text-[#939393] leading-[4px]">{items.length ? items[liq_index - 1].item_desc : null}</p>
            ) : null}
          </div>
          {items.length && 0 < liq_index && liq_index <= items.length && items[liq_index - 1] ? (
            <div className="mr-[10px] flex">
              {items[liq_index - 1].discount_price > 0 && (
                <span
                  style={{
                    fontFamily: "GOOD BRUSH",
                    fontSize: "20px",
                    color: "red",
                  }}
                >{`${Math.round((1 - items[liq_index - 1].discount_price / items[liq_index - 1].item_price) * 100)}% `}</span>
              )}
              {typeof items[liq_index - 1].item_price === "number" && (
                <p className="h-min self-center font-bold text-14px mr-[10px]">₩{items[liq_index - 1].item_price.toLocaleString("ko-KR")}</p>
              )}
              <button
                className={`w-[56px] h-[45px] self-center text-center rounded-lg text-white bg-[#292929] `}
                style={items[liq_index - 1].stock <= 0 ? { lineHeight: "45px", backgroundColor: "#e3e3e3", color: "#969696" } : { lineHeight: "45px" }}
                onClick={() => {
                  dispatch(setAddIdle(false));
                  dispatch(setIOstatus(0));
                  dispatch(
                    addCartItem({
                      user_id,
                      item_id: items[liq_index - 1].item_id,
                    }),
                  );
                  dispatch(setBasket(true));
                }}
                disabled={items[liq_index - 1].stock <= 0}
              >
                {items[liq_index - 1].stock <= 0 ? "품절" : "담기"}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default memo(Liq);
