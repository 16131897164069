import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCustom } from "../../redux/module/custom_str";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

export default function MyHistory() {
  const dispatch = useDispatch();
  const nav = useNavigate();

  useEffect(() => {
    dispatch(setCustom("배송 현황 관리"));
  }, [dispatch]);

  const [order_infos, setOrderInfo] = useState([]);
  const [order_items, setOrderItem] = useState([]);
  const [invoices, setInvoice] = useState([]);
  const [invoice_tracks, setInvoiceTrack] = useState([]);

  const [complete, setComplete] = useState(false);

  const colorCase = (text) => {
    // 아래 삼항 버그 수정 및 가시성 편의성
    switch (text) {
      case "주문 접수":
        return " text-[#00C31F]";
      case "배송 준비중":
        return " text-[#FF7A00]";
      case "배송중":
        return " text-[#0047FF]";
      case "주문 취소":
        return " text-[#FF0000]";
      default:
        return "";
    }
  };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_FRONT_URL}/api/user/shipping`).then((res) => {
      if (res.data && res.data.length) {
        // res.data[0]의 구조
        // order_id
        // order_item
        // actual_price
        // invoice
        // order_addr
        // order_tel
        // order_msg
        setOrderInfo(res.data[0]);
        setInvoice(res.data[0].map((order) => order.invoice));
        setOrderItem(res.data[1]);
        setInvoiceTrack(res.data[2]);
        // setStatus(res.data);
      }

      setComplete(true);
    });
  }, []);

  return complete ? (
    order_infos.map((order, itr) => (
      <div className="mt-[27px]" key={`shipping_${itr}`}>
        <div className="flex justify-between mx-[20px] Rubik">
          <p>
            {order.order_time.slice(0, 4)}.{order.order_time.slice(5, 7)}.{order.order_time.slice(8, 10)}
          </p>
          <p
            className="flex"
            onClick={() =>
              nav("detail", {
                state: {
                  order_info: order_infos[itr],
                  order_items,
                  invoice: invoices[itr],
                  invoice_track: invoice_tracks[itr],
                },
              })
            }
          >
            <span className={`mr-[6.5px] ${colorCase(invoice_tracks[itr])}`}>{invoice_tracks[itr]}</span>
            <img src="/imageDB/common/chevronLeft.svg" className="rotate-180" width={7} alt="상세 보기" />
          </p>
        </div>
        <hr className="mt-[20px] border-t-[1.5px] border-black mx-[20px]" />
        <Swiper id="basket" width={102} slidesOffsetBefore={16} slidesOffsetAfter={-16} spaceBetween={10} className="mt-[8px]" modules={[Pagination]}>
          {order_items.map((order_item, i) =>
            order_item.order_id === order.order_id ? (
              <SwiperSlide
                key={i}
                className="basket-parts flex-col"
                onClick={() => {
                  let type;
                  switch (order_item.item_id[3]) {
                    case "L":
                      type = "liquid";
                      break;
                    case "M":
                      type = "machine";
                      break;
                    case "C":
                      type = "coil";
                      break;
                    case "P":
                      type = "pod";
                      break;
                    default:
                  }
                  nav(`/detail/${type}/${parseInt(order_item.item_id.slice(4))}`);
                }}
              >
                <div className="absolute top-[12px] w-full">
                  <p className="h-[65px] flex">
                    <img
                      src={process.env.REACT_APP_IMAGE_BASE + order_item.item_info.item_img + `?timestamp=${new Date().getTime()}&w=256&q=75&f=webp`}
                      width={65}
                      className="mx-auto self-center"
                      alt={`상품 ${i}`}
                    />
                  </p>
                  <div className="text-12px text-left mx-[10px] font-medium">
                    <p className="mt-[9.7px]">{order_item.item_info.item_company}</p>
                    <p>{order_item.item_info.item_name}</p>
                    <p className="font-normal text-[#828282]">{order_item.item_info.item_desc}</p>
                    {order_item.item_option ? <p className="text-[#828282] font-normal">{order_item.item_option}</p> : null}
                  </div>
                </div>
                <div className="absolute bottom-[4px] w-full">
                  <p className="mt-[4px] mr-[10px] text-12px text-right font-semibold Roboto">
                    1개 / ₩{order_item.item_info.item_price.toLocaleString("ko-KR")}
                  </p>
                  <hr className="mt-[8px] w-full border-[#D4D4D4]" />
                  <p className="mt-[8px] text-14px text-[#3C2E06] font-medium Roboto">수량 {order_item.quantity}개</p>
                </div>
              </SwiperSlide>
            ) : null,
          )}
        </Swiper>
        {itr < order_infos.length - 1 ? <hr className="mt-[14px] border-[#D2D2D2]" /> : null}
      </div>
    ))
  ) : (
    <p className="mt-[32px]">배송 현황 로딩 중...</p>
  );
}
