import { Swiper, SwiperSlide } from "swiper/react"; // Swiper 라이브러리
import { Pagination } from "swiper";
import "swiper/css";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setFavs, setFLloading } from "../../redux/module/item_fav";

export default function Curation_Liq({ parameters }) {
  const nav = useNavigate();
  const loc = useLocation();
  const dispatch = useDispatch();
  const {
    id,
    titleimg,
    items, // , title, text
  } = parameters;

  const favs = useSelector((state) => state.item_fav.favs);

  const alterFav = async (id, inc) => {
    var res = await axios.get(`${process.env.REACT_APP_FRONT_URL}/api/user/updateFav?item_id=${id}&increment=${inc}`).catch((err) => console.err(err));
    if (res.data === "login") {
      sessionStorage.setItem("path", loc.pathname);
      return nav(`/kakaoLogin`);
    } else if (inc === -1) {
      const idx = favs.indexOf(id);
      const arr = [...favs];
      if (idx > -1) arr.splice(idx, 1);
      dispatch(setFavs([...arr]));
      dispatch(setFLloading(true));
    } else {
      dispatch(setFavs([...favs, id]));
      dispatch(setFLloading(true));
    }
  };

  return (
    <div className="my-[40px]">
      <div className="relative">
        <img src={process.env.REACT_APP_IMAGE_BASE + titleimg + `?timestamp=${new Date().getTime()}`} width={360} alt="큐레이션 소개 문구" />
        <p
          className="absolute top-0 right-4 text-[#A8A8A8] font-medium text-14px pl-[4px] pb-[4px]"
          onClick={() => nav("/curation", { state: parameters })}
          // onClick={() => console.log(items, Math.round((1 - items[0].discount_price / items.item_price) * 100))}
        >
          더보기
        </p>
      </div>
      <Swiper className="mySwiper mt-[24px]" width={140} slidesOffsetBefore={16} slidesOffsetAfter={-16} spaceBetween={12} modules={[Pagination]}>
        {items.length &&
          items.map((liq, i) => {
            // console.log("cid_" + parseInt(id.substring(6)) + "liq_" + i);
            return (
              <SwiperSlide
                key={"cid_" + parseInt(id.substring(6)) + "liq_" + i}
                className="min-h-[166px] curation-item flex flex-col"
                onClick={() =>
                  nav(
                    `/detail/${liq.item_id[3] === "C" ? "coil" : liq.item_id[3] === "P" ? "pod" : liq.item_id[3] === "M" ? "machine" : "liquid"}/${parseInt(
                      liq.item_id.slice(4),
                    )}`,
                  )
                }
              >
                <div className="bg-[#F5F5F5] h-[166px] w-full relative">
                  {/* <div
                    className="px-[8px] py-[5px] bg-[#FF2F61] text-white font-medium text-[14px]"
                    style={{
                      position: "absolute",
                      width: "fit-content",
                      marginLeft: 0,
                      marginRight: "auto",
                    }}
                  >
                    블프
                  </div> */}
                  {
                    //찜테이블에 존재하고, 그게 1일 경우.. 사실 1이 아니면 이 전에 필터를 했을 가능성 농후
                    favs.includes(liq.item_id) ? (
                      <img
                        draggable="false"
                        src="/imageDB/common/heartFull.svg"
                        className="absolute top-0 right-0"
                        alt="찜한 상품"
                        onClick={(e) => {
                          e.stopPropagation();
                          alterFav(liq.item_id, -1);
                        }}
                      />
                    ) : (
                      <img
                        draggable="false"
                        src="/imageDB/common/heartEmpty.svg"
                        className="absolute top-0 right-0"
                        alt="찜하지 않은 상품"
                        onClick={(e) => {
                          e.stopPropagation();
                          alterFav(liq.item_id, 1);
                        }}
                      />
                    )
                  }
                  <p className="my-[12px] h-[138px] flex">
                    <img
                      draggable="false"
                      src={process.env.REACT_APP_IMAGE_BASE + liq.img + `?timestamp=${new Date().getTime()}&w=256&q=75&f=webp`}
                      width={140}
                      className="mx-auto self-center"
                      alt="액상 큐레이션"
                    />
                  </p>
                </div>
                <div className="mt-[12px] text-left tracking-[-0.28px]">
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <p className="text-14px font-medium">{liq.manuf + " "}</p>
                    {liq.stock <= 0 && (
                      <div
                        style={{
                          display: "inline-block",
                          width: "28px",
                          height: "18px",
                          margin: "0 0 0 4px",
                          lineHeight: "18px",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          color: "#FFFFFF",
                          backgroundColor: "#B8B8B8",
                          textAlign: "center",
                        }}
                      >
                        품절
                      </div>
                    )}
                  </div>
                  <p className="text-14px">{liq.name}</p>
                  {liq.discount_price > 0 && (
                    <span
                      style={{
                        fontFamily: "GOOD BRUSH",
                        fontSize: "20px",
                        color: "red",
                      }}
                    >{`${Math.round((1 - liq.discount_price / liq.price) * 100)}% `}</span>
                  )}
                  {typeof liq.price == "number" && (
                    <span className="font-bold">{(liq.discount_price > 0 ? liq.discount_price : liq.price).toLocaleString("ko-KR")}원</span>
                  )}
                  {Number(liq.review_count) > 0 && (
                    <div className="flex text-12px Roboto" onClick={() => nav(`/review/liquid/${parseInt(liq.item_id.slice(4))}`)}>
                      <img draggable="false" src="/imageDB/common/rankStar.svg" className="inline" alt="별점" />
                      <p className="ml-[4px] text-[#464646]">
                        <span className="align-sub">{liq.review_rate.toFixed(1)}</span>
                      </p>
                      <p className="ml-[4px] text-[#A8A8A8]">
                        <span className="align-sub">({liq.review_count})</span>
                      </p>
                    </div>
                  )}
                  <div
                    style={{
                      marginTop: "5px",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {liq.badges.length && typeof liq.badges === "object"
                      ? liq.badges.map(
                          (badge, idx) =>
                            badge && (
                              <img
                                key={idx}
                                src={`${process.env.REACT_APP_IMAGE_BASE}${badge}`}
                                style={{ height: "16px", marginRight: "4px" }}
                                alt={`Badge ${idx}`}
                              />
                            ),
                        )
                      : null}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
}
