// Import necessary dependencies
import { IMP } from "../../App";
import axios from "axios";

// Define the onClickCertification function
const onClickCertification = async ({ user_data }) => {
  const date = new Date();

  IMP.certification(
    {
      // param
      // 주문 번호
      // pg: "COCandocom", //본인인증 설정이 2개이상 되어 있는 경우 필
      merchant_uid:
        "Ando_cert_" +
        date.getFullYear().toString() +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        date.getDate().toString().padStart(2, "0") +
        date.getHours().toString().padStart(2, "0") +
        date.getMinutes().toString().padStart(2, "0") +
        date.getSeconds().toString().padStart(2, "0") +
        date.getMilliseconds().toString().padStart(3, "0"),
      m_redirect_url: process.env.REACT_APP_FRONT_URL,
      popup: true,
      min_age: 19,
      name: "",
      phone: user_data.tel,
    },
    (response) => {
      const { success, error_msg } = response;

      if (success) {
        axios
          .post(`${process.env.REACT_APP_FRONT_URL}/api/certifications`, {
            imp_uid: response.imp_uid,
          })
          .then((res) => {
            if (res.data !== "success") {
              alert("본인 인증에 실패 했습니다: " + res.data);
            } else {
              alert("본인 인증에 성공했습니다!");
              window.location.href = "/";
            }
          })
          .catch((err) => {
            alert("본인 인증에 실패하였습니다: " + err);
          });
      } else {
        alert("본인 인증에 실패하였습니다: " + error_msg);
      }
    },
  );
};

// function callback(response) {
//   const {
//     success,
//     //merchant_uid,
//     error_msg,
//     //...
//   } = response;

//   if (success) {
//     // 인증 성공 시
//     // jQuery로 HTTP 요청
//     axios.post(
//       {
//         url: `${process.env.REACT_APP_FRONT_URL}"/api/certifications"`,
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         data: { imp_uid: response.imp_uid },
//       },
//       (req, res) => {
//         alert(res);
//         redirect("/");
//       }
//     );
//   } else {
//     alert("본인 인증에 실패하였습니다: " + response.error_msg);
//   }
// }
// return;
// // return <button onClick={onClickCertification}>본인인증 테스트</button>;
//}

//export default ImpCert;

export default onClickCertification;
