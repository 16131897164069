export const AskMeAnything = ({ remove, show }) => (
  <>
    {!remove && (
      <div className="fixed z-[18] bottom-[55px] right-0 w-[114px] h-[114px]">
        <p
          className={`absolute flex inset-x-0 mx-auto -top-[30px] text-14px w-[84px] h-[60px] rounded-[24px] right-0 bg-black text-white transition-all duration-1000 ${
            show ? "opacity-40" : "opacity-0"
          }`}
        >
          <span className="mx-auto self-center">
            무엇이든
            <br />
            물어보세요
          </span>
        </p>
      </div>
    )}

    <button className="fixed z-[19] bottom-[55px] right-0 w-[114px] h-[114px]">
      <a href="http://pf.kakao.com/_qJbjG/chat" target="_blank" rel="noreferrer">
        <p className="relative flex">
          {/* <img
            src="/imageDB/bottom_nav/ButtonBG.png"
            width={110}
            className="absolute inset-x-0 mx-auto self-center"
            alt="카카오 문의 배경"
            /> */}
          <img
            // src="/imageDB/bottom_nav/KakaoQuery.png"
            src="/imageDB/bottom_nav/kakaoIco.png"
            width={80}
            className="absolute inset-x-0 mx-auto self-center"
            alt="카카오 문의 버튼"
          />
        </p>
      </a>
    </button>
  </>
);
