import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";

const fetchCart = createAsyncThunk("item_cart/fetchCart", () =>
  Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/user/cart`)
    .then((res) => res.data)
    .catch((err) => console.log("DB 통신 오류C"))
);

// 상품을 장바구니에 생성하거나 증가
const addCartItem = createAsyncThunk(
  "item_cart/addCartItem",
  ({ user_id, item_id, item_option = null, quantity = 1 }) =>
    Axios.patch(`${process.env.REACT_APP_SERVER_URL}/api/user/cart`, {
      user_id,
      item_id,
      item_option,
      quantity,
    })
      .then((res) => {
        if (res.data !== "OK" && !res.data.length)
          console.log("장바구니 추가 오류");
      })
      .catch((err) => console.log("DB 통신 오류AC"))
);

// 상품을 장바구니에서 제거
const delCartItem = createAsyncThunk(
  "item_cart/delCartItem",
  ({ user_id, item_id }) =>
    Axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/user/cart`, {
      data: { user_id, item_id },
    }).then((res) => {
      if (res.data !== "OK") console.log("장바구니 제거 오류");
    })
);

// 장바구니에 있는 상품의 갯수를 지정 (set)
const editCartItem = createAsyncThunk(
  "item_cart/editCartItem",
  ({ user_id, item_id, quantity }) =>
    Axios.put(`${process.env.REACT_APP_SERVER_URL}/api/user/cart`, {
      user_id,
      item_id,
      quantity,
    }).then((res) => {
      if (res.data !== "OK") console.log("장바구니 수정 오류");
      else {
        return { item_id, quantity };
      }
    })
);

const rewriteCartItem = createAsyncThunk(
  "item_cart/rewriteCartItem",
  ({ user_id, item_id, quantity }) =>
    Axios.post(`${process.env.REACT_APP_SERVER_URL}/api/user/cart`, {
      user_id,
      item_id,
      quantity,
    }).then((res) => {
      if (res.data !== "OK") console.log("장바구니 덮어쓰기 오류");
      else
        return Axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/user/cart?hallo=wow`
        )
          .then((res2) => res2.data)
          .catch((err) => console.log("DB 통신 오류C"));
    })
);

const initC = {
  data: [],
  // 0: 통신 전, 1: 통신 중, 2: 통신 완료 (처리 후에 0으로 set)
  // 처음 실행 시 DB 쿼리를 통해 받아오게 유도해야 하므로 최초값은 1
  IOongoing: 1,
  addIdle: false, // 상품 추가 가능한 상태인가?
  delIdle: true, // 상품 삭제 가능한 상태인가?
  editIdle: true, // 상품 수정 가능한 상태인가?
  writeIdle: true, // 장바구니 덮어쓰기 가능한 상태인가?
  basket_open: false, // 장바구니를 펼치는가?
};

const item_cart = createSlice({
  name: "item_cart",
  initialState: {
    ...initC,
  },
  reducers: {
    initCart: () => ({ ...initC }),
    setIOstatus: (state, action) => ({ ...state, IOongoing: action.payload }),
    setAddIdle: (state, action) => ({ ...state, addIdle: action.payload }),
    setDelIdle: (state, action) => ({ ...state, delIdle: action.payload }),
    setEditIdle: (state, action) => ({ ...state, editIdle: action.payload }),
    setWriteIdle: (state, action) => ({ ...state, writeIdle: action.payload }),
    setBasket: (state, action) => ({ ...state, basket_open: action.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCart.fulfilled, (state, action) => {
        if (action.payload.length)
          if (action.payload.length) {
            state.data = action.payload;
            state.IOongoing = 2;
          }
      })
      .addCase(addCartItem.fulfilled, (state) => {
        state.IOongoing = 1;
      })
      .addCase(delCartItem.fulfilled, (state, action) => {
        state.data.splice(action.meta.arg.idx, 1);
        state.delIdle = true;
      })
      .addCase(editCartItem.fulfilled, (state, action) => {
        if (action.payload) {
          state.data.forEach((it) => {
            if (it.item_id === action.payload.item_id) {
              it.quantity = action.payload.quantity;
            }
          }); //[0].quantity = action.payload;
        }
        state.editIdle = true;
      })
      .addCase(rewriteCartItem.fulfilled, (state, action) => {
        // 장바구니 덮어씌우기를 했을 때 새로 추가된 상품 불러오기
        if (action.payload.length)
          if (action.payload.length) {
            state.data = action.payload;
            state.IOongoing = 2;
          }
        state.writeIdle = true;
      });
  },
});

export {
  fetchCart,
  addCartItem,
  delCartItem,
  editCartItem,
  rewriteCartItem,
  item_cart,
};
export const {
  initCart,
  setIOstatus,
  setAddIdle,
  setDelIdle,
  setEditIdle,
  setWriteIdle,
  setBasket,
} = item_cart.actions;
