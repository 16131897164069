// https://velog.io/@tlatjdgh3778/React-%ED%8E%98%EC%9D%B4%EC%A7%80-%EC%9D%B4%EB%8F%99-%EC%8B%9C-%EC%8A%A4%ED%81%AC%EB%A1%A4-%EB%A7%A8-%EC%9C%84%EB%A1%9C-%EC%98%A4%EA%B2%8C-%ED%95%98%EB%8A%94-%EB%B0%A9%EB%B2%95
// smooth: https://bobbyhadz.com/blog/react-scroll-to-top

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, [pathname]);

  return null;
}
