import { memo } from "react";

const Footer = () => {
  return (
    <footer className="bg-[#414141] text-white text-14px tracking-[-3%] w-full min-w-[360px] mt-[32px] py-[25px]">
      <div>
        <p className="mt-[4px]">나리</p>
        <p className="mt-[4px]">ANDO</p>
      </div>
      <div className="font-light">
        <p className="mt-[4px]">
          경기도 수원시 장안구 정자동 950
          <br />
          화서역파크푸르지오 오피스텔 201동 3006호
        </p>
        <p className="mt-[4px]">대표자 : 박나리</p>
        <p className="mt-[4px]">사업자등록번호 : 448-33-01174</p>
        <p className="mt-[4px]">통신판매업신고번호 : 2022-수원장안-0703</p>
        <p className="mt-[4px]">대표 번호 : 070-4082-0306</p>
      </div>
    </footer>
  );
};

export default memo(Footer);
