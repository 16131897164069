import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSearchText } from "../../../redux/module/custom_str";

export default function FilterAccessory({ typeFilter, setFilterType, filterOptionsAccessory, setQuery }) {
  const dispatch = useDispatch();

  const list_brand = useSelector((state) => state.item_acc.brands);
  const list_type = [
    { str: "코일", qry: "coil" },
    { str: "팟", qry: "pod" },
  ];

  const ref_brand = useRef([]);
  const tmp_brand = useRef([]);

  useEffect(() => {
    if (list_brand.length && !ref_brand.current.length) {
      ref_brand.current = new Array(list_brand.length).fill(false);
      tmp_brand.current = [...ref_brand.current];
    }
  }, [list_brand.length]);

  const ref_type = useRef(new Array(list_type.length).fill(false));
  const tmp_type = useRef([...ref_type.current]);

  const handleFilter = (type) => {
    tmp_brand.current = [...ref_brand.current];
    tmp_type.current = [...ref_type.current];
    setFilterType(type);
  };

  const MakeQuery = () => {
    let query_brand = "";
    for (let i = 0; i < ref_brand.current.length; i++) {
      if (ref_brand.current[i]) {
        if (query_brand) query_brand += `,${list_brand[i]}`;
        else query_brand += `item_company=${list_brand[i]}`;
      }
    }

    let query_type = "";
    for (let i = 0; i < ref_type.current.length; i++) {
      if (ref_type.current[i]) {
        if (query_type) query_type += `,${list_type[i]["qry"]}`;
        else query_type += `acc_type=${list_type[i]["qry"]}`;
      }
    }

    let query_final = "";
    query_final += query_brand ? `${query_brand}&` : "";
    query_final += query_type ? `${query_type}&` : "";

    // 쿼리 스트링에 특수문자가 들어가면 서버에서 문자열이 오염돼서
    // 퍼센트 인코딩 과정을 추가로 거쳐야 함.
    query_final = encodeURI(query_final);
    setQuery(query_final);
  };

  const ModalBrand = () => {
    const [select_brand, setBrand] = useState(tmp_brand.current);

    return (
      <div className="overflow-y-scroll hide-scrollbar h-[345px]">
        {list_brand.map((elem, itr) => (
          <div key={"liquid_brand_" + itr}>
            <div
              className="flex justify-between px-[16px] pt-[7px] pb-[8px]"
              onClick={() => {
                tmp_brand.current[itr] = !tmp_brand.current[itr];
                setBrand([...tmp_brand.current]);
              }}
            >
              <p className={`${select_brand[itr] ? "text-black" : ""}`}>{elem}</p>
              <p>
                {select_brand[itr] ? (
                  <img src="/imageDB/common/checked.svg" width={16} alt="checked" />
                ) : (
                  <img src="/imageDB/common/unchecked.svg" width={16} alt="unchecked" />
                )}
              </p>
            </div>
            {itr !== list_brand.length - 1 ? <hr className="mt-[8px] mb-[7px] border-[#F4F4F4]" /> : null}
          </div>
        ))}
      </div>
    );
  };

  const ModalType = () => {
    const [select_type, setType] = useState(tmp_type.current);

    return (
      <div className="overflow-y-scroll hide-scrollbar h-[345px]">
        {list_type.map((elem, itr) => (
          <div key={"accessory_type_" + itr}>
            <div
              className="flex justify-between px-[16px] pt-[7px] pb-[8px]"
              onClick={() => {
                tmp_type.current[itr] = !tmp_type.current[itr];
                setType([...tmp_type.current]);
              }}
            >
              <p className={`${select_type[itr] ? "text-black" : ""}`}>{elem.str}</p>
              <p>
                {select_type[itr] ? (
                  <img src="/imageDB/common/checked.svg" width={16} alt="checked" />
                ) : (
                  <img src="/imageDB/common/unchecked.svg" width={16} alt="unchecked" />
                )}
              </p>
            </div>
            {itr !== list_type.length - 1 ? <hr className="mt-[8px] mb-[7px] border-[#F4F4F4]" /> : null}
          </div>
        ))}
      </div>
    );
  };

  return typeFilter ? (
    <>
      {/* 음영 */}
      <div className="absolute top-0 z-[21] w-full min-w-[360px] h-screen bg-[rgba(28,28,28)]/80" onClick={() => handleFilter(0)} />

      <div className="bg-white w-full min-w-[360px] h-[580px] rounded-t-[20px] fixed z-[22] bottom-0">
        <div className="w-[40px] h-[5px] rounded-[2.5px] bg-[#F0F0F0] mx-auto mt-[10px]" onClick={() => handleFilter(0)} />

        <div className="mt-[25px] flex font-bold text-[#C3C3C3] mb-[28px]">
          {/* 하단 메뉴 */}
          {filterOptionsAccessory.map((elem, itr) => (
            <p
              className={`ml-[16px] ${typeFilter === itr + 1 ? "text-black" : null}`}
              key={"accessory_filter_tab_" + itr}
              onClick={() => setFilterType(itr + 1)}
            >
              {elem}
            </p>
          ))}
        </div>
        <div className="text-[#A0A0A0] font-medium text-14px">
          {typeFilter === 1 ? <ModalBrand /> : typeFilter === 2 ? <ModalType /> : null}
          {/* <div className="absolute bottom-[81px] w-full mt-[12px] flex bg-teal-500"> */}
          {/* 이 부분은 마지막에 검토!! */}
          {/* {["김치볶음밥", "잔치국수", "튀김우동"].map((elem) => (
            <div className="bg-black rounded-[18.5px] text-white w-[79px] h-[37px] mr-[8px] flex flex-col justify-center">
              <p>{elem}</p>
            </div>
          ))}
        </div> */}
        </div>
        <div className="w-full min-w-[360px] h-[130px] flex absolute bottom-0">
          <div className="mt-[12px] mb-[17px] w-full relative">
            <button
              className="bg-white border border-[#E6E6E6] rounded-[8px] w-[114px] h-[52px] mr-[6px]"
              onClick={() => {
                ref_brand.current = new Array(list_brand.length).fill(false);
                ref_type.current = new Array(list_type.length).fill(false);
                dispatch(setSearchText(""));
                MakeQuery();
                handleFilter(0);
              }}
            >
              초기화
            </button>
            <button
              className="bg-black text-white rounded-[8px] w-[208px] h-[52px]"
              onClick={() => {
                // 실제로 상품 필터 적용하는 코드
                ref_brand.current = [...tmp_brand.current];
                ref_type.current = [...tmp_type.current];
                MakeQuery();
                handleFilter(0);
              }}
            >
              상품 보기
            </button>
          </div>
        </div>
      </div>
    </>
  ) : null;
}
